import React, { useRef } from "react";
import { pageTitle } from "../../../helpers/PageTitle";
import Section from "../../../components/Section";
import { useTranslation } from "react-i18next";
import AboutSectionStyle5 from "../../../components/Section/AboutSection/AboutSectionStyle5";
import { medicinesContentDatas } from "./medicinesContents";
import DepartmentSectionStyle2 from "../../../components/Section/DepartmentSection/DepartmentSectionStyle2";
import DepartmentSectionStyle6 from "../../../components/Section/DepartmentSection/DepartmentSectionStyle6";
import Banner from "../../../components/Section/BannerSection";
import Spacing from "../../../components/Spacing";
import List from "../../../components/List";
import TestimonialStyle2 from "../../../components/Testimonial/TestimonialStyle2";
import SectionHeading from "../../../components/SectionHeading";
import FeaturesSectionStyle3 from "../../../components/Section/FeaturesSection/FeaturesSectionStyle3";
import FeaturesSectionStyle2 from "../../../components/Section/FeaturesSection/FeaturesSectionStyle2";
import FeaturesSectionStyle5 from "../../../components/Section/FeaturesSection/FeaturesSectionStyle5";
import KidneyBreadcrumStyle from "../../../components/Breadcrumb/KidneyBreadcrumStyle";
import KidneyEducationBannerStyle from "../../../components/Section/BannerSection/KidneyEducationBannerStyle";
import ScrollToSection from "../../../components/ScrollToSection";
import SingleGridAccordion from "../../../components/SingleGridAccordion/SingleGridAccordion";

const Medicines = () => {
  pageTitle("Medicines");
  const { t } = useTranslation(["medicines"]);

  const importantDescNotesRef = useRef(null);
  const antihypertensivesBenefitRef = useRef(null);
  const promotingOverallRef = useRef(null);
  const typesAntihypertensivesRef = useRef(null);
  const remember1Ref = useRef(null);
  const importantNoteRef = useRef(null);
  const secondLineMedicationsRef = useRef(null);
  const commonCKDRef = useRef(null);
  const remember2Ref = useRef(null);
  const healthCKDPatientsRef = useRef(null);
  const typesOfInsulinRef = useRef(null);
  const remember3Ref = useRef(null);
  const anemiaManagementRef = useRef(null);
  const ckdMBDRef = useRef(null);
  const sodiumBicarbonateRef = useRef(null);

  const scrollToLists = [
    // {
    //   btnName: "Important Notes",
    //   btnRef: importantDescNotesRef,
    // },
    {
      btnName: "Antihypertensives benefit",
      btnRef: antihypertensivesBenefitRef,
      btnImage: "/images/kidney_educations/medicines/medicine-icon-01.svg",
    },
    {
      btnName: "Promoting health",
      btnRef: promotingOverallRef,
      btnImage: "/images/kidney_educations/medicines/medicine-icon-02.svg",
    },
    {
      btnName: "Types of antihypertensives",
      btnRef: typesAntihypertensivesRef,
      btnImage: "/images/kidney_educations/medicines/medicine-icon-03.svg",
    },
    // {
    //   btnName: "Remember",
    //   btnRef: remember1Ref,
    // },
    // {
    //   btnName: "Important Note",
    //   btnRef: importantNoteRef,
    // },
    {
      btnName: "Second-line medications",
      btnRef: secondLineMedicationsRef,
      btnImage: "/images/kidney_educations/medicines/medicine-icon-04.svg",
    },
    {
      btnName: "Common CKD",
      btnRef: commonCKDRef,
      btnImage: "/images/kidney_educations/medicines/medicine-icon-05.svg",
    },
    // {
    //   btnName: "Remember",
    //   btnRef: remember2Ref,
    // },
    {
      btnName: "Health in CKD patients",
      btnRef: healthCKDPatientsRef,
      btnImage: "/images/kidney_educations/medicines/medicine-icon-06.svg",
    },
    {
      btnName: "Types of insulin",
      btnRef: typesOfInsulinRef,
      btnImage: "/images/kidney_educations/medicines/medicine-icon-07.svg",
    },
    // {
    //   btnName: "Remember",
    //   btnRef: remember3Ref,
    // },
    {
      btnName: "Anemia management",
      btnRef: anemiaManagementRef,
      btnImage: "/images/kidney_educations/medicines/medicine-icon-08.svg",
    },
    {
      btnName: "CKD-MBD",
      btnRef: ckdMBDRef,
      btnImage: "/images/kidney_educations/medicines/medicine-icon-09.svg",
    },
    {
      btnName: "Sodium bicarbonate",
      btnRef: sodiumBicarbonateRef,
      btnImage: "/images/kidney_educations/medicines/medicine-icon-10.svg",
    },
  ];

  const {
    importantDescNotesData,
    howAntihypertensivesBenefitYourKidneysData,
    typesOfAntihypertensivesData1,
    typesOfAntihypertensivesData2,
    typesOfAntihypertensivesData3,
    typesOfAntihypertensivesData4,
    typesOfAntihypertensivesData5,
    whySecondLineMedicationsData,
    ckdMedicationSubData1,
    ckdMedicationSubData4,
    typesOfInsulinData,
    metforminData,
    sulfonylureasData,
    inhibitorsData,
    receptorAgonistsData,
    anemiaManagementData,
    chronicKidneyDiseaseMineralBoneDisorderData,
  } = medicinesContentDatas();

  const importantDescriptionNotes = [
    {
      title: `${t("importantDescNotes.title")}`,
      iconUrl: "/images/home/remember-icon.svg",
      content: <List heading="" description="" data={importantDescNotesData} />,
    },
  ];

  const typesOfAntihypertensivesList1 = [
    {
      title: `${t("typesOfAntihypertensivesLists.list1.title")}`,
      iconUrl: "/images/home/remember-icon.svg",
      content: (
        <List
          heading=""
          description={`${t(
            "typesOfAntihypertensivesLists.list1.description"
          )}`}
          data={typesOfAntihypertensivesData1}
        />
      ),
    },
  ];

  const typesOfAntihypertensivesList2 = [
    {
      title: `${t("typesOfAntihypertensivesLists.list2.title")}`,
      iconUrl: "/images/home/remember-icon.svg",
      content: (
        <List
          heading=""
          description={`${t(
            "typesOfAntihypertensivesLists.list2.description"
          )}`}
          data={typesOfAntihypertensivesData2}
        />
      ),
    },
  ];

  const typesOfAntihypertensivesList3 = [
    {
      title: `${t("typesOfAntihypertensivesLists.list3.title")}`,
      iconUrl: "/images/home/remember-icon.svg",
      content: (
        <List
          heading=""
          description={`${t(
            "typesOfAntihypertensivesLists.list3.description"
          )}`}
          data={typesOfAntihypertensivesData3}
        />
      ),
    },
  ];

  const typesOfAntihypertensivesList4 = [
    {
      title: `${t("typesOfAntihypertensivesLists.list4.title")}`,
      iconUrl: "/images/home/remember-icon.svg",
      content: (
        <List
          heading=""
          description={`${t(
            "typesOfAntihypertensivesLists.list4.description"
          )}`}
          data={typesOfAntihypertensivesData4}
        />
      ),
    },
  ];

  const typesOfAntihypertensivesList5 = [
    {
      title: `${t("typesOfAntihypertensivesLists.list5.title")}`,
      iconUrl: "/images/home/remember-icon.svg",
      content: (
        <List
          heading=""
          description={`${t(
            "typesOfAntihypertensivesLists.list5.description"
          )}`}
          data={typesOfAntihypertensivesData5}
        />
      ),
    },
  ];

  const commonSecondLineMedicationsCKDList1 = [
    {
      title: `${t("commonSecondLineMedicationsCKD.lists.list1.title")}`,
      iconUrl: "/images/home/remember-icon.svg",
      content: (
        <List
          heading=""
          description={`${t(
            "commonSecondLineMedicationsCKD.lists.list1.description"
          )}`}
          data={ckdMedicationSubData1}
        />
      ),
    },
  ];

  const commonSecondLineMedicationsCKDList2 = [
    {
      title: `${t("commonSecondLineMedicationsCKD.lists.list2.title")}`,
      iconUrl: "/images/home/remember-icon.svg",
      content: (
        <List
          heading=""
          description={`${t(
            "commonSecondLineMedicationsCKD.lists.list2.description"
          )}`}
          data={[]}
        />
      ),
    },
  ];

  const commonSecondLineMedicationsCKDList3 = [
    {
      title: `${t("commonSecondLineMedicationsCKD.lists.list3.title")}`,
      iconUrl: "/images/home/remember-icon.svg",
      content: (
        <List
          heading=""
          description={`${t(
            "commonSecondLineMedicationsCKD.lists.list3.description"
          )}`}
          data={[]}
        />
      ),
    },
  ];

  const commonSecondLineMedicationsCKDList4 = [
    {
      title: `${t("commonSecondLineMedicationsCKD.lists.list4.title")}`,
      iconUrl: "/images/home/remember-icon.svg",
      content: (
        <List
          heading=""
          description={`${t(
            "commonSecondLineMedicationsCKD.lists.list4.description"
          )}`}
          data={ckdMedicationSubData4}
        />
      ),
    },
  ];

  const commonSecondLineMedicationsCKDList5 = [
    {
      title: `${t("commonSecondLineMedicationsCKD.lists.list5.title")}`,
      iconUrl: "/images/home/remember-icon.svg",
      content: (
        <List
          heading=""
          description={`${t(
            "commonSecondLineMedicationsCKD.lists.list5.description"
          )}`}
          data={[]}
        />
      ),
    },
  ];

  const insulinAndOtherBloodList = [
    {
      title: `${t("insulinAndOtherBlood.title")}`,
      iconUrl: "/images/home/remember-icon.svg",
      content: (
        <List
          heading=""
          description={`${t("insulinAndOtherBlood.description")}:`}
          data={[]}
        />
      ),
    },
  ];

  const insulinList = [
    {
      title: `${t("insulin.title")}`,
      iconUrl: "/images/home/remember-icon.svg",
      content: (
        <List
          heading=""
          description={`${t("insulin.description")}`}
          data={[]}
        />
      ),
    },
  ];

  const metforminList = [
    {
      title: `${t("metformin.title")}`,
      iconUrl: "/images/home/apple-icon-kidney-education.svg",
      content: <List heading="" description="" data={metforminData} />,
    },
  ];

  const sulfonylureasList = [
    {
      title: `${t("sulfonylureas.title")}`,
      iconUrl: "/images/home/apple-icon-kidney-education.svg",
      content: <List heading="" description="" data={sulfonylureasData} />,
    },
  ];

  const inhibitorsList = [
    {
      title: `${t("inhibitors.title")}`,
      iconUrl: "/images/home/apple-icon-kidney-education.svg",
      content: <List heading="" description="" data={inhibitorsData} />,
    },
  ];

  const receptorAgonistsList = [
    {
      title: `${t("receptorAgonists.title")}`,
      iconUrl: "/images/home/apple-icon-kidney-education.svg",
      content: <List heading="" description="" data={receptorAgonistsData} />,
    },
  ];

  const sodiumBicarbonateList = [
    {
      title: `${t("sodiumBicarbonate.title")}`,
      iconUrl: "/images/home/remember-icon.svg",
      content: (
        <List
          heading=""
          description={`${t("sodiumBicarbonate.description")}:`}
          data={[]}
        />
      ),
    },
  ];

  return (
    <>
      {/* Breadcrumb Section Start */}
      <Section className="cs_kideny_education">
        <KidneyBreadcrumStyle />
      </Section>
      {/* Breadcrumb Section End */}

      {/* Banner Section Start */}
      <Section className="cs_banner_styles">
        <KidneyEducationBannerStyle
          bgUrl="/images/kidney_educations/medicines/medicines-web-banner-new.jpg"
          imgUrl=""
          title={t("empoweringYourHealth.title")}
          subTitle={t("empoweringYourHealth.description")}
          subHeadTitle={t("empoweringYourHealth.subHeadTitle")}
          customeThemeClass=""
        />
      </Section>
      {/* Banner Section End */}

      {/* Section Navigation Start */}
      <Section
        topMd={50}
        topLg={50}
        topXl={50}
        bottomXl={80}
        bottomLg={80}
        bottomMd={80}
      >
        <ScrollToSection
          btnLists={scrollToLists}
          brandName="kidney-education"
        />
      </Section>
      {/* Section Navigation End */}

      {/* Remember Section Start */}
      <Section
        bottomMd={130}
        bottomLg={120}
        bottomXl={110}
        className="cs_about_kidney cs_white_bg"
        sectionRef={importantDescNotesRef}
      >
        <div className="container">
          <div className="cs_banner cs_style_9 cs_white_bg cs_radius_30">
            <SingleGridAccordion
              accordionData={importantDescriptionNotes}
              customClasses="cs_accordians_kidney_education cs_accordians_with_icon cs_accordians_only_title"
            />
          </div>
        </div>
      </Section>
      {/* Remember Section End */}

      {/* <section
        className="cs_banner cs_style_3 cs_bg_filed cs_medicines"
        style={{ backgroundImage: `url(/images/appointments/banner_bg.svg)` }}
      >
        <div className="container">
          <div className="row">
            <div className="cs_banner_text">
              <p className="cs_banner_subtitle cs_fs_20 cs_heading_color">
                {t("bannerContents.para1")}
              </p>
              <p className="cs_banner_subtitle cs_fs_20 cs_heading_color">
                {t("bannerContents.para2")}
              </p>
              <p className="cs_banner_subtitle cs_fs_20 cs_heading_color">
                {t("bannerContents.para3")}
              </p>
              <p className="cs_banner_subtitle cs_fs_20  cs_heading_color">
                {t("bannerContents.para4")}
              </p>
              <p className="cs_banner_subtitle cs_fs_20 cs_heading_color">
                {t("bannerContents.para5")}
              </p>
              <p className="cs_banner_subtitle cs_fs_20 mb-0 cs_heading_color">
                <Trans
                  i18nKey={t("bannerContents.para6")}
                  components={{
                    styledFont: <i />,
                  }}
                />
              </p>
            </div>
          </div>
        </div>
      </section> */}
      {/* <Section topMd={190} topLg={150} topXl={105} className="cs_transplant">
        <AboutSectionStyle5
          imgUrl="/images/home_4/about_img.jpeg"
          title={t("empoweringYourHealth.title")}
          subTitle={t("empoweringYourHealth.description")}
          subHeadTitle={t("empoweringYourHealth.subHeadTitle")}
          customeThemeClass="cs_kidney_education_color"
        />
      </Section> */}

      {/* How Antihypertensives Benefit Section Start */}
      <Section
        bottomMd={130}
        bottomLg={120}
        bottomXl={110}
        className="cs_catheters"
        sectionRef={antihypertensivesBenefitRef}
      >
        <DepartmentSectionStyle6
          sectionTitle={t("howAntihypertensivesBenefitYourKidneys.title")}
          data={howAntihypertensivesBenefitYourKidneysData}
        />
      </Section>
      {/* How Antihypertensives Benefit Section End */}

      {/* Promotion Health Section Start */}
      <Section
        bottomMd={130}
        bottomLg={120}
        bottomXl={110}
        className="cs_white_bg cs_medicines"
        sectionRef={promotingOverallRef}
      >
        <Banner
          bgUrl="/images/home/kidney-education-bg.png"
          imgUrl="/images/home_1/cta_img.png"
          title={t("promotingOverallHealth.title")}
          subTitle={t("promotingOverallHealth.description")}
        />
      </Section>
      {/* Promotion Health Section End */}

      {/* Types of Antihypertensives Section Start */}
      <Section
        bottomMd={130}
        bottomLg={120}
        bottomXl={110}
        className="cs_about_kidney"
        sectionRef={typesAntihypertensivesRef}
      >
        <div className="cs_doctor_details">
          <div
            className="cs_doctor_details_bg cs_bg_filed"
            style={{
              backgroundImage: `url(/images/home/kidney-education-bg.png)`,
            }}
          />
          <Spacing md="85" />
          <div className="container">
            <div className="row">
              <div className="col-lg-5">
                <div className="cs_single_doctor overflow-hidden cs_radius_20">
                  <img
                    src="/images/kidney_educations/medicines/types-of-Antihypertensives-new-1209.jpg"
                    alt="Doctor"
                    className="w-100"
                  />
                </div>
              </div>
              <div className="col-lg-6 offset-lg-1 position-relative">
                <Spacing md="55" />
                <h2 className="cs_fs_48 mb-0 cs_semibold testimonial-title">
                  {t("typesOfAntihypertensives.title")}
                </h2>
                <Spacing md="32" />
                <p className="mb-0 cs_heading_color testimonial-sub-title">{`${t(
                  "typesOfAntihypertensives.description"
                )}:`}</p>
                <Spacing md="200" xl="150" lg="210" />
                <SingleGridAccordion
                  accordionData={typesOfAntihypertensivesList1}
                  customClasses="cs_accordians_kidney_education cs_accordians_with_icon"
                />
                <Spacing md="50" lg="50" />
                <SingleGridAccordion
                  accordionData={typesOfAntihypertensivesList2}
                  customClasses="cs_accordians_kidney_education cs_accordians_with_icon"
                />
                <Spacing md="50" lg="50" />
                <SingleGridAccordion
                  accordionData={typesOfAntihypertensivesList3}
                  customClasses="cs_accordians_kidney_education cs_accordians_with_icon"
                />
                <Spacing md="50" lg="50" />
                <SingleGridAccordion
                  accordionData={typesOfAntihypertensivesList4}
                  customClasses="cs_accordians_kidney_education cs_accordians_with_icon"
                />
                <Spacing md="50" lg="50" />
                <SingleGridAccordion
                  accordionData={typesOfAntihypertensivesList5}
                  customClasses="cs_accordians_kidney_education cs_accordians_with_icon"
                />
              </div>
            </div>
          </div>
        </div>
      </Section>
      {/* Types of Antihypertensives Section End */}

      {/* Remember Section Start */}
      <Section
        topMd={130}
        topLg={120}
        topXl={110}
        bottomMd={30}
        bottomLg={30}
        bottomXl={30}
        className="cs_kideny_education cs_important_remember_bg"
        sectionRef={remember1Ref}
      >
        <div className="container">
          <div className="row">
            <TestimonialStyle2
              title={t("remember.title")}
              text={t("remember.description")}
            />
          </div>
        </div>
      </Section>
      {/* Remember Section End */}

      {/* Important Note Section Start */}
      <Section
        bottomMd={130}
        bottomLg={120}
        bottomXl={110}
        className="cs_kideny_education cs_important_remember_bg"
        sectionRef={importantNoteRef}
      >
        <div className="container">
          <div className="row">
            <TestimonialStyle2
              title={t("importantNote.title")}
              text={t("importantNote.description1")}
              subTitle={<p>{t("importantNote.description2")}</p>}
            />
          </div>
        </div>
      </Section>
      {/* Important Note Section End */}

      {/* Why Second-Line Medications Section Start */}
      <Section
        topMd={130}
        topLg={120}
        topXl={110}
        bottomMd={130}
        bottomLg={120}
        bottomXl={110}
        sectionRef={secondLineMedicationsRef}
      >
        <DepartmentSectionStyle2
          sectionTitle={t("whySecondLineMedications.title")}
          subTitle={t("whySecondLineMedications.description1")}
          subTitle2={`${t("whySecondLineMedications.description2")}:`}
          data={whySecondLineMedicationsData}
        />
      </Section>
      {/* Why Second-Line Medications Section End */}

      {/* CKD Section Start */}
      <Section
        bottomMd={130}
        bottomLg={120}
        bottomXl={110}
        className="cs_dialysis"
        sectionRef={commonCKDRef}
      >
        <div className="container">
          <div className="cs_departments cs_style_1">
            <div
              className="cs_departments_bg cs_radius_25"
              style={{
                backgroundImage: `url(/images/home/kidney-education-bg.png)`,
              }}
            />
            <SectionHeading
              title={t("commonSecondLineMedicationsCKD.title")}
              center
            />
            <Spacing md="72" lg="50" />
            <div className="cs_department_list">
              <div className="cs_banner cs_style_9 cs_white_bg cs_radius_30">
                <div className="row">
                  <div className="col-lg-12 col-xl-12">
                    <SingleGridAccordion
                      accordionData={commonSecondLineMedicationsCKDList1}
                      customClasses="cs_accordians_kidney_education cs_accordians_with_icon"
                    />
                    <Spacing md="20" lg="20" />
                    <SingleGridAccordion
                      accordionData={commonSecondLineMedicationsCKDList2}
                      customClasses="cs_accordians_kidney_education cs_accordians_with_icon"
                    />
                    <Spacing md="20" lg="20" />
                    <SingleGridAccordion
                      accordionData={commonSecondLineMedicationsCKDList3}
                      customClasses="cs_accordians_kidney_education cs_accordians_with_icon"
                    />
                    <Spacing md="20" lg="20" />
                    <SingleGridAccordion
                      accordionData={commonSecondLineMedicationsCKDList4}
                      customClasses="cs_accordians_kidney_education cs_accordians_with_icon"
                    />
                    <Spacing md="20" lg="20" />
                    <SingleGridAccordion
                      accordionData={commonSecondLineMedicationsCKDList5}
                      customClasses="cs_accordians_kidney_education cs_accordians_with_icon"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Section>
      {/* CKD Section End */}

      {/* Remember Section Start */}
      <Section
        topMd={130}
        topLg={120}
        topXl={110}
        bottomMd={130}
        bottomLg={120}
        bottomXl={110}
        className="cs_kideny_education cs_important_remember_bg"
        sectionRef={remember2Ref}
      >
        <div className="container">
          <div className="row">
            <TestimonialStyle2
              title={t("remember1.title")}
              text={t("remember1.description")}
            />
          </div>
        </div>
      </Section>
      {/* Remember Section End */}

      {/* Managing Blood Sugar Section Start */}
      <Section
        topMd={130}
        topLg={120}
        topXl={110}
        bottomMd={30}
        bottomLg={30}
        bottomXl={30}
        className="cs_transplant"
        sectionRef={healthCKDPatientsRef}
      >
        <AboutSectionStyle5
          imgUrl="/images/kidney_educations/medicines/managing-blood-sugar.jpg"
          title={t("managingBloodSugar.title")}
          subTitle={t("managingBloodSugar.description1")}
          subTitleHints={`${t("managingBloodSugar.description2")}:`}
        />
      </Section>
      {/* Managing Blood Sugar Section End */}

      {/* Insulin & Types Section Start */}
      <Section
        bottomMd={130}
        bottomLg={120}
        bottomXl={110}
        className="cs_white_bg cs_transplant"
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-xl-12 cs_list_sec1">
              <SingleGridAccordion
                accordionData={insulinAndOtherBloodList}
                customClasses="cs_accordians_kidney_education cs_accordians_with_icon cs_accordians_only_title"
              />
              <Spacing md="20" lg="20" />
              <SingleGridAccordion
                accordionData={insulinList}
                customClasses="cs_accordians_kidney_education cs_accordians_with_icon cs_accordians_only_title"
              />
              <Spacing md="50" lg="50" xl="50" />
              <Section
                className="cs_medicines cs_dialysis"
                sectionRef={typesOfInsulinRef}
              >
                <FeaturesSectionStyle3
                  sectionTitleDown={`${t("typesOfInsulin.title")}:`}
                  data={typesOfInsulinData}
                />
              </Section>
              <Spacing md="50" lg="50" />
              <SingleGridAccordion
                accordionData={metforminList}
                customClasses="cs_accordians_kidney_education cs_accordians_with_icon cs_accordians_only_title"
              />
              <Spacing md="50" lg="50" />
              <SingleGridAccordion
                accordionData={sulfonylureasList}
                customClasses="cs_accordians_kidney_education cs_accordians_with_icon cs_accordians_only_title"
              />
              <Spacing md="50" lg="50" />
              <SingleGridAccordion
                accordionData={inhibitorsList}
                customClasses="cs_accordians_kidney_education cs_accordians_with_icon cs_accordians_only_title"
              />
              <Spacing md="50" lg="50" />
              <SingleGridAccordion
                accordionData={receptorAgonistsList}
                customClasses="cs_accordians_kidney_education cs_accordians_with_icon cs_accordians_only_title"
              />
            </div>
          </div>
        </div>
      </Section>
      {/* Insulin & Types Section End */}

      {/* Remember Section Start */}
      <Section
        topMd={130}
        topLg={120}
        topXl={110}
        bottomMd={130}
        bottomLg={120}
        bottomXl={110}
        className="cs_kideny_education cs_important_remember_bg"
        sectionRef={remember3Ref}
      >
        <div className="container">
          <div className="row">
            <TestimonialStyle2
              title={t("remember2.title")}
              text={t("remember2.description1")}
              subTitle={<p>{`${t("remember2.description2")}:`}</p>}
            />
          </div>
        </div>
      </Section>
      {/* Remember Section End */}

      {/* Anemia Management Section Start */}
      <Section
        topMd={130}
        topLg={120}
        topXl={110}
        bottomMd={130}
        bottomLg={120}
        bottomXl={110}
        className="cs_white_bg cs_dialysis"
        sectionRef={anemiaManagementRef}
      >
        <FeaturesSectionStyle2
          sectionTitle={t("anemiaManagement.title")}
          sectoinSubTitle={`${t("anemiaManagement.description")}:`}
          imgUrl="/images/kidney_educations/medicines/anemia-management-new-1209.jpg"
          data={anemiaManagementData}
        />
      </Section>
      {/* Anemia Management Section End */}

      {/* CKD-MBD Section Start */}
      <Section
        bottomMd={130}
        bottomLg={120}
        bottomXl={110}
        className="cs_white_bg cs_medicines cs_healthy_kidneys"
        sectionRef={ckdMBDRef}
      >
        <FeaturesSectionStyle5
          sectionTitle={t("chronicKidneyDiseaseMineralBoneDisorder.title")}
          sectionSubTitle={`${t(
            "chronicKidneyDiseaseMineralBoneDisorder.description"
          )}:`}
          data={chronicKidneyDiseaseMineralBoneDisorderData}
        />
      </Section>
      {/* CKD-MBD Section End */}

      {/* Sodium Bicarbonate Section Start */}
      <Section
        bottomMd={130}
        bottomLg={120}
        bottomXl={110}
        className="cs_about_kidney cs_white_bg"
        sectionRef={sodiumBicarbonateRef}
      >
        <div className="container">
          <div className="cs_banner cs_style_9 cs_white_bg cs_radius_30">
            <SingleGridAccordion
              accordionData={sodiumBicarbonateList}
              customClasses="cs_accordians_kidney_education cs_accordians_with_icon cs_accordians_only_title"
            />
          </div>
        </div>
      </Section>
      {/* Sodium Bicarbonate Section End */}
    </>
  );
};

export default Medicines;
