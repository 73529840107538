import React from "react";

const ScrollToSection = ({ btnLists = [], brandName = "" }) => {
  const handleScrollToSection = (scrollRef) => {
    if (scrollRef.current === null) return;

    window.scrollTo({
      top: scrollRef.current.offsetTop - 150,
      behavior: "smooth",
    });
  };

  if (btnLists.length === 0) return null;

  return (
    <div className="container">
      {/* <div className={`cs_scroll_to_lists ${brandName}`}>
        {btnLists.map((btn) => (
          <button
            key={btn.btnName}
            type="button"
            className="btn"
            onClick={() => handleScrollToSection(btn.btnRef)}
          >
            {btn.btnName}
          </button>
        ))}
      </div> */}
      <div className="cs_scroll_to_sec_container">
        {btnLists.map((btn, index) => (
          <div className="cs_scroll_to_sec" key={index + "-scroll"}>
            <div className="cs_scroll_to_grid">
              <div
                className={`cs_scroll_to_lists_sec ${brandName}`}
                onClick={() => handleScrollToSection(btn.btnRef)}
              >
                <img
                  src={btn.btnImage || "/images/home_3/blog_1.jpeg"}
                  alt={btn.btnName}
                />
                <div className="cs_scroll_to_lists_info">
                  <h6>{btn.btnName}</h6>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ScrollToSection;
