import React from "react";
import AccordionWithComponent from "../AccordionWithComponent";

const SingleGridAccordion = ({ accordionData = [], customClasses = "" }) => {
  return (
    <div className="container">
      <div className="row">
        <div className="col-lg-12 col-xl-12 cs_list_sec1">
          <AccordionWithComponent
            variant={`cs_style1 cs_type_3 ${customClasses}`}
            data={accordionData}
          />
        </div>
      </div>
    </div>
  );
};

export default SingleGridAccordion;
