import React from "react";
import parse from "html-react-parser";

export default function BannerSectionStyle5({
  bgUrl,
  imgUrl,
  title,
  subHeadTitle = "",
  titleDown = "",
  subTitle,
  subTitle2,
  customClasses = "",
  customeThemeClass = "",
}) {
  return (
    <section
      className={`cs_banner cs_style_5 cs_bg_filed ${customClasses}`}
      style={{ backgroundImage: `url(${bgUrl})` }}
    >
      <div className="cs_banner_img">
        <img loading="lazy" src={imgUrl} alt="Banner" />
      </div>
      <div className="container">
        <div className="cs_banner_text">
          <h2 className="cs_banner_title cs_fs_72">{parse(title)}</h2>
          {subHeadTitle && (
            <h3 className={`cs_hero_title cs_fs_48 ${customeThemeClass}`}>
              {subHeadTitle}
            </h3>
          )}
          {titleDown && <h4 className="cs_banner_title">{titleDown}</h4>}
          <p className="cs_banner_subtitle cs_fs_20 mb-0 cs_heading_color">
            {parse(subTitle)}
          </p>
          {subTitle2 && (
            <p className="cs_banner_subtitle cs_fs_20 mb-0 cs_heading_color">
              {parse(subTitle2)}
            </p>
          )}
        </div>
      </div>
    </section>
  );
}
