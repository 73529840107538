import i18n from "../../../i18n/i18n";

export const transplantContentDatas = () => {
  const whatIsKidneyTransplantData = [
    {
      title: i18n.t("whatIsKidneyTransplantLists.list1.title", {
        ns: "transplant",
      }),
      subTitle: i18n.t("whatIsKidneyTransplantLists.list1.description", {
        ns: "transplant",
      }),
      iconUrl: "/images/kidney_educations/transplant/Icon 1-01.svg",
    },
    {
      title: i18n.t("whatIsKidneyTransplantLists.list2.title", {
        ns: "transplant",
      }),
      subTitle: i18n.t("whatIsKidneyTransplantLists.list2.description", {
        ns: "transplant",
      }),
      iconUrl: "/images/kidney_educations/transplant/Icon 2-01.svg",
    },
  ];

  const benefitsOfKidneyTransplantData = [
    {
      title: i18n.t("benefitsOfKidneyTransplantLists.list1.title", {
        ns: "transplant",
      }),
      subTitle: i18n.t("benefitsOfKidneyTransplantLists.list1.description", {
        ns: "transplant",
      }),
      iconUrl: "/images/kidney_educations/transplant/Icon 3-01.svg",
    },
    {
      title: i18n.t("benefitsOfKidneyTransplantLists.list2.title", {
        ns: "transplant",
      }),
      subTitle: i18n.t("benefitsOfKidneyTransplantLists.list2.description", {
        ns: "transplant",
      }),
      iconUrl: "/images/kidney_educations/transplant/Icon 4-01.svg",
    },
    {
      title: i18n.t("benefitsOfKidneyTransplantLists.list3.title", {
        ns: "transplant",
      }),
      subTitle: i18n.t("benefitsOfKidneyTransplantLists.list3.description", {
        ns: "transplant",
      }),
      iconUrl: "/images/kidney_educations/transplant/Icon 5-01.svg",
    },
  ];

  const evaluationProcessForKidneyTransplantData = [
    {
      title: i18n.t("evaluationProcessForKidneyTransplantLists.list1.title", {
        ns: "transplant",
      }),
      subTitle: i18n.t(
        "evaluationProcessForKidneyTransplantLists.list1.description",
        {
          ns: "transplant",
        }
      ),
      iconUrl: "/images/kidney_educations/transplant/Icon 7-01.svg",
    },
    {
      title: i18n.t("evaluationProcessForKidneyTransplantLists.list2.title", {
        ns: "transplant",
      }),
      subTitle: i18n.t(
        "evaluationProcessForKidneyTransplantLists.list2.description",
        {
          ns: "transplant",
        }
      ),
      iconUrl: "/images/kidney_educations/transplant/Icon 8-01.svg",
    },
    {
      title: i18n.t("evaluationProcessForKidneyTransplantLists.list3.title", {
        ns: "transplant",
      }),
      subTitle: i18n.t(
        "evaluationProcessForKidneyTransplantLists.list3.description",
        {
          ns: "transplant",
        }
      ),
      iconUrl: "/images/kidney_educations/transplant/Icon 9-01.svg",
    },
    {
      title: i18n.t("evaluationProcessForKidneyTransplantLists.list4.title", {
        ns: "transplant",
      }),
      subTitle: i18n.t(
        "evaluationProcessForKidneyTransplantLists.list4.description",
        {
          ns: "transplant",
        }
      ),
      iconUrl: "/images/kidney_educations/transplant/Icon 10-01.svg",
    },
  ];

  const nottoOrganResponsibilitiesData = [
    {
      title: i18n.t("nottoOrganResponsibilities.lists.list1.title", {
        ns: "transplant",
      }),
    },
    {
      title: i18n.t("nottoOrganResponsibilities.lists.list2.title", {
        ns: "transplant",
      }),
    },
    {
      title: i18n.t("nottoOrganResponsibilities.lists.list3.title", {
        ns: "transplant",
      }),
    },
    {
      title: i18n.t("nottoOrganResponsibilities.lists.list4.title", {
        ns: "transplant",
      }),
    },
    {
      title: i18n.t("nottoOrganResponsibilities.lists.list5.title", {
        ns: "transplant",
      }),
    },
  ];

  const sottoOrganResponsibilitiesData = [
    {
      title: i18n.t("sottoOrganResponsibilities.lists.list1.title", {
        ns: "transplant",
      }),
    },
    {
      title: i18n.t("sottoOrganResponsibilities.lists.list2.title", {
        ns: "transplant",
      }),
    },
    {
      title: i18n.t("sottoOrganResponsibilities.lists.list3.title", {
        ns: "transplant",
      }),
    },
    {
      title: i18n.t("sottoOrganResponsibilities.lists.list4.title", {
        ns: "transplant",
      }),
    },
    {
      title: i18n.t("sottoOrganResponsibilities.lists.list5.title", {
        ns: "transplant",
      }),
    },
  ];

  const whyBecomeAnOrganDonorData = [
    {
      title: i18n.t("whyBecomeAnOrganDonor.lists.list1.title", {
        ns: "transplant",
      }),
      subTitle: i18n.t("whyBecomeAnOrganDonor.lists.list1.description", {
        ns: "transplant",
      }),
      iconUrl: "/images/kidney_educations/transplant/Icon 11-01.svg",
    },
    {
      title: i18n.t("whyBecomeAnOrganDonor.lists.list2.title", {
        ns: "transplant",
      }),
      subTitle: i18n.t("whyBecomeAnOrganDonor.lists.list2.description", {
        ns: "transplant",
      }),
      iconUrl: "/images/kidney_educations/transplant/Icon 12-01.svg",
    },
    {
      title: i18n.t("whyBecomeAnOrganDonor.lists.list3.title", {
        ns: "transplant",
      }),
      subTitle: i18n.t("whyBecomeAnOrganDonor.lists.list3.description", {
        ns: "transplant",
      }),
      iconUrl: "/images/kidney_educations/transplant/Icon 13-01.svg",
    },
  ];

  const becomeAnOrganDonorTodayData = [
    {
      title: i18n.t("becomeAnOrganDonorToday.lists.list1.title", {
        ns: "transplant",
      }),
      subTitle: i18n.t("becomeAnOrganDonorToday.lists.list1.description", {
        ns: "transplant",
      }),
      iconUrl: "/images/kidney_educations/transplant/Icon 14-01.svg",
    },
    {
      title: i18n.t("becomeAnOrganDonorToday.lists.list2.title", {
        ns: "transplant",
      }),
      subTitle: i18n.t("becomeAnOrganDonorToday.lists.list2.description", {
        ns: "transplant",
      }),
      iconUrl: "/images/kidney_educations/transplant/Icon 15-01.svg",
    },
    {
      title: i18n.t("becomeAnOrganDonorToday.lists.list3.title", {
        ns: "transplant",
      }),
      subTitle: i18n.t("becomeAnOrganDonorToday.lists.list3.description", {
        ns: "transplant",
      }),
      iconUrl: "/images/kidney_educations/transplant/Icon 16-01.svg",
    },
  ];

  return {
    whatIsKidneyTransplantData,
    benefitsOfKidneyTransplantData,
    evaluationProcessForKidneyTransplantData,
    nottoOrganResponsibilitiesData,
    sottoOrganResponsibilitiesData,
    whyBecomeAnOrganDonorData,
    becomeAnOrganDonorTodayData,
  };
};
