import React from "react";
import { useTranslation } from "react-i18next";
import BlogList from "../../../Blogs/BlogComponents/BlogList";
import Spacing from "../../../../components/Spacing";
import { thirdBlogContentDatas } from "./thirdBlogContents";
import BlogOrderedList from "../../../Blogs/BlogComponents/BlogOrderedList";

const ThirdBlog = () => {
  const { t } = useTranslation(["dialysisAccessoriesBlog"]);
  const {
    howDoesDialyzerWorkListData,
    typesOfDialyzersListData,
    potentialDialyzerReactionsListData,
  } = thirdBlogContentDatas();

  return (
    <>
      <p>{t(`blogs.103.innerHTML.blogDescription`)}</p>

      <Spacing md="10" lg="10" />
      <h2>{t(`blogs.103.innerHTML.howDoesDialyzerWork.title`)}</h2>
      <div className="cs_blog_un_ord_list">
        <BlogList data={howDoesDialyzerWorkListData} />
      </div>

      <Spacing md="20" lg="20" />
      <h2>{t(`blogs.103.innerHTML.typesOfDialyzers.title`)}</h2>
      <p>{t(`blogs.103.innerHTML.typesOfDialyzers.description`)}</p>
      <div className="cs_blog_ord_list">
        <BlogOrderedList data={typesOfDialyzersListData} />
      </div>

      <Spacing md="30" lg="30" />
      <h2>{t(`blogs.103.innerHTML.potentialDialyzerReactions.title`)}</h2>
      <p>{t(`blogs.103.innerHTML.potentialDialyzerReactions.description`)}</p>
      <div className="cs_blog_un_ord_list">
        <BlogList data={potentialDialyzerReactionsListData} />
      </div>

      <Spacing md="30" lg="30" />
      <h3>{t(`blogs.103.innerHTML.notes`)}</h3>

      <Spacing md="30" lg="30" />
      <h2>
        {t(`blogs.103.innerHTML.yourPartnerAffordableDialysisCare.title`)}
      </h2>
      <p>
        {t(`blogs.103.innerHTML.yourPartnerAffordableDialysisCare.description`)}
      </p>

      <Spacing md="20" lg="20" />
      <h2>{t(`blogs.103.innerHTML.remember.title`)}</h2>
      <p>{t(`blogs.103.innerHTML.remember.description`)}</p>

      <Spacing md="20" lg="20" />
      <h2>{t(`blogs.103.innerHTML.disclaimer.title`)}</h2>
      <p>{t(`blogs.103.innerHTML.disclaimer.description`)}</p>
    </>
  );
};

export default ThirdBlog;
