import React, { useRef } from "react";
import axios from "axios";
import { subscriberTemplate } from "../../constants/email-template/subscribe-template";
import { toastSuccess, toastFailure } from "../../helpers/toastHelper";
const baseUrl = process.env.REACT_APP_BASE_API_URL;

export default function NewsletterForm({ label, btnText, btnArrowUrl }) {
  const emailRef = useRef(null);

  const handleSubmit = async (e) => {
    e.preventDefault();
    // Create a new FormData object to send data as multipart/form-data
    if(!emailRef.current.value){
      toastFailure("Please fill in email!");
      return;
    }
    const data = new FormData();
    data.append(
      "body",
      subscriberTemplate({
        email: emailRef.current.value,
      })
    );
    data.append("subject", "Add to subscription list");

    try {
      await axios.post(`${baseUrl}/contact-us`, data);
      toastSuccess("Subscribed!");
      resetForm();
    } catch (error) {
      toastFailure("Something went wrong. Please retry later.");
    }
  };
  const resetForm = () => {
    emailRef.current.value = "";
  };
  return (
    <>
      {label && <p>Your Email</p>}
      <form action="#" className="cs_newsletter_form">
        <input
          ref={emailRef}
          required
          type="text"
          className="cs_form_field"
          placeholder="example@email.com"
        />
        <button className="cs_btn cs_style_1" onClick={handleSubmit}>
          <span>{btnText}</span>
          <i>
            <img loading="lazy" src={btnArrowUrl} alt="Icon" />
            <img loading="lazy" src={btnArrowUrl} alt="Icon" />
          </i>
        </button>
      </form>
    </>
  );
}
