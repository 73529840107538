import React, { useEffect, useState } from "react";
import Spacing from "../Spacing";
import { Link } from "react-router-dom";

export default function KidneyBreadcrumStyle() {
  const [urlSegments, setUrlSegments] = useState([]);
  useEffect(() => {
    const pathSegments = window.location.pathname
      .split("/")
      .filter((segment) => segment !== "");
    setUrlSegments(pathSegments);
  }, []);
  return (
    <>
      <Spacing md="100" />
      <div className="cs_page_heading">
        <div className="container">
          <div className="cs_page_heading_in">
            <ol className="breadcrumb text-capitalize">
              <li className="breadcrumb-item">
                <Link to="/">Home</Link>
              </li>
              {urlSegments.map((segment, index) => (
                <li key={index} className="breadcrumb-item">
                  {index < urlSegments.length - 1 ? (
                    <Link to={`/${urlSegments.slice(0, index + 1).join("/")}`}>
                      {segment}
                    </Link>
                  ) : (
                    <span>{segment}</span>
                  )}
                </li>
              ))}
            </ol>
            {/* <div className="cs_quick_faq">
              <h5>Quick FAQ</h5>
              <div className="dropdown">
                <button
                  className="btn btn-info dropdown-toggle cs_quick_faq_btn"
                  type="button"
                >
                  <i>
                    <img loading="lazy" src="/images/icons/right_arrow_blue.svg" alt="Icon" />
                  </i>
                </button>
                <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                  <Link
                    className="dropdown-item"
                    to="/kidney-education/healthy-kidneys"
                  >
                    Page 1
                  </Link>
                  <Link
                    className="dropdown-item"
                    to="/kidney-education/healthy-kidneys"
                  >
                    Page 2
                  </Link>
                  <Link
                    className="dropdown-item"
                    to="/kidney-education/healthy-kidneys"
                  >
                    Page 3
                  </Link>
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </>
  );
}
