import { Trans } from "react-i18next";
import i18n from "../../../i18n/i18n";

export const eLearningCourseContentDatas = () => {
  const ourInteractiveELearningCourseOffersData = [
    {
      title: i18n.t("ourInteractiveELearningCourseOffers.lists.list1.title", {
        ns: "eLearningCourse",
      }),
      subTitle: i18n.t(
        "ourInteractiveELearningCourseOffers.lists.list1.description",
        {
          ns: "eLearningCourse",
        }
      ),
      iconUrl: "/images/our_services/e-learning-course/Icon 2-01.svg",
    },
    {
      title: i18n.t("ourInteractiveELearningCourseOffers.lists.list2.title", {
        ns: "eLearningCourse",
      }),
      subTitle: i18n.t(
        "ourInteractiveELearningCourseOffers.lists.list2.description",
        {
          ns: "eLearningCourse",
        }
      ),
      iconUrl: "/images/our_services/e-learning-course/Icon 2-02.svg",
    },
    {
      title: i18n.t("ourInteractiveELearningCourseOffers.lists.list3.title", {
        ns: "eLearningCourse",
      }),
      subTitle: i18n.t(
        "ourInteractiveELearningCourseOffers.lists.list3.description",
        {
          ns: "eLearningCourse",
        }
      ),
      iconUrl: "/images/our_services/e-learning-course/Icon 2-03.svg",
    },
    {
      title: i18n.t("ourInteractiveELearningCourseOffers.lists.list4.title", {
        ns: "eLearningCourse",
      }),
      subTitle: i18n.t(
        "ourInteractiveELearningCourseOffers.lists.list4.description",
        {
          ns: "eLearningCourse",
        }
      ),
      iconUrl: "/images/our_services/e-learning-course/Icon 2-04.svg",
    },
  ];

  const keyBenefitsOurELearningCourseData = [
    {
      title: i18n.t("keyBenefitsOurELearningCourse.lists.list1.title", {
        ns: "eLearningCourse",
      }),
      subTitle: i18n.t(
        "keyBenefitsOurELearningCourse.lists.list1.description",
        {
          ns: "eLearningCourse",
        }
      ),
      iconUrl: "/images/our_services/e-learning-course/Icon 1-01.svg",
    },
    {
      title: i18n.t("keyBenefitsOurELearningCourse.lists.list2.title", {
        ns: "eLearningCourse",
      }),
      subTitle: i18n.t(
        "keyBenefitsOurELearningCourse.lists.list2.description",
        {
          ns: "eLearningCourse",
        }
      ),
      iconUrl: "/images/our_services/e-learning-course/Icon 1-02.svg",
    },
    {
      title: i18n.t("keyBenefitsOurELearningCourse.lists.list3.title", {
        ns: "eLearningCourse",
      }),
      subTitle: i18n.t(
        "keyBenefitsOurELearningCourse.lists.list3.description",
        {
          ns: "eLearningCourse",
        }
      ),
      iconUrl: "/images/our_services/e-learning-course/Icon 1-03.svg",
    },
    {
      title: i18n.t("keyBenefitsOurELearningCourse.lists.list4.title", {
        ns: "eLearningCourse",
      }),
      subTitle: i18n.t(
        "keyBenefitsOurELearningCourse.lists.list4.description",
        {
          ns: "eLearningCourse",
        }
      ),
      iconUrl: "/images/our_services/e-learning-course/Icon 1-04.svg",
    },
    {
      title: i18n.t("keyBenefitsOurELearningCourse.lists.list5.title", {
        ns: "eLearningCourse",
      }),
      subTitle: i18n.t(
        "keyBenefitsOurELearningCourse.lists.list5.description",
        {
          ns: "eLearningCourse",
        }
      ),
      iconUrl: "/images/our_services/e-learning-course/Icon 1-05.svg",
    },
  ];

  const additionalFeaturesData = [
    {
      title: i18n.t("additionalFeatures.lists.list1.title", {
        ns: "eLearningCourse",
      }),
      subTitle: i18n.t("additionalFeatures.lists.list1.description", {
        ns: "eLearningCourse",
      }),
      iconUrl: "/images/our_services/e-learning-course/Icon 1-06.svg",
    },
    {
      title: i18n.t("additionalFeatures.lists.list2.title", {
        ns: "eLearningCourse",
      }),
      subTitle: i18n.t("additionalFeatures.lists.list2.description", {
        ns: "eLearningCourse",
      }),
      iconUrl: "/images/our_services/e-learning-course/Icon 1-07.svg",
    },
    {
      title: i18n.t("additionalFeatures.lists.list3.title", {
        ns: "eLearningCourse",
      }),
      subTitle: i18n.t("additionalFeatures.lists.list3.description", {
        ns: "eLearningCourse",
      }),
      iconUrl: "/images/our_services/e-learning-course/Icon 1-08.svg",
    },
  ];

  const eLearningCourseForCKDPatientsModule1 = [
    {
      title: (
        <Trans
          i18nKey={i18n.t(
            "eLearningCourseForCKDPatients.module1.lists.list1.text",
            {
              ns: "eLearningCourse",
            }
          )}
          components={{
            boldText: <b />,
          }}
        />
      ),
    },
    {
      title: (
        <Trans
          i18nKey={i18n.t(
            "eLearningCourseForCKDPatients.module1.lists.list2.text",
            {
              ns: "eLearningCourse",
            }
          )}
          components={{
            boldText: <b />,
          }}
        />
      ),
    },
    {
      title: (
        <Trans
          i18nKey={i18n.t(
            "eLearningCourseForCKDPatients.module1.lists.list3.text",
            {
              ns: "eLearningCourse",
            }
          )}
          components={{
            boldText: <b />,
          }}
        />
      ),
    },
    {
      title: (
        <Trans
          i18nKey={i18n.t(
            "eLearningCourseForCKDPatients.module1.lists.list4.text",
            {
              ns: "eLearningCourse",
            }
          )}
          components={{
            boldText: <b />,
          }}
        />
      ),
    },
  ];

  const eLearningCourseForCKDPatientsModule2 = [
    {
      title: (
        <Trans
          i18nKey={i18n.t(
            "eLearningCourseForCKDPatients.module2.lists.list1.text",
            {
              ns: "eLearningCourse",
            }
          )}
          components={{
            boldText: <b />,
          }}
        />
      ),
    },
    {
      title: (
        <Trans
          i18nKey={i18n.t(
            "eLearningCourseForCKDPatients.module2.lists.list2.text",
            {
              ns: "eLearningCourse",
            }
          )}
          components={{
            boldText: <b />,
          }}
        />
      ),
    },
    {
      title: (
        <Trans
          i18nKey={i18n.t(
            "eLearningCourseForCKDPatients.module2.lists.list3.text",
            {
              ns: "eLearningCourse",
            }
          )}
          components={{
            boldText: <b />,
          }}
        />
      ),
    },
    {
      title: (
        <Trans
          i18nKey={i18n.t(
            "eLearningCourseForCKDPatients.module2.lists.list4.text",
            {
              ns: "eLearningCourse",
            }
          )}
          components={{
            boldText: <b />,
          }}
        />
      ),
    },
    {
      title: (
        <Trans
          i18nKey={i18n.t(
            "eLearningCourseForCKDPatients.module2.lists.list5.text",
            {
              ns: "eLearningCourse",
            }
          )}
          components={{
            boldText: <b />,
          }}
        />
      ),
    },
  ];

  const eLearningCourseForCKDPatientsModule3 = [
    {
      title: (
        <Trans
          i18nKey={i18n.t(
            "eLearningCourseForCKDPatients.module3.lists.list1.text",
            {
              ns: "eLearningCourse",
            }
          )}
          components={{
            boldText: <b />,
          }}
        />
      ),
    },
    {
      title: (
        <Trans
          i18nKey={i18n.t(
            "eLearningCourseForCKDPatients.module3.lists.list2.text",
            {
              ns: "eLearningCourse",
            }
          )}
          components={{
            boldText: <b />,
          }}
        />
      ),
    },
    {
      title: (
        <Trans
          i18nKey={i18n.t(
            "eLearningCourseForCKDPatients.module3.lists.list3.text",
            {
              ns: "eLearningCourse",
            }
          )}
          components={{
            boldText: <b />,
          }}
        />
      ),
    },
    {
      title: (
        <Trans
          i18nKey={i18n.t(
            "eLearningCourseForCKDPatients.module3.lists.list4.text",
            {
              ns: "eLearningCourse",
            }
          )}
          components={{
            boldText: <b />,
          }}
        />
      ),
    },
    {
      title: (
        <Trans
          i18nKey={i18n.t(
            "eLearningCourseForCKDPatients.module3.lists.list5.text",
            {
              ns: "eLearningCourse",
            }
          )}
          components={{
            boldText: <b />,
          }}
        />
      ),
    },
  ];

  const eLearningCourseForCKDPatientsModule4 = [
    {
      title: (
        <Trans
          i18nKey={i18n.t(
            "eLearningCourseForCKDPatients.module4.lists.list1.text",
            {
              ns: "eLearningCourse",
            }
          )}
          components={{
            boldText: <b />,
          }}
        />
      ),
    },
    {
      title: (
        <Trans
          i18nKey={i18n.t(
            "eLearningCourseForCKDPatients.module4.lists.list2.text",
            {
              ns: "eLearningCourse",
            }
          )}
          components={{
            boldText: <b />,
          }}
        />
      ),
    },
    {
      title: (
        <Trans
          i18nKey={i18n.t(
            "eLearningCourseForCKDPatients.module4.lists.list3.text",
            {
              ns: "eLearningCourse",
            }
          )}
          components={{
            boldText: <b />,
          }}
        />
      ),
    },
    {
      title: (
        <Trans
          i18nKey={i18n.t(
            "eLearningCourseForCKDPatients.module4.lists.list4.text",
            {
              ns: "eLearningCourse",
            }
          )}
          components={{
            boldText: <b />,
          }}
        />
      ),
    },
    {
      title: (
        <Trans
          i18nKey={i18n.t(
            "eLearningCourseForCKDPatients.module4.lists.list5.text",
            {
              ns: "eLearningCourse",
            }
          )}
          components={{
            boldText: <b />,
          }}
        />
      ),
    },
  ];

  const eLearningCourseForCKDPatientsModule5 = [
    {
      title: (
        <Trans
          i18nKey={i18n.t(
            "eLearningCourseForCKDPatients.module5.lists.list1.text",
            {
              ns: "eLearningCourse",
            }
          )}
          components={{
            boldText: <b />,
          }}
        />
      ),
    },
    {
      title: (
        <Trans
          i18nKey={i18n.t(
            "eLearningCourseForCKDPatients.module5.lists.list2.text",
            {
              ns: "eLearningCourse",
            }
          )}
          components={{
            boldText: <b />,
          }}
        />
      ),
    },
    {
      title: (
        <Trans
          i18nKey={i18n.t(
            "eLearningCourseForCKDPatients.module5.lists.list3.text",
            {
              ns: "eLearningCourse",
            }
          )}
          components={{
            boldText: <b />,
          }}
        />
      ),
    },
    {
      title: (
        <Trans
          i18nKey={i18n.t(
            "eLearningCourseForCKDPatients.module5.lists.list4.text",
            {
              ns: "eLearningCourse",
            }
          )}
          components={{
            boldText: <b />,
          }}
        />
      ),
    },
    {
      title: (
        <Trans
          i18nKey={i18n.t(
            "eLearningCourseForCKDPatients.module5.lists.list5.text",
            {
              ns: "eLearningCourse",
            }
          )}
          components={{
            boldText: <b />,
          }}
        />
      ),
    },
  ];

  const eLearningCourseForCKDPatientsModule6 = [
    {
      title: (
        <Trans
          i18nKey={i18n.t(
            "eLearningCourseForCKDPatients.module6.lists.list1.text",
            {
              ns: "eLearningCourse",
            }
          )}
          components={{
            boldText: <b />,
          }}
        />
      ),
    },
    {
      title: (
        <Trans
          i18nKey={i18n.t(
            "eLearningCourseForCKDPatients.module6.lists.list2.text",
            {
              ns: "eLearningCourse",
            }
          )}
          components={{
            boldText: <b />,
          }}
        />
      ),
    },
    {
      title: (
        <Trans
          i18nKey={i18n.t(
            "eLearningCourseForCKDPatients.module6.lists.list3.text",
            {
              ns: "eLearningCourse",
            }
          )}
          components={{
            boldText: <b />,
          }}
        />
      ),
    },
    {
      title: (
        <Trans
          i18nKey={i18n.t(
            "eLearningCourseForCKDPatients.module6.lists.list4.text",
            {
              ns: "eLearningCourse",
            }
          )}
          components={{
            boldText: <b />,
          }}
        />
      ),
    },
    {
      title: (
        <Trans
          i18nKey={i18n.t(
            "eLearningCourseForCKDPatients.module6.lists.list5.text",
            {
              ns: "eLearningCourse",
            }
          )}
          components={{
            boldText: <b />,
          }}
        />
      ),
    },
  ];

  const additionalConsiderationsData = [
    {
      title: i18n.t("additionalConsiderations.lists.list1.title", {
        ns: "eLearningCourse",
      }),
    },
    {
      title: i18n.t("additionalConsiderations.lists.list2.title", {
        ns: "eLearningCourse",
      }),
    },
    {
      title: i18n.t("additionalConsiderations.lists.list3.title", {
        ns: "eLearningCourse",
      }),
    },
    {
      title: i18n.t("additionalConsiderations.lists.list4.title", {
        ns: "eLearningCourse",
      }),
    },
    {
      title: i18n.t("additionalConsiderations.lists.list5.title", {
        ns: "eLearningCourse",
      }),
    },
  ];

  return {
    ourInteractiveELearningCourseOffersData,
    keyBenefitsOurELearningCourseData,
    additionalFeaturesData,
    eLearningCourseForCKDPatientsModule1,
    eLearningCourseForCKDPatientsModule2,
    eLearningCourseForCKDPatientsModule3,
    eLearningCourseForCKDPatientsModule4,
    eLearningCourseForCKDPatientsModule5,
    eLearningCourseForCKDPatientsModule6,
    additionalConsiderationsData,
  };
};
