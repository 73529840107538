import React from 'react';
// import SideMenuWidget from '../Widget/SideMenuWidget';
import RecentPostWidget from '../Widget/RecentPostWidget';
import NewsletterStyle5 from '../Widget/NewsletterStyle5';
import { blogDatas } from '../../constants/common-contents';
import { CustomToastContainer } from "../../helpers/toastHelper";


// const categoryData = [
//   {
//     title: 'Health Tips and Tricks',
//     url: '/',
//   },
//   {
//     title: 'Trends and Analysis',
//     url: '/',
//   },
//   {
//     title: 'Time Management',
//     url: '/',
//   },
// ];

export default function Sidebar() {
  return (
    <div className="cs_sidebar">
      {/* <div className="cs_sidebar_item widget_categories">
        <SideMenuWidget title="Popular Categories" data={categoryData} />
      </div> */}
      <CustomToastContainer />
      <div className="cs_sidebar_item">
        <RecentPostWidget title="Popular Articles" data={blogDatas().slice(0, 5)} />
      </div>
      <div className="cs_sidebar_item widget_categories">
        <NewsletterStyle5 title="Newsletter Sign Up Form" />
      </div>
    </div>
  );
}
