import { Trans } from "react-i18next";
import i18n from "../../../i18n/i18n";

export const kidneyDietContentDatas = () => {
  const understandingYourNeedsData = [
    {
      title: i18n.t("understandingYourNeeds.lists.list1.title", {
        ns: "kidneyDiet",
      }),
      subTitle: i18n.t("understandingYourNeeds.lists.list1.description", {
        ns: "kidneyDiet",
      }),
      iconUrl: "/images/our_services/kidney-diet/Icon 1-01.svg",
    },
    {
      title: i18n.t("understandingYourNeeds.lists.list2.title", {
        ns: "kidneyDiet",
      }),
      subTitle: i18n.t("understandingYourNeeds.lists.list2.description", {
        ns: "kidneyDiet",
      }),
      iconUrl: "/images/our_services/kidney-diet/Icon 1-02.svg",
    },
    {
      title: i18n.t("understandingYourNeeds.lists.list3.title", {
        ns: "kidneyDiet",
      }),
      subTitle: i18n.t("understandingYourNeeds.lists.list3.description", {
        ns: "kidneyDiet",
      }),
      iconUrl: "/images/our_services/kidney-diet/Icon 1-03.svg",
    },
  ];

  const nutritionalValueEducationData = [
    {
      title: i18n.t("nutritionalValueEducation.lists.list1.title", {
        ns: "kidneyDiet",
      }),
      subTitle: i18n.t("nutritionalValueEducation.lists.list1.description", {
        ns: "kidneyDiet",
      }),
      iconUrl: "/images/our_services/kidney-diet/Icon 2-01.svg",
    },
    {
      title: i18n.t("nutritionalValueEducation.lists.list2.title", {
        ns: "kidneyDiet",
      }),
      subTitle: i18n.t("nutritionalValueEducation.lists.list2.description", {
        ns: "kidneyDiet",
      }),
      iconUrl: "/images/our_services/kidney-diet/Icon 2-02.svg",
    },
    {
      title: i18n.t("nutritionalValueEducation.lists.list3.title", {
        ns: "kidneyDiet",
      }),
      subTitle: i18n.t("nutritionalValueEducation.lists.list3.description", {
        ns: "kidneyDiet",
      }),
      iconUrl: "/images/our_services/kidney-diet/Icon 2-03.svg",
    },
    {
      title: i18n.t("nutritionalValueEducation.lists.list4.title", {
        ns: "kidneyDiet",
      }),
      subTitle: i18n.t("nutritionalValueEducation.lists.list4.description", {
        ns: "kidneyDiet",
      }),
      iconUrl: "/images/our_services/kidney-diet/Icon 2-04.svg",
    },
  ];

  const personalizedDietPlansData = [
    {
      title: i18n.t("personalizedDietPlans.lists.list1.title", {
        ns: "kidneyDiet",
      }),
      subTitle: i18n.t("personalizedDietPlans.lists.list1.description", {
        ns: "kidneyDiet",
      }),
      iconUrl: "/images/our_services/kidney-diet/Icon 1-04.svg",
    },
    {
      title: (
        <Trans
          i18nKey={i18n.t("personalizedDietPlans.lists.list2.title", {
            ns: "kidneyDiet",
          })}
          components={{
            doubleQuotes: <q />,
          }}
        />
      ),
      subTitle: i18n.t("personalizedDietPlans.lists.list2.description", {
        ns: "kidneyDiet",
      }),
      iconUrl: "/images/our_services/kidney-diet/Icon 1-05.svg",
    },
    {
      title: i18n.t("personalizedDietPlans.lists.list3.title", {
        ns: "kidneyDiet",
      }),
      subTitle: i18n.t("personalizedDietPlans.lists.list3.description", {
        ns: "kidneyDiet",
      }),
      iconUrl: "/images/our_services/kidney-diet/Icon 1-06.svg",
    },
    {
      title: i18n.t("personalizedDietPlans.lists.list4.title", {
        ns: "kidneyDiet",
      }),
      subTitle: i18n.t("personalizedDietPlans.lists.list4.description", {
        ns: "kidneyDiet",
      }),
      iconUrl: "/images/our_services/kidney-diet/Icon 1-07.svg",
    },
    {
      title: i18n.t("personalizedDietPlans.lists.list5.title", {
        ns: "kidneyDiet",
      }),
      subTitle: i18n.t("personalizedDietPlans.lists.list5.description", {
        ns: "kidneyDiet",
      }),
      iconUrl: "/images/our_services/kidney-diet/Icon 1-08.svg",
    },
  ];

  const supportBeyondDietData = [
    {
      title: i18n.t("supportBeyondDiet.lists.list1.title", {
        ns: "kidneyDiet",
      }),
      subTitle: i18n.t("supportBeyondDiet.lists.list1.description", {
        ns: "kidneyDiet",
      }),
      iconUrl: "/images/our_services/kidney-diet/Icon 1-09.svg",
    },
    {
      title: i18n.t("supportBeyondDiet.lists.list2.title", {
        ns: "kidneyDiet",
      }),
      subTitle: i18n.t("supportBeyondDiet.lists.list2.description", {
        ns: "kidneyDiet",
      }),
      iconUrl: "/images/our_services/kidney-diet/Icon 1-10.svg",
    },
    {
      title: i18n.t("supportBeyondDiet.lists.list3.title", {
        ns: "kidneyDiet",
      }),
      subTitle: i18n.t("supportBeyondDiet.lists.list3.description", {
        ns: "kidneyDiet",
      }),
      iconUrl: "/images/our_services/kidney-diet/Icon 1-11.svg",
    },
    {
      title: i18n.t("supportBeyondDiet.lists.list4.title", {
        ns: "kidneyDiet",
      }),
      subTitle: i18n.t("supportBeyondDiet.lists.list4.description", {
        ns: "kidneyDiet",
      }),
      iconUrl: "/images/our_services/kidney-diet/Icon 1-12.svg",
    },
  ];

  return {
    understandingYourNeedsData,
    nutritionalValueEducationData,
    personalizedDietPlansData,
    supportBeyondDietData,
  };
};
