import i18n from "../../../../i18n/i18n";

export const thirdBlogContentDatas = () => {
  const howDoesDialyzerWorkListData = [
    {
      title: i18n.t("blogs.103.innerHTML.howDoesDialyzerWork.list1.title", {
        ns: "dialysisAccessoriesBlog",
      }),
      description: i18n.t(
        "blogs.103.innerHTML.howDoesDialyzerWork.list1.description",
        {
          ns: "dialysisAccessoriesBlog",
        }
      ),
    },
    {
      title: i18n.t("blogs.103.innerHTML.howDoesDialyzerWork.list2.title", {
        ns: "dialysisAccessoriesBlog",
      }),
      description: i18n.t(
        "blogs.103.innerHTML.howDoesDialyzerWork.list2.description",
        {
          ns: "dialysisAccessoriesBlog",
        }
      ),
    },
    {
      title: i18n.t("blogs.103.innerHTML.howDoesDialyzerWork.list3.title", {
        ns: "dialysisAccessoriesBlog",
      }),
      description: i18n.t(
        "blogs.103.innerHTML.howDoesDialyzerWork.list3.description",
        {
          ns: "dialysisAccessoriesBlog",
        }
      ),
    },
  ];

  const typesOfDialyzersListData = [
    {
      title: i18n.t(
        "blogs.103.innerHTML.typesOfDialyzers.singleUseReusableDialyzers.title",
        {
          ns: "dialysisAccessoriesBlog",
        }
      ),
      childList: [
        {
          description: i18n.t(
            "blogs.103.innerHTML.typesOfDialyzers.singleUseReusableDialyzers.list1.description",
            {
              ns: "dialysisAccessoriesBlog",
            }
          ),
        },
        {
          description: i18n.t(
            "blogs.103.innerHTML.typesOfDialyzers.singleUseReusableDialyzers.list2.description",
            {
              ns: "dialysisAccessoriesBlog",
            }
          ),
        },
      ],
    },
    {
      title: i18n.t(
        "blogs.103.innerHTML.typesOfDialyzers.highFluxLowFluxDialyzers.title",
        {
          ns: "dialysisAccessoriesBlog",
        }
      ),
      childList: [
        {
          description: i18n.t(
            "blogs.103.innerHTML.typesOfDialyzers.highFluxLowFluxDialyzers.list1.description",
            {
              ns: "dialysisAccessoriesBlog",
            }
          ),
        },
        {
          description: i18n.t(
            "blogs.103.innerHTML.typesOfDialyzers.highFluxLowFluxDialyzers.list2.description",
            {
              ns: "dialysisAccessoriesBlog",
            }
          ),
        },
      ],
    },
    {
      title: i18n.t(
        "blogs.103.innerHTML.typesOfDialyzers.pediatricDialyzers.title",
        {
          ns: "dialysisAccessoriesBlog",
        }
      ),
      description: i18n.t(
        "blogs.103.innerHTML.typesOfDialyzers.pediatricDialyzers.description",
        {
          ns: "dialysisAccessoriesBlog",
        }
      ),
    },
    {
      title: i18n.t(
        "blogs.103.innerHTML.typesOfDialyzers.variousSizesBrands.title",
        {
          ns: "dialysisAccessoriesBlog",
        }
      ),
      description: i18n.t(
        "blogs.103.innerHTML.typesOfDialyzers.variousSizesBrands.description",
        {
          ns: "dialysisAccessoriesBlog",
        }
      ),
    },
  ];

  const potentialDialyzerReactionsListData = [
    {
      title: i18n.t(
        "blogs.103.innerHTML.potentialDialyzerReactions.list1.title",
        {
          ns: "dialysisAccessoriesBlog",
        }
      ),
      description: i18n.t(
        "blogs.103.innerHTML.potentialDialyzerReactions.list1.description",
        {
          ns: "dialysisAccessoriesBlog",
        }
      ),
    },
    {
      title: i18n.t(
        "blogs.103.innerHTML.potentialDialyzerReactions.list2.title",
        {
          ns: "dialysisAccessoriesBlog",
        }
      ),
      description: i18n.t(
        "blogs.103.innerHTML.potentialDialyzerReactions.list2.description",
        {
          ns: "dialysisAccessoriesBlog",
        }
      ),
    },
  ];

  return {
    howDoesDialyzerWorkListData,
    typesOfDialyzersListData,
    potentialDialyzerReactionsListData,
  };
};
