/* eslint-disable jsx-a11y/anchor-has-content */
import React from "react";
import Section from "../../components/Section";
import { Trans, useTranslation } from "react-i18next";
import Spacing from "../../components/Spacing";
import BlogList from "../Blogs/BlogComponents/BlogList";
import { termsConditionContentDatas } from "./termsConditionsContents";

const TermsAndConditions = () => {
  const { t } = useTranslation(["termsAndConditions"]);

  const {
    licenceToAccessListData,
    yourConductListData,
    yourAccountListData,
    unavailabilityOfProductListData,
    deliveryListData,
    returnNotApplicableListData,
    medicineDeliveryPolicyListData,
    diagnosticServicePolicyListData,
    counsellingServicePolicyListData,
    educationalServicePolicyListData,
    ourAddressListData,
  } = termsConditionContentDatas();

  return (
    <Section
      topMd={150}
      topLg={150}
      topXl={150}
      bottomXl={80}
      bottomLg={80}
      bottomMd={80}
    >
      <div className="container">
        <div className="cs_blog_details">
          <h2>{t(`introduction.title`)}</h2>
          <p className="cs_custom-para-link">
            <Trans
              i18nKey={t("introduction.description.para1")}
              components={{
                anchorText: (
                  <a
                    href="https://www.kidneyneeds.com/"
                    target="_blank"
                    rel="noreferrer"
                  />
                ),
                boldText: <b />,
              }}
            />
          </p>
          <p>{t(`introduction.description.para2`)}</p>
          <p>{t(`introduction.description.para3`)}</p>

          <Spacing md="30" lg="30" />
          <h2>{t(`privacy.title`)}</h2>
          <p className="cs_custom-para-link">
            <Trans
              i18nKey={t("privacy.description")}
              components={{
                anchorText: (
                  <a
                    href="https://www.kidneyneeds.com/"
                    target="_blank"
                    rel="noreferrer"
                  />
                ),
                boldText: <b />,
              }}
            />
          </p>

          <Spacing md="30" lg="30" />
          <h2>{t(`electronicCommunications.title`)}</h2>
          <p>{t(`electronicCommunications.description`)}</p>

          <Spacing md="30" lg="30" />
          <h2>{t(`trademarksAndCopyright.title`)}</h2>
          <p className="cs_custom-para-link">
            <Trans
              i18nKey={t("trademarksAndCopyright.description.para1")}
              components={{
                anchorText: (
                  <a
                    href="https://www.kidneyneeds.com/"
                    target="_blank"
                    rel="noreferrer"
                  />
                ),
                boldText: <b />,
              }}
            />
          </p>
          <p>{t(`trademarksAndCopyright.description.para2`)}</p>

          <Spacing md="30" lg="30" />
          <h2>{t(`continuedAvailability.title`)}</h2>
          <p>{t(`continuedAvailability.description`)}</p>

          <Spacing md="30" lg="30" />
          <h2>{t(`licenceToAccess.title`)}</h2>
          <p>{t(`licenceToAccess.description.para1`)}</p>
          <p>{t(`licenceToAccess.description.para2`)}</p>
          <p>{t(`licenceToAccess.description.para3`)}</p>
          <div className="cs_blog_un_ord_list">
            <BlogList data={licenceToAccessListData} />
          </div>

          <Spacing md="30" lg="30" />
          <h2>{t(`yourConduct.title`)}</h2>
          <p>{t(`yourConduct.description`)}</p>
          <div className="cs_blog_un_ord_list">
            <BlogList data={yourConductListData} />
          </div>

          <Spacing md="30" lg="30" />
          <h2>{t(`yourAccount.title`)}</h2>
          <div className="cs_blog_un_ord_list">
            <BlogList data={yourAccountListData} />
          </div>

          <Spacing md="30" lg="30" />
          <h2>{t(`yourSubmission.title`)}</h2>
          <p>{t(`yourSubmission.description`)}</p>

          <Spacing md="30" lg="30" />
          <h2>{t(`claimsAgainstObjectionableContent.title`)}</h2>
          <p>{t(`claimsAgainstObjectionableContent.description.para1`)}</p>
          <p>{t(`claimsAgainstObjectionableContent.description.para2`)}</p>

          <Spacing md="30" lg="30" />
          <h2>{t(`claimsAgainstInfringingContent.title`)}</h2>
          <p>{t(`claimsAgainstInfringingContent.description`)}</p>

          <Spacing md="30" lg="30" />
          <h2>{t(`productDescriptions.title`)}</h2>
          <p>{t(`productDescriptions.description`)}</p>

          <Spacing md="30" lg="30" />
          <h2>{t(`unavailabilityOfProduct.title`)}</h2>
          <p>{t(`unavailabilityOfProduct.description`)}</p>
          <div className="cs_blog_un_ord_list">
            <BlogList data={unavailabilityOfProductListData} />
          </div>
          <p>{t(`unavailabilityOfProduct.notes`)}</p>

          <Spacing md="30" lg="30" />
          <h2>{t(`thirdPartyBusiness.title`)}</h2>
          <p>{t(`thirdPartyBusiness.description.para1`)}</p>
          <p>{t(`thirdPartyBusiness.description.para2`)}</p>

          <Spacing md="30" lg="30" />
          <h2>{t(`delivery.title`)}</h2>
          <div className="cs_blog_un_ord_list">
            <BlogList data={deliveryListData} />
          </div>

          <Spacing md="30" lg="30" />
          <h2>{t(`eventsBeyondOurControl.title`)}</h2>
          <p>{t(`eventsBeyondOurControl.description`)}</p>

          <Spacing md="30" lg="30" />
          <h2>{t(`otherBusiness.title`)}</h2>
          <p>{t(`otherBusiness.description`)}</p>

          <Spacing md="30" lg="30" />
          <h2>{t(`returnNotApplicable.title`)}</h2>
          <div className="cs_blog_un_ord_list">
            <BlogList data={returnNotApplicableListData} />
          </div>

          <Spacing md="30" lg="30" />
          <h2>{t(`medicineDeliveryPolicy.title`)}</h2>
          <div className="cs_blog_un_ord_list">
            <BlogList data={medicineDeliveryPolicyListData} />
          </div>

          <Spacing md="30" lg="30" />
          <h2>{t(`diagnosticServicePolicy.title`)}</h2>
          <div className="cs_blog_un_ord_list">
            <BlogList data={diagnosticServicePolicyListData} />
          </div>

          <Spacing md="30" lg="30" />
          <h2>{t(`counsellingServicePolicy.title`)}</h2>
          <div className="cs_blog_un_ord_list">
            <BlogList data={counsellingServicePolicyListData} />
          </div>

          <Spacing md="30" lg="30" />
          <h2>{t(`educationalServicePolicy.title`)}</h2>
          <div className="cs_blog_un_ord_list">
            <BlogList data={educationalServicePolicyListData} />
          </div>

          <Spacing md="30" lg="30" />
          <h2>{t(`governingLawAndJurisdiction.title`)}</h2>
          <p>{t(`governingLawAndJurisdiction.description`)}</p>

          <Spacing md="30" lg="30" />
          <h2>{t(`conflictOfTerms.title`)}</h2>
          <p>{t(`conflictOfTerms.description`)}</p>

          <Spacing md="30" lg="30" />
          <h2>{t(`ourAddress.title`)}</h2>
          <p>{t(`ourAddress.description.para1`)}</p>
          <p>
            <b>{t(`ourAddress.description.para2`)}</b>
          </p>
          <p>{t(`ourAddress.description.para3`)}</p>
          <div className="cs_blog_un_ord_list">
            <BlogList data={ourAddressListData} />
          </div>
        </div>
      </div>
    </Section>
  );
};

export default TermsAndConditions;
