export const prescriptionTemplate = (input) => {
    return `<!DOCTYPE html>
  <html lang="en">
  <head>
      <meta charset="UTF-8">
      <meta name="viewport" content="width=device-width, initial-scale=1.0">
      <title>Prescription Delivery request</title>
  </head>
  <body>
      <h2>Prescription Delivery request</h2>
      <p>Prescription by patient is attached.</p>
  </body>
  </html>`;
  };
  