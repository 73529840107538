import i18n from "../../../i18n/i18n";

export const healthRecordsContentDatas = () => {
  const whyManageYourHealthRecordsData2 = [
    {
      title: i18n.t(
        "whyManageYourHealthRecords.lists.list2.subLists.list1.title",
        {
          ns: "healthRecords",
        }
      ),
      subTitle: i18n.t(
        "whyManageYourHealthRecords.lists.list2.subLists.list1.description",
        {
          ns: "healthRecords",
        }
      ),
    },
    {
      title: i18n.t(
        "whyManageYourHealthRecords.lists.list2.subLists.list2.title",
        {
          ns: "healthRecords",
        }
      ),
      subTitle: i18n.t(
        "whyManageYourHealthRecords.lists.list2.subLists.list2.description",
        {
          ns: "healthRecords",
        }
      ),
    },
  ];

  const whyManageYourHealthRecordsData3 = [
    {
      title: i18n.t(
        "whyManageYourHealthRecords.lists.list3.subLists.list1.title",
        {
          ns: "healthRecords",
        }
      ),
      subTitle: i18n.t(
        "whyManageYourHealthRecords.lists.list3.subLists.list1.description",
        {
          ns: "healthRecords",
        }
      ),
    },
    {
      title: i18n.t(
        "whyManageYourHealthRecords.lists.list3.subLists.list2.title",
        {
          ns: "healthRecords",
        }
      ),
      subTitle: i18n.t(
        "whyManageYourHealthRecords.lists.list3.subLists.list2.description",
        {
          ns: "healthRecords",
        }
      ),
    },
  ];

  const benefitsOfManagingYourHealthRecordsData = [
    {
      title: i18n.t("benefitsOfManagingYourHealthRecords.lists.list1.title", {
        ns: "healthRecords",
      }),
      subTitle: i18n.t(
        "benefitsOfManagingYourHealthRecords.lists.list1.description",
        {
          ns: "healthRecords",
        }
      ),
      iconUrl: "/images/our_services/health-records/Icon 1-01.svg",
    },
    {
      title: i18n.t("benefitsOfManagingYourHealthRecords.lists.list2.title", {
        ns: "healthRecords",
      }),
      subTitle: i18n.t(
        "benefitsOfManagingYourHealthRecords.lists.list2.description",
        {
          ns: "healthRecords",
        }
      ),
      iconUrl: "/images/our_services/health-records/Icon 1-02.svg",
    },
    {
      title: i18n.t("benefitsOfManagingYourHealthRecords.lists.list3.title", {
        ns: "healthRecords",
      }),
      subTitle: i18n.t(
        "benefitsOfManagingYourHealthRecords.lists.list3.description",
        {
          ns: "healthRecords",
        }
      ),
      iconUrl: "/images/our_services/health-records/Icon 1-03.svg",
    },
  ];

  return {
    whyManageYourHealthRecordsData2,
    whyManageYourHealthRecordsData3,
    benefitsOfManagingYourHealthRecordsData,
  };
};
