import React from "react";
import FeatureCarousel from "../../Slider/FeatureCarousel";

export default function FeaturesSectionStyle4({
  sectionTitle,
  sectionTitleDown = "",
  sectionSubTitle = "",
  data,
  leftArrowIcon = "/images/icons/left_arrow_blue.svg",
  rightArrowIcon = "/images/icons/right_arrow_blue.svg",
}) {
  return (
    <FeatureCarousel
      sectionTitle={sectionTitle}
      sectionTitleDown={sectionTitleDown}
      sectionSubTitle={sectionSubTitle}
      data={data}
      leftArrowIcon={leftArrowIcon}
      rightArrowIcon={rightArrowIcon}
    />
  );
}
