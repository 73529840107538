import React from "react";
import { pageTitle } from "../../../../helpers/PageTitle";
import BlogBreadCrumbSection from "../../../Blogs/BlogBreadCrumbSection";
import BlogDetailInfoSection from "../../../Blogs/BlogDetailInfoSection";
import Spacing from "../../../../components/Spacing";
import BlogSidebar from "../../../Blogs/BlogSidebar";
import { cathetersBlogDatas } from "../../../../constants/common-contents";
import FirstBlog from "./FirstBlog";
import BlogImageSection from "../../../Blogs/BlogImageSection";
import SecondBlog from "./SecondBlog";
import { useParams } from "react-router-dom";
import ThirdBlog from "./ThirdBlog";

const BlogDetails = () => {
  pageTitle("Catheters Blogs");

  let { blogId } = useParams();

  if (
    blogId === "104" ||
    blogId === "107" ||
    blogId === "110" ||
    blogId === "113" ||
    blogId === "116"
  )
    blogId = "101";

  if (
    blogId === "105" ||
    blogId === "108" ||
    blogId === "111" ||
    blogId === "114" ||
    blogId === "117"
  )
    blogId = "102";

  if (
    blogId === "106" ||
    blogId === "109" ||
    blogId === "112" ||
    blogId === "115" ||
    blogId === "118"
  )
    blogId = "103";

  return (
    <>
      <BlogBreadCrumbSection
        jsonFileName="cathetersBlogs"
        unUsedValues="catheters"
      />
      <div className="container">
        <BlogDetailInfoSection jsonFileName="cathetersBlogs" />
        <Spacing md="55" />
        <BlogImageSection jsonFileName="cathetersBlogs" />
        <Spacing md="90" lg="50" />
        <div className="row">
          <div className="col-lg-8">
            <div className="cs_blog_details">
              {blogId === "101" && <FirstBlog />}
              {blogId === "102" && <SecondBlog />}
              {blogId === "103" && <ThirdBlog />}
            </div>
          </div>
          <div className="col-lg-4">
            <BlogSidebar blogDatas={cathetersBlogDatas} />
          </div>
        </div>
        <Spacing md="30" lg="30" />
      </div>
    </>
  );
};

export default BlogDetails;
