import React from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

const BlogImageSection = ({ jsonFileName }) => {
  const { t } = useTranslation([jsonFileName]);
  let { blogId } = useParams();

  if (
    blogId === "104" ||
    blogId === "107" ||
    blogId === "110" ||
    blogId === "113" ||
    blogId === "116"
  )
    blogId = "101";

  if (
    blogId === "105" ||
    blogId === "108" ||
    blogId === "111" ||
    blogId === "114" ||
    blogId === "117"
  )
    blogId = "102";

  if (
    blogId === "106" ||
    blogId === "109" ||
    blogId === "112" ||
    blogId === "115" ||
    blogId === "118"
  )
    blogId = "103";

  return (
    <img
      src={t(`blogs.${blogId}.image`)}
      alt="Blog Details"
      className="w-100 cs_radius_20"
    />
  );
};

export default BlogImageSection;
