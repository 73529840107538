import React from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

const ThirdBlog = () => {
  const { t } = useTranslation(["kidneyBiopsyBlogs"]);
  let { blogId } = useParams();

  if (
    blogId === "104" ||
    blogId === "107" ||
    blogId === "110" ||
    blogId === "113" ||
    blogId === "116"
  )
    blogId = "101";

  if (
    blogId === "105" ||
    blogId === "108" ||
    blogId === "111" ||
    blogId === "114" ||
    blogId === "117"
  )
    blogId = "102";

  if (
    blogId === "106" ||
    blogId === "109" ||
    blogId === "112" ||
    blogId === "115" ||
    blogId === "118"
  )
    blogId = "103";

  return (
    <div
      className="cs_blog_details"
      dangerouslySetInnerHTML={{ __html: t(`blogs.${blogId}.innerHTML`) }}
    ></div>
  );
};

export default ThirdBlog;
