import React from "react";

const CustomTable = ({ tableHead = [], tableBody = [], tableFooter = [] }) => {
  if (
    tableHead &&
    tableHead.length === 0 &&
    tableBody &&
    tableBody.length === 0
  )
    return null;

  const columnKeys = Object.keys(tableBody[0]);

  return (
    <div className="table-responsive cs_custom_table">
      <table className="table table-hover ">
        <thead className="thead-light">
          <tr>
            {tableHead.map((head) => (
              <th scope="col">{head.heading}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {tableBody.map((row) => (
            <tr>
              {columnKeys.map((key) => (
                <td>{row[key]}</td>
              ))}
            </tr>
          ))}
          <tr></tr>
        </tbody>
        <tfoot>
          {tableFooter.map((row) => (
            <tr>
              {columnKeys.map((key) => (
                <th>{row[key]}</th>
              ))}
            </tr>
          ))}
        </tfoot>
      </table>
    </div>
  );
};

export default CustomTable;
