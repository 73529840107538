import React from "react";

export default function AboutSectionStyle6({
  imgUrl,
  titleUp = "",
  title,
  subTitle,
  quoteBgColor = "",
}) {
  return (
    <div className="cs_about cs_style_1 cs_type_1 cs_shape_wrap cs_about_style6">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-6">
            <div className="cs_about_img">
              <img loading="lazy" src={imgUrl} alt="About" />
            </div>
          </div>
          <div className="col-lg-6">
            <div className="cs_blog_details">
              <blockquote>
                <p style={{ backgroundColor: `${quoteBgColor}` }}>{subTitle}</p>
              </blockquote>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
