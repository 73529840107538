import React from "react";
import { useTranslation } from "react-i18next";
import Spacing from "../../../../components/Spacing";
import { firstBlogContentDatas } from "./firstBlogContents";
import BlogList from "../../../Blogs/BlogComponents/BlogList";

const FirstBlog = () => {
  const { t } = useTranslation(["kidneyBiopsyBlogs"]);
  const {
    preBiopsyTestsListData,
    analyzingBiopsySampleListData,
    watchForSignsAndSymptomsListData,
  } = firstBlogContentDatas();

  return (
    <>
      <p>{t(`blogs.101.innerHTML.blogDescription`)}</p>

      <Spacing md="20" lg="20" />
      <h2>{t(`blogs.101.innerHTML.whenABiopsyBecomesNecessary.title`)}</h2>
      <p>{t(`blogs.101.innerHTML.whenABiopsyBecomesNecessary.description`)}</p>

      <Spacing md="20" lg="20" />
      <h2>{t(`blogs.101.innerHTML.preBiopsyTests.title`)}</h2>
      <p>{t(`blogs.101.innerHTML.preBiopsyTests.description`)}</p>
      <div className="cs_blog_un_ord_list">
        <BlogList data={preBiopsyTestsListData} />
      </div>

      <Spacing md="20" lg="20" />
      <p>{t(`blogs.101.innerHTML.notes`)}</p>

      <Spacing md="20" lg="20" />
      <h2>{t(`blogs.101.innerHTML.anesthesia.title`)}</h2>
      <p>{t(`blogs.101.innerHTML.anesthesia.description`)}</p>

      <Spacing md="20" lg="20" />
      <h2>{t(`blogs.101.innerHTML.dontPanic.title`)}</h2>
      <p>{t(`blogs.101.innerHTML.dontPanic.description`)}</p>

      <Spacing md="20" lg="20" />
      <h2>{t(`blogs.101.innerHTML.keepingThePressureUnderControl.title`)}</h2>
      <p>
        {t(`blogs.101.innerHTML.keepingThePressureUnderControl.description`)}
      </p>

      <Spacing md="20" lg="20" />
      <h2>{t(`blogs.101.innerHTML.postBiopsyMonitoring.title`)}</h2>
      <p>{t(`blogs.101.innerHTML.postBiopsyMonitoring.description`)}</p>

      <Spacing md="20" lg="20" />
      <h2>{t(`blogs.101.innerHTML.analyzingBiopsySample.title`)}</h2>
      <p>{t(`blogs.101.innerHTML.analyzingBiopsySample.description`)}</p>
      <div className="cs_blog_un_ord_list">
        <BlogList data={analyzingBiopsySampleListData} />
      </div>

      <Spacing md="20" lg="20" />
      <p>{t(`blogs.101.innerHTML.note2`)}</p>

      <Spacing md="20" lg="20" />
      <h2>{t(`blogs.101.innerHTML.watchForSignsAndSymptoms.title`)}</h2>
      <p>{t(`blogs.101.innerHTML.watchForSignsAndSymptoms.description`)}</p>
      <div className="cs_blog_un_ord_list">
        <BlogList data={watchForSignsAndSymptomsListData} />
      </div>

      <Spacing md="20" lg="20" />
      <h2>{t(`blogs.101.innerHTML.everythingAboutUltrasound.title`)}</h2>
      <p>{t(`blogs.101.innerHTML.everythingAboutUltrasound.description`)}</p>

      <Spacing md="20" lg="20" />
      <h2>{t(`blogs.101.innerHTML.rarelyAboutJugularKidneyBiopsies.title`)}</h2>
      <p>
        {t(`blogs.101.innerHTML.rarelyAboutJugularKidneyBiopsies.description`)}
      </p>

      <Spacing md="20" lg="20" />
      <h2>{t(`blogs.101.innerHTML.daycareProcedure.title`)}</h2>
      <p>{t(`blogs.101.innerHTML.daycareProcedure.description`)}</p>

      <Spacing md="20" lg="20" />
      <h2>{t(`blogs.101.innerHTML.sometimesBiopsyNecessity.title`)}</h2>
      <p>{t(`blogs.101.innerHTML.sometimesBiopsyNecessity.description`)}</p>

      <Spacing md="20" lg="20" />
      <h2>{t(`blogs.101.innerHTML.sharingYourStory.title`)}</h2>
      <p>{t(`blogs.101.innerHTML.sharingYourStory.description.para1`)}</p>
      <p>{t(`blogs.101.innerHTML.sharingYourStory.description.para2`)}</p>

      <Spacing md="20" lg="20" />
      <h2>{t(`blogs.101.innerHTML.disclaimer.title`)}</h2>
      <p>{t(`blogs.101.innerHTML.disclaimer.description`)}</p>
    </>
  );
};

export default FirstBlog;
