import i18n from "../../i18n/i18n";

export const firstBlogContentDatas = () => {
  const whyJEEVANIsNeededListData = [
    {
      title: i18n.t("blogs.101.innerHTML.whyJEEVANIsNeeded.list1.title", {
        ns: "homeBlog",
      }),
      description: i18n.t(
        "blogs.101.innerHTML.whyJEEVANIsNeeded.list1.description",
        {
          ns: "homeBlog",
        }
      ),
    },
    {
      title: i18n.t("blogs.101.innerHTML.whyJEEVANIsNeeded.list2.title", {
        ns: "homeBlog",
      }),
      description: i18n.t(
        "blogs.101.innerHTML.whyJEEVANIsNeeded.list2.description",
        {
          ns: "homeBlog",
        }
      ),
    },
    {
      title: i18n.t("blogs.101.innerHTML.whyJEEVANIsNeeded.list3.title", {
        ns: "homeBlog",
      }),
      description: i18n.t(
        "blogs.101.innerHTML.whyJEEVANIsNeeded.list3.description",
        {
          ns: "homeBlog",
        }
      ),
    },
  ];

  const howWeMakeItPossibleListData = [
    {
      title: i18n.t("blogs.101.innerHTML.howWeMakeItPossible.list1.title", {
        ns: "homeBlog",
      }),
      description: i18n.t(
        "blogs.101.innerHTML.howWeMakeItPossible.list1.description",
        {
          ns: "homeBlog",
        }
      ),
    },
    {
      title: i18n.t("blogs.101.innerHTML.howWeMakeItPossible.list2.title", {
        ns: "homeBlog",
      }),
      description: i18n.t(
        "blogs.101.innerHTML.howWeMakeItPossible.list2.description",
        {
          ns: "homeBlog",
        }
      ),
    },
    {
      title: i18n.t("blogs.101.innerHTML.howWeMakeItPossible.list3.title", {
        ns: "homeBlog",
      }),
      description: i18n.t(
        "blogs.101.innerHTML.howWeMakeItPossible.list3.description",
        {
          ns: "homeBlog",
        }
      ),
    },
  ];

  const illustrativePrescriptionCostComparisonListData = [
    {
      title: i18n.t(
        "blogs.101.innerHTML.illustrativePrescriptionCostComparison.list1.title",
        {
          ns: "homeBlog",
        }
      ),
      enableTable: true,
      tableHeadData: [
        {
          heading: i18n.t(
            "blogs.101.innerHTML.illustrativePrescriptionCostComparison.list1.tableData.tableHead.tHead1",
            {
              ns: "homeBlog",
            }
          ),
        },
        {
          heading: i18n.t(
            "blogs.101.innerHTML.illustrativePrescriptionCostComparison.list1.tableData.tableHead.tHead2",
            {
              ns: "homeBlog",
            }
          ),
        },
        {
          heading: i18n.t(
            "blogs.101.innerHTML.illustrativePrescriptionCostComparison.list1.tableData.tableHead.tHead3",
            {
              ns: "homeBlog",
            }
          ),
        },
        {
          heading: i18n.t(
            "blogs.101.innerHTML.illustrativePrescriptionCostComparison.list1.tableData.tableHead.tHead4",
            {
              ns: "homeBlog",
            }
          ),
        },
      ],
      tableBodyData: [
        {
          col1: i18n.t(
            "blogs.101.innerHTML.illustrativePrescriptionCostComparison.list1.tableData.tableBody.row1.col1",
            {
              ns: "homeBlog",
            }
          ),
          col2: i18n.t(
            "blogs.101.innerHTML.illustrativePrescriptionCostComparison.list1.tableData.tableBody.row1.col2",
            {
              ns: "homeBlog",
            }
          ),
          col3: i18n.t(
            "blogs.101.innerHTML.illustrativePrescriptionCostComparison.list1.tableData.tableBody.row1.col3",
            {
              ns: "homeBlog",
            }
          ),
          col4: i18n.t(
            "blogs.101.innerHTML.illustrativePrescriptionCostComparison.list1.tableData.tableBody.row1.col4",
            {
              ns: "homeBlog",
            }
          ),
        },
        {
          col1: i18n.t(
            "blogs.101.innerHTML.illustrativePrescriptionCostComparison.list1.tableData.tableBody.row2.col1",
            {
              ns: "homeBlog",
            }
          ),
          col2: i18n.t(
            "blogs.101.innerHTML.illustrativePrescriptionCostComparison.list1.tableData.tableBody.row2.col2",
            {
              ns: "homeBlog",
            }
          ),
          col3: i18n.t(
            "blogs.101.innerHTML.illustrativePrescriptionCostComparison.list1.tableData.tableBody.row2.col3",
            {
              ns: "homeBlog",
            }
          ),
          col4: i18n.t(
            "blogs.101.innerHTML.illustrativePrescriptionCostComparison.list1.tableData.tableBody.row2.col4",
            {
              ns: "homeBlog",
            }
          ),
        },
        {
          col1: i18n.t(
            "blogs.101.innerHTML.illustrativePrescriptionCostComparison.list1.tableData.tableBody.row3.col1",
            {
              ns: "homeBlog",
            }
          ),
          col2: i18n.t(
            "blogs.101.innerHTML.illustrativePrescriptionCostComparison.list1.tableData.tableBody.row3.col2",
            {
              ns: "homeBlog",
            }
          ),
          col3: i18n.t(
            "blogs.101.innerHTML.illustrativePrescriptionCostComparison.list1.tableData.tableBody.row3.col3",
            {
              ns: "homeBlog",
            }
          ),
          col4: i18n.t(
            "blogs.101.innerHTML.illustrativePrescriptionCostComparison.list1.tableData.tableBody.row3.col4",
            {
              ns: "homeBlog",
            }
          ),
        },
        {
          col1: i18n.t(
            "blogs.101.innerHTML.illustrativePrescriptionCostComparison.list1.tableData.tableBody.row4.col1",
            {
              ns: "homeBlog",
            }
          ),
          col2: i18n.t(
            "blogs.101.innerHTML.illustrativePrescriptionCostComparison.list1.tableData.tableBody.row4.col2",
            {
              ns: "homeBlog",
            }
          ),
          col3: i18n.t(
            "blogs.101.innerHTML.illustrativePrescriptionCostComparison.list1.tableData.tableBody.row4.col3",
            {
              ns: "homeBlog",
            }
          ),
          col4: i18n.t(
            "blogs.101.innerHTML.illustrativePrescriptionCostComparison.list1.tableData.tableBody.row4.col4",
            {
              ns: "homeBlog",
            }
          ),
        },
        {
          col1: i18n.t(
            "blogs.101.innerHTML.illustrativePrescriptionCostComparison.list1.tableData.tableBody.row5.col1",
            {
              ns: "homeBlog",
            }
          ),
          col2: i18n.t(
            "blogs.101.innerHTML.illustrativePrescriptionCostComparison.list1.tableData.tableBody.row5.col2",
            {
              ns: "homeBlog",
            }
          ),
          col3: i18n.t(
            "blogs.101.innerHTML.illustrativePrescriptionCostComparison.list1.tableData.tableBody.row5.col3",
            {
              ns: "homeBlog",
            }
          ),
          col4: i18n.t(
            "blogs.101.innerHTML.illustrativePrescriptionCostComparison.list1.tableData.tableBody.row5.col4",
            {
              ns: "homeBlog",
            }
          ),
        },
        {
          col1: i18n.t(
            "blogs.101.innerHTML.illustrativePrescriptionCostComparison.list1.tableData.tableBody.row6.col1",
            {
              ns: "homeBlog",
            }
          ),
          col2: i18n.t(
            "blogs.101.innerHTML.illustrativePrescriptionCostComparison.list1.tableData.tableBody.row6.col2",
            {
              ns: "homeBlog",
            }
          ),
          col3: i18n.t(
            "blogs.101.innerHTML.illustrativePrescriptionCostComparison.list1.tableData.tableBody.row6.col3",
            {
              ns: "homeBlog",
            }
          ),
          col4: i18n.t(
            "blogs.101.innerHTML.illustrativePrescriptionCostComparison.list1.tableData.tableBody.row6.col4",
            {
              ns: "homeBlog",
            }
          ),
        },
        {
          col1: i18n.t(
            "blogs.101.innerHTML.illustrativePrescriptionCostComparison.list1.tableData.tableBody.row7.col1",
            {
              ns: "homeBlog",
            }
          ),
          col2: i18n.t(
            "blogs.101.innerHTML.illustrativePrescriptionCostComparison.list1.tableData.tableBody.row7.col2",
            {
              ns: "homeBlog",
            }
          ),
          col3: i18n.t(
            "blogs.101.innerHTML.illustrativePrescriptionCostComparison.list1.tableData.tableBody.row7.col3",
            {
              ns: "homeBlog",
            }
          ),
          col4: i18n.t(
            "blogs.101.innerHTML.illustrativePrescriptionCostComparison.list1.tableData.tableBody.row7.col4",
            {
              ns: "homeBlog",
            }
          ),
        },
        {
          col1: i18n.t(
            "blogs.101.innerHTML.illustrativePrescriptionCostComparison.list1.tableData.tableBody.row8.col1",
            {
              ns: "homeBlog",
            }
          ),
          col2: i18n.t(
            "blogs.101.innerHTML.illustrativePrescriptionCostComparison.list1.tableData.tableBody.row8.col2",
            {
              ns: "homeBlog",
            }
          ),
          col3: i18n.t(
            "blogs.101.innerHTML.illustrativePrescriptionCostComparison.list1.tableData.tableBody.row8.col3",
            {
              ns: "homeBlog",
            }
          ),
          col4: i18n.t(
            "blogs.101.innerHTML.illustrativePrescriptionCostComparison.list1.tableData.tableBody.row8.col4",
            {
              ns: "homeBlog",
            }
          ),
        },
        {
          col1: i18n.t(
            "blogs.101.innerHTML.illustrativePrescriptionCostComparison.list1.tableData.tableBody.row9.col1",
            {
              ns: "homeBlog",
            }
          ),
          col2: i18n.t(
            "blogs.101.innerHTML.illustrativePrescriptionCostComparison.list1.tableData.tableBody.row9.col2",
            {
              ns: "homeBlog",
            }
          ),
          col3: i18n.t(
            "blogs.101.innerHTML.illustrativePrescriptionCostComparison.list1.tableData.tableBody.row9.col3",
            {
              ns: "homeBlog",
            }
          ),
          col4: i18n.t(
            "blogs.101.innerHTML.illustrativePrescriptionCostComparison.list1.tableData.tableBody.row9.col4",
            {
              ns: "homeBlog",
            }
          ),
        },
      ],
      tableFooterData: [
        {
          col1: i18n.t(
            "blogs.101.innerHTML.illustrativePrescriptionCostComparison.list1.tableData.tableFooter.row1.col1",
            {
              ns: "homeBlog",
            }
          ),
          col2: i18n.t(
            "blogs.101.innerHTML.illustrativePrescriptionCostComparison.list1.tableData.tableFooter.row1.col2",
            {
              ns: "homeBlog",
            }
          ),
          col3: i18n.t(
            "blogs.101.innerHTML.illustrativePrescriptionCostComparison.list1.tableData.tableFooter.row1.col3",
            {
              ns: "homeBlog",
            }
          ),
          col4: i18n.t(
            "blogs.101.innerHTML.illustrativePrescriptionCostComparison.list1.tableData.tableFooter.row1.col4",
            {
              ns: "homeBlog",
            }
          ),
        },
      ],
    },
    {
      title: i18n.t(
        "blogs.101.innerHTML.illustrativePrescriptionCostComparison.list2.title",
        {
          ns: "homeBlog",
        }
      ),
      enableTable: true,
      tableHeadData: [
        {
          heading: i18n.t(
            "blogs.101.innerHTML.illustrativePrescriptionCostComparison.list2.tableData.tableHead.tHead1",
            {
              ns: "homeBlog",
            }
          ),
        },
        {
          heading: i18n.t(
            "blogs.101.innerHTML.illustrativePrescriptionCostComparison.list2.tableData.tableHead.tHead2",
            {
              ns: "homeBlog",
            }
          ),
        },
        {
          heading: i18n.t(
            "blogs.101.innerHTML.illustrativePrescriptionCostComparison.list2.tableData.tableHead.tHead3",
            {
              ns: "homeBlog",
            }
          ),
        },
        {
          heading: i18n.t(
            "blogs.101.innerHTML.illustrativePrescriptionCostComparison.list2.tableData.tableHead.tHead4",
            {
              ns: "homeBlog",
            }
          ),
        },
      ],
      tableBodyData: [
        {
          col1: i18n.t(
            "blogs.101.innerHTML.illustrativePrescriptionCostComparison.list2.tableData.tableBody.row1.col1",
            {
              ns: "homeBlog",
            }
          ),
          col2: i18n.t(
            "blogs.101.innerHTML.illustrativePrescriptionCostComparison.list2.tableData.tableBody.row1.col2",
            {
              ns: "homeBlog",
            }
          ),
          col3: i18n.t(
            "blogs.101.innerHTML.illustrativePrescriptionCostComparison.list2.tableData.tableBody.row1.col3",
            {
              ns: "homeBlog",
            }
          ),
          col4: i18n.t(
            "blogs.101.innerHTML.illustrativePrescriptionCostComparison.list2.tableData.tableBody.row1.col4",
            {
              ns: "homeBlog",
            }
          ),
        },
      ],
    },
    {
      title: i18n.t(
        "blogs.101.innerHTML.illustrativePrescriptionCostComparison.list3.title",
        {
          ns: "homeBlog",
        }
      ),
      childList: [
        {
          description: i18n.t(
            "blogs.101.innerHTML.illustrativePrescriptionCostComparison.list3.subLists.list1.description",
            {
              ns: "homeBlog",
            }
          ),
        },
        {
          description: i18n.t(
            "blogs.101.innerHTML.illustrativePrescriptionCostComparison.list3.subLists.list2.description",
            {
              ns: "homeBlog",
            }
          ),
        },
      ],
    },
  ];

  const estimatedMonthlyCostWithJEEVANListData = [
    {
      title: i18n.t(
        "blogs.101.innerHTML.estimatedMonthlyCostWithJEEVAN.list1.title",
        {
          ns: "homeBlog",
        }
      ),
      description: i18n.t(
        "blogs.101.innerHTML.estimatedMonthlyCostWithJEEVAN.list1.description",
        {
          ns: "homeBlog",
        }
      ),
    },
    {
      title: i18n.t(
        "blogs.101.innerHTML.estimatedMonthlyCostWithJEEVAN.list2.title",
        {
          ns: "homeBlog",
        }
      ),
      description: i18n.t(
        "blogs.101.innerHTML.estimatedMonthlyCostWithJEEVAN.list2.description",
        {
          ns: "homeBlog",
        }
      ),
    },
    {
      title: i18n.t(
        "blogs.101.innerHTML.estimatedMonthlyCostWithJEEVAN.list3.title",
        {
          ns: "homeBlog",
        }
      ),
      description: i18n.t(
        "blogs.101.innerHTML.estimatedMonthlyCostWithJEEVAN.list3.description",
        {
          ns: "homeBlog",
        }
      ),
    },
  ];

  return {
    whyJEEVANIsNeededListData,
    howWeMakeItPossibleListData,
    illustrativePrescriptionCostComparisonListData,
    estimatedMonthlyCostWithJEEVANListData,
  };
};
