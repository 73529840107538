import React, { useEffect } from "react";
import { useState } from "react";
import { useLocation } from "react-router-dom";

const ScrollIndicator = () => {
  const [scroll, setScroll] = useState(0);

  const { pathname } = useLocation();

  useEffect(() => {
    setScroll(0);
  }, [pathname]);

  const onScroll = () => {
    const Scrolled = document.documentElement.scrollTop;
    const MaxHeight =
      document.documentElement.scrollHeight -
      document.documentElement.clientHeight;
    const ScrollPercent = (Scrolled / MaxHeight) * 100;
    setScroll(ScrollPercent);
  };

  window.addEventListener("scroll", onScroll);

  //   if (scroll === 0) return null;

  return (
    <div className="cs_scroll_indicator">
      <div className="cs_scroll_indicator_container">
        <div
          className="cs_scroll_indicator_progressbar"
          style={{ width: `${scroll}%` }}
        ></div>
      </div>
    </div>
  );
};

export default ScrollIndicator;
