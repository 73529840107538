import React from "react";
import AppointmentForm from "../../AppointmentForm";
import { Icon } from "@iconify/react";
import UploadPrescriptionForm from "../../UploadForm";
import Spacing from "../../Spacing";

const avatarSheduleList = [
  {
    icon: "fa6-solid:calendar-days",
    title: " ",
    details: "Within 1 Day",
  },
  {
    icon: "fa6-regular:clock",
    title: " ",
    details: "Deliever within 2 Days",
  },
];

export default function AppointmentWithContactInfoSection({
  contactInfo = [],
  customClass = "",
}) {
  return (
    <div className={`container ${customClass}`}>
      <div className="row">
        <div className="col-lg-7">
          <h2 className="cs_fs_40 cs_medium mb-0">
            Book An Appointment For Counselling
          </h2>
          <div className="cs_height_42 cs_height_xl_25" />
          <AppointmentForm />
        </div>
        <div className="col-lg-4 offset-lg-1">
          <div className="cs_height_lg_100" />
          <h2 className="cs_fs_40 cs_medium mb-0">Upload Your Prescription</h2>
          <div className="cs_height_60 cs_height_xl_40" />
          <img
            src="/images/home/upload.png"
            alt="Appointment"
            className="cs_radius_25 w-100"
          />
          {contactInfo && contactInfo.length > 0 && (
            <>
              <div className="cs_height_100 cs_height_xl_60" />
              <ul className="cs_contact_info cs_style_1 cs_mp0">
                {contactInfo.map((item, index) => (
                  <li key={index}>
                    <h3 className="cs_fs_24 cs_semibold mb-0">{item.title}</h3>
                    <p className="mb-0 cs_heading_color">{item.subTitle}</p>
                  </li>
                ))}
              </ul>
            </>
          )}
          <div className="cs_upload_prescription_content">
            <div className="cs_hero_card cs_radius_20 cs_white_color">
              <div className="cs_hero_card_media">
                <img loading="lazy" src="/images/home/upload-logo.jpg" alt="Avatar" />
                <div>
                  <h4 className="cs_fs_24 cs_semibold cs_white_color m-0">
                    Get The Medicine Approved By Doctor
                  </h4>
                </div>
              </div>
              <ul className="cs_hero_shedule_list">
                {avatarSheduleList.map((item, index) => (
                  <li key={index}>
                    <i>
                      <Icon icon={item.icon} />
                    </i>
                    <span>{item.title}</span>
                    <strong>{item.details}</strong>
                  </li>
                ))}
              </ul>
              <Spacing xl="15" lg="10" md="10" />
              <UploadPrescriptionForm />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
