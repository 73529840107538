import React from "react";
import { useTranslation } from "react-i18next";

import BlogList from "../../../Blogs/BlogComponents/BlogList";
import Spacing from "../../../../components/Spacing";
import BlogOrderedList from "../../../Blogs/BlogComponents/BlogOrderedList";
import { secondBlogContentDatas } from "./secondBlogContents";

const SecondBlog = () => {
  const { t } = useTranslation(["cathetersBlogs"]);

  const {
    whyCathetersAreNecessaryListData,
    typesOfDialysisCathetersListData,
    placementSitesListData,
    additionalFactorsListData,
    makingInformedDecisionListData,
    kidneyNeedsYourPartnerListData,
    addressingMisconceptionsListData,
  } = secondBlogContentDatas();

  return (
    <>
      <h2>{t(`blogs.102.innerHTML.understandingDialysis.title`)}</h2>
      <p>{t(`blogs.102.innerHTML.understandingDialysis.description.para1`)}</p>
      <p>{t(`blogs.102.innerHTML.understandingDialysis.description.para2`)}</p>
      <h2>{t(`blogs.102.innerHTML.whyCathetersAreNecessary.title`)}</h2>
      <p>{t(`blogs.102.innerHTML.whyCathetersAreNecessary.description`)}</p>
      <div className="cs_blog_un_ord_list">
        <BlogList data={whyCathetersAreNecessaryListData} />
      </div>
      <Spacing md="30" lg="30" />
      <h2>{t(`blogs.102.innerHTML.typesOfDialysisCatheters.title`)}</h2>
      <Spacing md="20" lg="20" />
      <div className="cs_blog_ord_list">
        <BlogOrderedList data={typesOfDialysisCathetersListData} />
      </div>
      <h2>{t(`blogs.102.innerHTML.placementSites.title`)}</h2>
      <Spacing md="5" lg="5" />
      <div className="cs_blog_un_ord_list">
        <BlogList data={placementSitesListData} />
      </div>
      <Spacing md="40" lg="40" />
      <h2>{t(`blogs.102.innerHTML.additionalFactors.title`)}</h2>
      <Spacing md="5" lg="5" />
      <div className="cs_blog_un_ord_list">
        <BlogList data={additionalFactorsListData} />
      </div>
      <Spacing md="40" lg="40" />
      <h2>{t(`blogs.102.innerHTML.makingInformedDecision.title`)}</h2>
      <p>{t(`blogs.102.innerHTML.makingInformedDecision.description`)}</p>
      <div className="cs_blog_ord_list cs_blog_ord_list_only">
        <BlogOrderedList data={makingInformedDecisionListData} />
      </div>
      <Spacing md="40" lg="40" />
      <h2>{t(`blogs.102.innerHTML.kidneyNeedsYourPartner.title`)}</h2>
      <p>{t(`blogs.102.innerHTML.kidneyNeedsYourPartner.description`)}</p>
      <div className="cs_blog_un_ord_list">
        <BlogList data={kidneyNeedsYourPartnerListData} />
      </div>
      <Spacing md="40" lg="40" />
      <h2>{t(`blogs.102.innerHTML.addressingMisconceptions.title`)}</h2>
      <Spacing md="5" lg="5" />
      <div className="cs_blog_un_ord_list">
        <BlogList data={addressingMisconceptionsListData} />
      </div>
      <Spacing md="30" lg="30" />
      <h2>{t(`blogs.102.innerHTML.conclusion.title`)}:</h2>
      <p>
        <span>{t(`blogs.102.innerHTML.conclusion.description`)}</span>
      </p>
    </>
  );
};

export default SecondBlog;
