import i18n from "../../../i18n/i18n";

export const avFistulaContentDatas = () => {
  const whyCreateAVFistulaData = [
    {
      title: i18n.t("whyCreateAVFistulaLists.list1.title", {
        ns: "avFistula",
      }),
      subTitle: i18n.t("whyCreateAVFistulaLists.list1.description", {
        ns: "avFistula",
      }),
      iconUrl: "/images/kidney_educations/av-fitsula/Icon 1-01.svg",
    },
    {
      title: i18n.t("whyCreateAVFistulaLists.list2.title", {
        ns: "avFistula",
      }),
      subTitle: i18n.t("whyCreateAVFistulaLists.list2.description", {
        ns: "avFistula",
      }),
      iconUrl: "/images/kidney_educations/av-fitsula/Icon 1-02.svg",
    },
    {
      title: i18n.t("whyCreateAVFistulaLists.list3.title", {
        ns: "avFistula",
      }),
      subTitle: i18n.t("whyCreateAVFistulaLists.list3.description", {
        ns: "avFistula",
      }),
      iconUrl: "/images/kidney_educations/av-fitsula/Icon 1-03.svg",
    },
    {
      title: i18n.t("whyCreateAVFistulaLists.list4.title", {
        ns: "avFistula",
      }),
      subTitle: i18n.t("whyCreateAVFistulaLists.list4.description", {
        ns: "avFistula",
      }),
      iconUrl: "/images/kidney_educations/av-fitsula/Icon 1-04.svg",
    },
  ];

  const howIsAVFistulaCreatedData = [
    {
      title: i18n.t("howIsAVFistulaCreatedLists.list1.title", {
        ns: "avFistula",
      }),
      subTitle: i18n.t("howIsAVFistulaCreatedLists.list1.description", {
        ns: "avFistula",
      }),
      iconUrl: "/images/kidney_educations/av-fitsula/Icon 2-01.svg",
      number: "01",
    },
    {
      title: i18n.t("howIsAVFistulaCreatedLists.list2.title", {
        ns: "avFistula",
      }),
      subTitle: i18n.t("howIsAVFistulaCreatedLists.list2.description", {
        ns: "avFistula",
      }),
      iconUrl: "/images/kidney_educations/av-fitsula/Icon 2-02.svg",
      number: "02",
    },
    {
      title: i18n.t("howIsAVFistulaCreatedLists.list3.title", {
        ns: "avFistula",
      }),
      subTitle: i18n.t("howIsAVFistulaCreatedLists.list3.description", {
        ns: "avFistula",
      }),
      iconUrl: "/images/kidney_educations/av-fitsula/Icon 2-03.svg",
      number: "03",
    },
  ];

  const rememberData = [
    {
      title: i18n.t("remember.description1", {
        ns: "avFistula",
      }),
    },
    {
      title: i18n.t("remember.description2", {
        ns: "avFistula",
      }),
    },
  ];

  return { whyCreateAVFistulaData, howIsAVFistulaCreatedData, rememberData };
};
