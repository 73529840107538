export const faqData = [
  {
    title: "What is KidneyNeeds and what services do you offer?",
    content:
      "KidneyNeeds is a comprehensive platform dedicated to supporting individuals with kidney disease. We offer a range of services tailored to their needs, including disease-specific counselling, medication support, assistance finding suitable healthcare providers, and personalized dietary advice.",
  },
  {
    title: "Who can benefit from KidneyNeeds services?",
    content:
      "Anyone diagnosed with kidney disease, their caregivers, or those at risk for kidney disease can benefit from our services.",
  },
  {
    title: "Why is counselling important for kidney patients?",
    content:
      "Counselling plays a crucial role in helping kidney patients understand their condition, manage emotional stress, adhere to treatment plans, and improve their quality of life.",
  },
  {
    title:
      "What role does dietary advice play in kidney health? How does KidneyNeeds help?",
    content:
      "Renal nutrition is vital in managing kidney disease and slowing its progression. KidneyNeeds offers personalized dietary advice, including menu planning and alternative food suggestions tailored to individual preferences, to support optimal kidney health.",
  },
  {
    title: "How can I manage my daily routine with kidney disease?",
    content:
      "Kidney disease can impact your daily life, but with proper management, you can maintain a fulfilling routine. KidneyNeeds offers various resources to help with fatigue management, medication schedules, and dietary restrictions.",
  },
  {
    title: "What kind of emotional support does KidneyNeeds offer?",
    content:
      "Kidney disease can bring emotional challenges. KidneyNeeds provides support groups (online and in-person where possible), online forums, and individual counseling to help you connect with others and receive personalized emotional support.",
  },
  {
    title: "How can I access KidneyNeeds services?",
    content:
      "You can access KidneyNeeds services conveniently through our website. We offer detailed information about our offerings and a contact form for personalized assistance.",
  },
  {
    title: "Is there a cost associated with using KidneyNeeds services?",
    content:
      "Our core services, including counseling, medication education, and motivational support with basic dietary guidance, are free. However, some specialized services might have a cost. We will always be transparent about fees before you proceed.",
  },
  {
    title: "How does KidneyNeeds help with medication support?",
    content:
      "KidneyNeeds collaborates with pharmacies to provide discounted medications and assists in sourcing generic alternatives, ensuring affordability.",
  },
  {
    title:
      "I'm experiencing side effects from my medications. Can KidneyNeeds help?",
    content:
      "While consulting your healthcare provider is essential, KidneyNeeds can offer guidance on understanding potential causes and communicating effectively with your doctor.",
  },
  {
    title:
      "Can KidneyNeeds help me find financial assistance for my kidney care needs?",
    content:
      "Absolutely! KidneyNeeds can assist you in exploring various financial assistance options.",
  },
  {
    title:
      "Does KidneyNeeds offer educational resources for kidney patients and their families?",
    content:
      "Yes, KidneyNeeds provides a comprehensive library of educational resources like articles, guides, and webinars to empower patients and families with knowledge about managing kidney disease and treatment options.",
  },
  {
    title:
      "How can I stay updated on the latest advancements in kidney disease treatment?",
    content:
      "KidneyNeeds keeps you informed through blog posts, newsletters, and webinars featuring the latest research, treatment advancements, and relevant health information.",
  },
  {
    title: "What happens if my kidney disease progresses?",
    content:
      "While kidney disease management focuses on slowing progression, understanding potential future scenarios is important. KidneyNeeds can help you prepare for kidney replacement therapy options and connect you with support resources.",
  },
  {
    title: "Can KidneyNeeds help me connect with other kidney patients?",
    content:
      "Absolutely! Building a support network is crucial. KidneyNeeds offers online communities and local support groups (where possible) to connect with others facing similar challenges.",
  },
];

// Home Blog Datas
export const homeBlogList = {
  blogs: {
    101: {
      author: "KidneyNeeds Team",
      date: "September 12, 2023",
      image: "/images/home/catheter-blog-1a.jpg",
      category: "Empowering",
      blogHeading:
        "KidneyNeeds' JEEVAN Kidney Initiative: Empowering Lives through Affordable, Quality Kidney Care",
    },
    102: {
      author: "KidneyNeeds Team",
      date: "March 24, 2023",
      image: "/images/our_services/catheters/catheter-blog-new-2a.jpg",
      category: "Catheters",
      blogHeading:
        "Dialysis and Catheters: Making Informed Choices for Your Health",
    },
    103: {
      author: "KidneyNeeds Team",
      date: "April 2, 2023",
      image: "/images/our_services/catheters/catheter-blog-new-3a.jpg",
      category: "Kidney Disease",
      blogHeading:
        "Our Not-for-Profit Catheter Care Infection Control Kit - Why it is a Necessity",
    },
  },
};

export const homeBlogDatas = () => {
  return Object.keys(homeBlogList.blogs).map((key) => {
    return {
      title: homeBlogList.blogs[key].blogHeading,
      thumbUrl: homeBlogList.blogs[key].image,
      image: homeBlogList.blogs[key].image,
      date: homeBlogList.blogs[key].date,
      btnText: "Learn More",
      href: `/home/blog/${key}`,
      author: homeBlogList.blogs[key].author,
      category: homeBlogList.blogs[key].category,
    };
  });
};

// Counselling Blog Datas
export const blogList = {
  blogs: {
    101: {
      author: "KidneyNeeds Team",
      date: "March 12, 2023",
      image: "/images/our_services/counselling/counselling-blog-new-1a.jpg",
      category: "Dialysis Vascular Access",
      blogHeading:
        "Don't Fear the Fistula or Catheter: Understanding Your Options for DialysisAccess",
    },
    102: {
      author: "KidneyNeeds Team",
      date: "March 24, 2023",
      image: "/images/our_services/counselling/counselling-blog-new-2a.jpg",
      category: "Dialysis Fistula",
      blogHeading:
        "Doctor Recommends a Fistula - Does it Mean Dialysis is Imminent? Addressing Concerns in India",
    },
    103: {
      author: "KidneyNeeds Team",
      date: "April 2, 2023",
      image: "/images/our_services/counselling/counselling-blog-new-3a.jpg",
      category: "Elderly Kidney Disease",
      blogHeading:
        "Kidney Disease in Seniors: A Journey of Understanding and Support",
    },
  },
};

export const blogDatas = () => {
  return Object.keys(blogList.blogs).map((key) => {
    return {
      title: blogList.blogs[key].blogHeading,
      thumbUrl: blogList.blogs[key].image,
      image: blogList.blogs[key].image,
      date: blogList.blogs[key].date,
      btnText: "Learn More",
      href: `/blog/${key}`,
      author: blogList.blogs[key].author,
      category: blogList.blogs[key].category,
    };
  });
};

// Medicine Blogs
export const medicineBlogList = {
  blogs: {
    101: {
      author: "KidneyNeeds Team",
      date: "March 12, 2023",
      image: "/images/our_services/medication/medicines-blog-new-1a.jpg",
      category: "Medication Errors in Kidney Disease",
      blogHeading:
        "Ending Medication Errors: KidneyNeeds' Color-Coded Strategy",
    },
    102: {
      author: "KidneyNeeds Team",
      date: "March 24, 2023",
      image: "/images/our_services/medication/medicines-blog-new-2a.jpg",
      category: "Substandard Medicines",
      blogHeading:
        "Substandard Medicines Can Worsen Kidney Disease - Know About Medicine Quality",
    },
    103: {
      author: "KidneyNeeds Team",
      date: "April 2, 2023",
      image: "/images/our_services/medication/medicines-blog-new-3a.jpg",
      category: "Dialyzer",
      blogHeading: "Understanding the Dialyzer: Your Artificial Kidney",
    },
  },
};

export const medicineBlogDatas = () => {
  return Object.keys(medicineBlogList.blogs).map((key) => {
    return {
      title: medicineBlogList.blogs[key].blogHeading,
      thumbUrl: medicineBlogList.blogs[key].image,
      image: medicineBlogList.blogs[key].image,
      date: medicineBlogList.blogs[key].date,
      btnText: "Learn More",
      href: `/medicine/blog/${key}`,
      author: medicineBlogList.blogs[key].author,
      category: medicineBlogList.blogs[key].category,
    };
  });
};

// Catheters Blog Data
export const cathetersBlogList = {
  blogs: {
    101: {
      author: "KidneyNeeds Team",
      date: "March 12, 2023",
      image: "/images/our_services/catheters/catheter-blog-new-1a.jpg",
      category: "Dialysis Catheters",
      blogHeading:
        "Best Practices in Dialysis Catheter Care: A Patient's Guide",
    },
    102: {
      author: "KidneyNeeds Team",
      date: "March 24, 2023",
      image: "/images/our_services/catheters/catheter-blog-new-2a.jpg",
      category: "Catheters",
      blogHeading:
        "Dialysis and Catheters: Making Informed Choices for Your Health",
    },
    103: {
      author: "KidneyNeeds Team",
      date: "April 2, 2023",
      image: "/images/our_services/catheters/catheter-blog-new-3a.jpg",
      category: "Catheter Care Kit",
      blogHeading:
        "Our Not-for-Profit Catheter Care Infection Control Kit - Why it is a Necessity",
    },
  },
};

export const cathetersBlogDatas = () => {
  return Object.keys(cathetersBlogList.blogs).map((key) => {
    return {
      title: cathetersBlogList.blogs[key].blogHeading,
      thumbUrl: cathetersBlogList.blogs[key].image,
      image: cathetersBlogList.blogs[key].image,
      date: cathetersBlogList.blogs[key].date,
      btnText: "Learn More",
      href: `/catheters/blog/${key}`,
      author: cathetersBlogList.blogs[key].author,
      category: cathetersBlogList.blogs[key].category,
    };
  });
};

// Dialysis Accessories Blog Data
export const dialysisAccessoriesBlogList = {
  blogs: {
    101: {
      author: "KidneyNeeds Team",
      date: "March 12, 2023",
      image:
        "/images/our_services/dialysis-accessories/dialysis-accessories-blog-new-1a-1409.jpg",
      category: "Fistula Needle",
      blogHeading:
        "AV Fistula Needles: Metallic vs. Plastic - A Comprehensive Guide for Patients",
    },
    102: {
      author: "KidneyNeeds Team",
      date: "March 24, 2023",
      image: "/images/our_services/dialysis-accessories/blog-2.jpg",
      category: "Simplifying Dialysis Needs",
      blogHeading:
        "Beyond Cost: How KidneyNeeds Simplifies Access to All Your Dialysis Needs",
    },
    103: {
      author: "KidneyNeeds Team",
      date: "April 2, 2023",
      image:
        "/images/our_services/dialysis-accessories/dialysis-accessories-blog-new-3a.jpg",
      category: "Dialyzers",
      blogHeading: "Understanding the Dialyzer: Your Artificial Kidney",
    },
  },
};

export const dialysisAccessoriesBlogDatas = () => {
  return Object.keys(dialysisAccessoriesBlogList.blogs).map((key) => {
    return {
      title: dialysisAccessoriesBlogList.blogs[key].blogHeading,
      thumbUrl: dialysisAccessoriesBlogList.blogs[key].image,
      image: dialysisAccessoriesBlogList.blogs[key].image,
      date: dialysisAccessoriesBlogList.blogs[key].date,
      btnText: "Learn More",
      href: `/dialysis-accessories/blog/${key}`,
      author: dialysisAccessoriesBlogList.blogs[key].author,
      category: dialysisAccessoriesBlogList.blogs[key].category,
    };
  });
};

// Kidney-Biopsy Blogs
export const kidneyBiopsyBlogList = {
  blogs: {
    101: {
      author: "KidneyNeeds Team",
      date: "March 12, 2023",
      image:
        "/images/our_services/kidney-biopsy-gun/kidney-biopsy-gun-blog-new-1a.jpg",
      category: "Kidney Biopsy",
      blogHeading: "Understanding Kidney Biopsies: A Patient's Guide",
    },
    102: {
      author: "KidneyNeeds Team",
      date: "March 24, 2023",
      image:
        "/images/our_services/kidney-biopsy-gun/kidney-biopsy-gun-blog-new-2a.jpg",
      category: "Precision in Kidney Biopsy",
      blogHeading: "KidneyNeeds: Empowering Precision in Biopsy Procedures",
    },
    103: {
      author: "KidneyNeeds Team",
      date: "April 2, 2023",
      image:
        "/images/our_services/kidney-biopsy-gun/kidney-biopsy-gun-blog-new-3a.jpg",
      category: "Kidney Disease",
      blogHeading: "Understanding the Dialyzer: Your Artificial Kidney",
    },
  },
};

export const kidneyBiopsyBlogDatas = () => {
  return Object.keys(kidneyBiopsyBlogList.blogs).map((key) => {
    return {
      title: kidneyBiopsyBlogList.blogs[key].blogHeading,
      thumbUrl: kidneyBiopsyBlogList.blogs[key].image,
      image: kidneyBiopsyBlogList.blogs[key].image,
      date: kidneyBiopsyBlogList.blogs[key].date,
      btnText: "Learn More",
      href: `/kidney-biopsy/blog/${key}`,
      author: kidneyBiopsyBlogList.blogs[key].author,
      category: kidneyBiopsyBlogList.blogs[key].category,
    };
  });
};

export const allBlogLists = {
  blogs: {
    101: {
      author: "KidneyNeeds Team",
      date: "September 12, 2023",
      image: "/images/home/catheter-blog-1a.jpg",
      category: "Empowering",
      blogHeading:
        "KidneyNeeds' JEEVAN Kidney Initiative: Empowering Lives through Affordable, Quality Kidney Care",
      pageHints: "home",
    },
    102: {
      author: "KidneyNeeds Team",
      date: "March 24, 2023",
      image: "/images/our_services/catheters/catheter-blog-new-2a.jpg",
      category: "Catheters",
      blogHeading:
        "Dialysis and Catheters: Making Informed Choices for Your Health",
      pageHints: "home",
    },
    103: {
      author: "KidneyNeeds Team",
      date: "April 2, 2023",
      image: "/images/our_services/catheters/catheter-blog-new-3a.jpg",
      category: "Kidney Disease",
      blogHeading:
        "Our Not-for-Profit Catheter Care Infection Control Kit - Why it is a Necessity",
      pageHints: "home",
    },
    104: {
      author: "KidneyNeeds Team",
      date: "March 12, 2023",
      image: "/images/our_services/counselling/counselling-blog-new-1a.jpg",
      category: "Dialysis Vascular Access",
      blogHeading:
        "Don't Fear the Fistula or Catheter: Understanding Your Options for DialysisAccess",
      pageHints: "",
    },
    105: {
      author: "KidneyNeeds Team",
      date: "March 24, 2023",
      image: "/images/our_services/counselling/counselling-blog-new-2a.jpg",
      category: "Dialysis Fistula",
      blogHeading:
        "Doctor Recommends a Fistula - Does it Mean Dialysis is Imminent? Addressing Concerns in India",
      pageHints: "",
    },
    106: {
      author: "KidneyNeeds Team",
      date: "April 2, 2023",
      image: "/images/our_services/counselling/counselling-blog-new-3a.jpg",
      category: "Elderly Kidney Disease",
      blogHeading:
        "Kidney Disease in Seniors: A Journey of Understanding and Support",
      pageHints: "",
    },
    107: {
      author: "KidneyNeeds Team",
      date: "March 12, 2023",
      image: "/images/our_services/medication/medicines-blog-new-1a.jpg",
      category: "Medication Errors in Kidney Disease",
      blogHeading:
        "Ending Medication Errors: KidneyNeeds' Color-Coded Strategy",
      pageHints: "medicine",
    },
    108: {
      author: "KidneyNeeds Team",
      date: "March 24, 2023",
      image: "/images/our_services/medication/medicines-blog-new-2a.jpg",
      category: "Substandard Medicines",
      blogHeading:
        "Substandard Medicines Can Worsen Kidney Disease - Know About Medicine Quality",
      pageHints: "medicine",
    },
    109: {
      author: "KidneyNeeds Team",
      date: "April 2, 2023",
      image: "/images/our_services/medication/medicines-blog-new-3a.jpg",
      category: "Dialyzer",
      blogHeading: "Understanding the Dialyzer: Your Artificial Kidney",
      pageHints: "medicine",
    },
    110: {
      author: "KidneyNeeds Team",
      date: "March 12, 2023",
      image: "/images/our_services/catheters/catheter-blog-new-1a.jpg",
      category: "Dialysis Catheters",
      blogHeading:
        "Best Practices in Dialysis Catheter Care: A Patient's Guide",
      pageHints: "catheters",
    },
    111: {
      author: "KidneyNeeds Team",
      date: "March 24, 2023",
      image: "/images/our_services/catheters/catheter-blog-new-2a.jpg",
      category: "Catheters",
      blogHeading:
        "Dialysis and Catheters: Making Informed Choices for Your Health",
      pageHints: "catheters",
    },
    112: {
      author: "KidneyNeeds Team",
      date: "April 2, 2023",
      image: "/images/our_services/catheters/catheter-blog-new-3a.jpg",
      category: "Catheter Care Kit",
      blogHeading:
        "Our Not-for-Profit Catheter Care Infection Control Kit - Why it is a Necessity",
      pageHints: "catheters",
    },
    113: {
      author: "KidneyNeeds Team",
      date: "March 12, 2023",
      image:
        "/images/our_services/dialysis-accessories/dialysis-accessories-blog-new-1a-1409.jpg",
      category: "Fistula Needle",
      blogHeading:
        "AV Fistula Needles: Metallic vs. Plastic - A Comprehensive Guide for Patients",
      pageHints: "dialysis-accessories",
    },
    114: {
      author: "KidneyNeeds Team",
      date: "March 24, 2023",
      image: "/images/our_services/dialysis-accessories/blog-2.jpg",
      category: "Simplifying Dialysis Needs",
      blogHeading:
        "Beyond Cost: How KidneyNeeds Simplifies Access to All Your Dialysis Needs",
      pageHints: "dialysis-accessories",
    },
    115: {
      author: "KidneyNeeds Team",
      date: "April 2, 2023",
      image:
        "/images/our_services/dialysis-accessories/dialysis-accessories-blog-new-3a.jpg",
      category: "Dialyzers",
      blogHeading: "Understanding the Dialyzer: Your Artificial Kidney",
      pageHints: "dialysis-accessories",
    },
    116: {
      author: "KidneyNeeds Team",
      date: "March 12, 2023",
      image:
        "/images/our_services/kidney-biopsy-gun/kidney-biopsy-gun-blog-new-1a.jpg",
      category: "Kidney Biopsy",
      blogHeading: "Understanding Kidney Biopsies: A Patient's Guide",
      pageHints: "kidney-biopsy",
    },
    117: {
      author: "KidneyNeeds Team",
      date: "March 24, 2023",
      image:
        "/images/our_services/kidney-biopsy-gun/kidney-biopsy-gun-blog-new-2a.jpg",
      category: "Precision in Kidney Biopsy",
      blogHeading: "KidneyNeeds: Empowering Precision in Biopsy Procedures",
      pageHints: "kidney-biopsy",
    },
    118: {
      author: "KidneyNeeds Team",
      date: "April 2, 2023",
      image:
        "/images/our_services/kidney-biopsy-gun/kidney-biopsy-gun-blog-new-3a.jpg",
      category: "Kidney Disease",
      blogHeading: "Understanding the Dialyzer: Your Artificial Kidney",
      pageHints: "kidney-biopsy",
    },
  },
};

export const allBlogListDatas = () => {
  return Object.keys(allBlogLists.blogs).map((key) => {
    return {
      title: allBlogLists.blogs[key].blogHeading,
      thumbUrl: allBlogLists.blogs[key].image,
      image: allBlogLists.blogs[key].image,
      date: allBlogLists.blogs[key].date,
      btnText: "Learn More",
      href:
        allBlogLists.blogs[key].pageHints === ""
          ? `/blog/${key}`
          : `/${allBlogLists.blogs[key].pageHints}/blog/${key}`,
      author: allBlogLists.blogs[key].author,
      category: allBlogLists.blogs[key].category,
    };
  });
};
