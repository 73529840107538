import React from "react";
import { Link } from "react-router-dom";

export default function IconBoxStyle14({
  title,
  subTitle = "",
  href,
  iconUrl,
  bgUrl,
}) {
  return (
    <Link to={href} className="cs_iconbox cs_style_2 cs_style-14">
      <div className="cs_style_14-bg">
        <img loading="lazy" src={bgUrl} alt="" />
      </div>

      {iconUrl && (
        <div className="cs_iconbox_icon">
          <img loading="lazy" src={iconUrl} alt="Icon" />
        </div>
      )}
      <h2 className="cs_iconbox_title cs_fs_22 cs_semibold m-0 cs_accent_color">
        {title}
      </h2>
      {subTitle && <p className="cs_iconbox_subtitle mb-0">{subTitle}</p>}
    </Link>
  );
}
