export const subscriberTemplate = (input) => {
    return `<!DOCTYPE html>
  <html lang="en">
  <head>
      <meta charset="UTF-8">
      <meta name="viewport" content="width=device-width, initial-scale=1.0">
      <title>New Subscriber</title>
  </head>
  <body>
      <h2>New Subscriber!</h2>
      <p><strong>Requesting User Email:</strong> ${input.email}</p>
  </body>
  </html>`;
  };