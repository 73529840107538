import React from "react";
import { Trans, useTranslation } from "react-i18next";
import { firstBlogContentDatas } from "./firstBlogContents";
import Spacing from "../../components/Spacing";
import BlogList from "../Blogs/BlogComponents/BlogList";
import CustomTable from "../../components/Table";

const FirstBlog = () => {
  const { t } = useTranslation(["homeBlog"]);
  const {
    whyJEEVANIsNeededListData,
    howWeMakeItPossibleListData,
    illustrativePrescriptionCostComparisonListData,
    estimatedMonthlyCostWithJEEVANListData,
  } = firstBlogContentDatas();

  return (
    <>
      <h2>{t(`blogs.101.innerHTML.revolutionizingKidneyCare.title`)}</h2>

      <Spacing md="20" lg="20" />
      <h2>{t(`blogs.101.innerHTML.rajeshStory.title`)}</h2>
      <p>{t(`blogs.101.innerHTML.rajeshStory.description.para1`)}</p>
      <p>{t(`blogs.101.innerHTML.rajeshStory.description.para2`)}</p>

      <Spacing md="20" lg="20" />
      <h2>{t(`blogs.101.innerHTML.jeevan.title`)}</h2>
      <p>{`"${t(`blogs.101.innerHTML.jeevan.description`)}"`}</p>

      <Spacing md="20" lg="20" />
      <h2>{t(`blogs.101.innerHTML.whyJEEVANIsNeeded.title`)}</h2>
      <p>{t(`blogs.101.innerHTML.whyJEEVANIsNeeded.description.para1`)}</p>
      <p>{t(`blogs.101.innerHTML.whyJEEVANIsNeeded.description.para2`)}</p>
      <div className="cs_blog_un_ord_list">
        <BlogList data={whyJEEVANIsNeededListData} />
      </div>
      <p>{t(`blogs.101.innerHTML.whyJEEVANIsNeeded.notes`)}</p>

      <Spacing md="20" lg="20" />
      <h2>{t(`blogs.101.innerHTML.howWeMakeItPossible.title`)}</h2>
      <div className="cs_blog_un_ord_list">
        <BlogList data={howWeMakeItPossibleListData} />
      </div>
      <p>{t(`blogs.101.innerHTML.howWeMakeItPossible.notes`)}</p>

      <Spacing md="20" lg="20" />
      <h2>
        {t(`blogs.101.innerHTML.illustrativePrescriptionCostComparison.title`)}
      </h2>
      <p>
        {t(
          `blogs.101.innerHTML.illustrativePrescriptionCostComparison.description`
        )}
      </p>
      <div className="cs_blog_un_ord_list">
        <BlogList data={illustrativePrescriptionCostComparisonListData} />
      </div>

      <Spacing md="20" lg="20" />
      <h2>{t(`blogs.101.innerHTML.estimatedMonthlyCostWithJEEVAN.title`)}</h2>
      <div className="cs_blog_un_ord_list">
        <BlogList data={estimatedMonthlyCostWithJEEVANListData} />
      </div>

      <Spacing md="20" lg="20" />
      <h2>{t(`blogs.101.innerHTML.ensuringQualityAccess.title`)}</h2>
      <p>{t(`blogs.101.innerHTML.ensuringQualityAccess.description.para1`)}</p>
      <p>{t(`blogs.101.innerHTML.ensuringQualityAccess.description.para2`)}</p>

      <Spacing md="20" lg="20" />
      <h2>{t(`blogs.101.innerHTML.yourPersonalizedPath.title`)}</h2>
      <p>
        <Trans
          i18nKey={t("blogs.101.innerHTML.yourPersonalizedPath.description")}
          components={{
            boldText: <b />,
          }}
        />
      </p>

      <Spacing md="20" lg="20" />
      <h2>{t(`blogs.101.innerHTML.conclusion.title`)}</h2>
      <p>{t(`blogs.101.innerHTML.conclusion.description`)}</p>

      <Spacing md="20" lg="20" />
      <h3>{t(`blogs.101.innerHTML.notes`)}</h3>

      <CustomTable />
    </>
  );
};

export default FirstBlog;
