import React, { useState } from "react";
import AboutSection from "../Section/AboutSection";
import Section from "../Section";
import BlogSection from "../Section/BlogSection";
import FaqSection from "../Section/FaqSection";
import { pageTitle } from "../../helpers/PageTitle";
import TestimonialSectionStyle4 from "../Section/TestimonialSection/TestimonialSectionStyle4";
import AppointmentWithContactInfoSection from "../Section/AppointmentWithContactInfoSection";
import BlogSectionStyle5 from "../Section/BlogSection/BlogSectionStyle5";
import DepartmentSectionStyle7 from "../Section/DepartmentSection/DepartmentSectionStyle7";
import HeroStyle2 from "../Hero/HeroStyle2";
import FunFactSection from "../Section/FunFactSection";
import GallerySection from "../Section/GallerySection";
import DepartmentSection from "../Section/DepartmentSection";
import ContactUsSlider from "../Slider/ContactUsSlider";
import PopupModalComponent from "../PopupModal";
import { homeBlogDatas } from "../../constants/common-contents";

const departmentData = [
  {
    title: "Counselling",
    iconUrl: "/images/home/counselling-1.png",
    href: "/our-services/counselling",
  },
  {
    title: "Health Records",
    iconUrl: "/images/home/Managing-Health-2.png",
    href: "/our-services/health-records",
  },
  {
    title: "Specialised Diet",
    iconUrl: "/images/home/Specialised-Diet-3.png",
    href: "/our-services/kidney-diet",
  },
  {
    title: "Blood Test Lab",
    iconUrl: "/images/home/Blood-Test-Lab-4.png",
    href: "/our-services/lab-test-service",
  },
  {
    title: "Buy Medicines With Us",
    iconUrl: "/images/home/Medicines-5.png",
    href: "/our-services/medicines",
  },
  {
    title: "Dialysis & Catheter",
    iconUrl: "/images/home/Dialyzers-Catheter-6.png",
    href: "/our-services/catheters",
  },
];

const funFactData = [
  { number: "20+", title: "Years of experience" },
  { number: "99%", title: "Patient satisfaction rating" },
  { number: "5000+", title: "Patients served annually" },
  { number: "3+", title: "Years in Service" },
  { number: "22+", title: "Convenient lab locations" },
];

const testimonialData = [
  {
    text: '"I recently had to bring my child to KidneyNeeds for a minor injury, and I was so impressed with the care he received. The pediatrician was great with him and made him feel at ease.”',
    ratingNumber: "5",
    avatarImgUrl: "/images/home_4/avatar_2_2.png",
    avatarName: "Smiti Sharma",
    avatarDesignation: "Delhi, India",
  },
  {
    text: '"KidneyNeeds has been a game-changer for me. The doctors are caring and highly skilled, and the staff is always friendly and accommodating. We hope for the best wishes.”',
    ratingNumber: "5",
    avatarImgUrl: "/images/home_4/avatar_3_3.png",
    avatarName: "Rishi Kumar",
    avatarDesignation: "Mumbai, India",
  },
  {
    text: "“I had a great experience with KidneyNeeds. The staff were friendly and professional, and the doctor took the time to answer all my questions and address my concerns.”",
    ratingNumber: "5",
    avatarImgUrl: "/images/home_4/avatar_4_4.png",
    avatarName: "Imran Ahmed",
    avatarDesignation: "Delhi, India",
  },
  {
    text: '"I recently had to bring my child to KidneyNeeds for a minor injury, and I was so impressed with the care he received. The pediatrician was great with him and made him feel at ease.”',
    ratingNumber: "5",
    avatarImgUrl: "/images/home_4/avatar_2_2.png",
    avatarName: "Smiti Sharma",
    avatarDesignation: "Delhi, India",
  },
  {
    text: '"KidneyNeeds has been a game-changer for me. The doctors are caring and highly skilled, and the staff is always friendly and accommodating. We hope for the best wishes.”',
    ratingNumber: "5",
    avatarImgUrl: "/images/home_4/avatar_3_3.png",
    avatarName: "Rishi Kumar",
    avatarDesignation: "Mumbai, India",
  },
  {
    text: "“I had a great experience with KidneyNeeds. The staff were friendly and professional, and the doctor took the time to answer all my questions and address my concerns.”",
    ratingNumber: "5",
    avatarImgUrl: "/images/home_4/avatar_4_4.png",
    avatarName: "Imran Ahmed",
    avatarDesignation: "Delhi, India",
  },
];

const galleryData = [
  { imgUrl: "/images/home/latest-activities-1.jpg", hoverText: "Kidney Needs" },
  { imgUrl: "/images/home/latest-activities-2.jpg", hoverText: "Kidney Needs" },
  { imgUrl: "/images/home/latest-activities-3.jpg", hoverText: "Kidney Needs" },
  { imgUrl: "/images/home/latest-activities-4.jpg", hoverText: "Kidney Needs" },
  { imgUrl: "/images/home/latest-activities-5.jpg", hoverText: "Kidney Needs" },
];

const faqData = [
  {
    title: "What is KidneyNeeds and what services do you offer?",
    content:
      "KidneyNeeds is a comprehensive platform dedicated to supporting individuals with kidney disease. We offer a range of services tailored to their needs, including disease-specific counselling, medication support, assistance finding suitable healthcare providers, and personalized dietary advice.",
  },
  {
    title: "Who can benefit from KidneyNeeds services?",
    content:
      "Anyone diagnosed with kidney disease, their caregivers, or those at risk for kidney disease can benefit from our services.",
  },
  {
    title: "Why is counselling important for kidney patients?",
    content:
      "Counselling plays a crucial role in helping kidney patients understand their condition, manage emotional stress, adhere to treatment plans, and improve their quality of life.",
  },
  {
    title:
      "What role does dietary advice play in kidney health? How does KidneyNeeds help?",
    content:
      "Renal nutrition is vital in managing kidney disease and slowing its progression. KidneyNeeds offers personalized dietary advice, including menu planning and alternative food suggestions tailored to individual preferences, to support optimal kidney health.",
  },
  {
    title: "How can I manage my daily routine with kidney disease?",
    content:
      "Kidney disease can impact your daily life, but with proper management, you can maintain a fulfilling routine. KidneyNeeds offers various resources to help with fatigue management, medication schedules, and dietary restrictions.",
  },
  {
    title: "What kind of emotional support does KidneyNeeds offer?",
    content:
      "Kidney disease can bring emotional challenges. KidneyNeeds provides support groups (online and in-person where possible), online forums, and individual counseling to help you connect with others and receive personalized emotional support.",
  },
  {
    title: "How can I access KidneyNeeds services?",
    content:
      "You can access KidneyNeeds services conveniently through our website. We offer detailed information about our offerings and a contact form for personalized assistance.",
  },
  {
    title: "Is there a cost associated with using KidneyNeeds services?",
    content:
      "Our core services, including counseling, medication education, and motivational support with basic dietary guidance, are free. However, some specialized services might have a cost. We will always be transparent about fees before you proceed.",
  },
  {
    title: "How does KidneyNeeds help with medication support?",
    content:
      "KidneyNeeds collaborates with pharmacies to provide discounted medications and assists in sourcing generic alternatives, ensuring affordability.",
  },
  {
    title:
      "I'm experiencing side effects from my medications. Can KidneyNeeds help?",
    content:
      "While consulting your healthcare provider is essential, KidneyNeeds can offer guidance on understanding potential causes and communicating effectively with your doctor.",
  },
  {
    title:
      "Can KidneyNeeds help me find financial assistance for my kidney care needs?",
    content:
      "Absolutely! KidneyNeeds can assist you in exploring various financial assistance options.",
  },
  {
    title:
      "Does KidneyNeeds offer educational resources for kidney patients and their families?",
    content:
      "Yes, KidneyNeeds provides a comprehensive library of educational resources like articles, guides, and webinars to empower patients and families with knowledge about managing kidney disease and treatment options.",
  },
  {
    title:
      "How can I stay updated on the latest advancements in kidney disease treatment?",
    content:
      "KidneyNeeds keeps you informed through blog posts, newsletters, and webinars featuring the latest research, treatment advancements, and relevant health information.",
  },
  {
    title: "What happens if my kidney disease progresses?",
    content:
      "While kidney disease management focuses on slowing progression, understanding potential future scenarios is important. KidneyNeeds can help you prepare for kidney replacement therapy options and connect you with support resources.",
  },
  {
    title: "Can KidneyNeeds help me connect with other kidney patients?",
    content:
      "Absolutely! Building a support network is crucial. KidneyNeeds offers online communities and local support groups (where possible) to connect with others facing similar challenges.",
  },
];

const blogData = [
  {
    title: "Knowledge Hub",
    subTitle:
      "Education and clinical tools for the prevention and management of kidney disease...",
    thumbUrl: "images/home/Knowledge-Hub.jpg",
    date: "May 1, 2023",
    btnText: "Learn More",
    href: "/kidney-education/kidney-diseases",
    socialShare: false,
  },
  {
    title: "Know About Us",
    subTitle:
      "Best affordable kidney medical health and wellness services in INDIA...",
    thumbUrl: "images/home/know-about-us.jpg",
    date: "May 4, 2023",
    btnText: "Learn More",
    href: "/our-services/counselling",
    socialShare: false,
  },
  {
    title: "Our Service",
    subTitle:
      "Our research creates knowledge about and treatments for kidney needs that are among...",
    thumbUrl: "images/home/our-services.jpg",
    date: "May 1, 2023",
    btnText: "Learn More",
    href: "/our-services/counselling",
    socialShare: false,
  },
  {
    title: "Are You in Risk?",
    subTitle:
      "Know your health status up to date for you and your family, take an action today...",
    thumbUrl: "images/home/are-you-at-risk.jpg",
    date: "May 1, 2023",
    btnText: "Learn More",
    href: "/contact",
    socialShare: false,
  },
];

const ourDepartmentData = [
  {
    title: "Counselling",
    subTitle:
      "Not just emotional support, but also a strong foundation in understanding your illness, managing",
    // iconUrl: "/images/home/counselling-1.png",
    bgUrl: "/images/home/counselling-bg.jpg",
    href: "/our-services/counselling",
  },
  {
    title: "Health Records",
    subTitle:
      "we understand the importance of comprehensive health record management for CKD",
    // iconUrl: "/images/home/Managing-Health-2.png",
    bgUrl: "/images/home/medical-record-bg.jpg",
    href: "/our-services/health-records",
  },
  {
    title: "Catheters",
    subTitle:
      "Vital treatment for many individuals with advanced kidney disease. We are dedicated to making",
    // iconUrl: "/images/home/Specialised-Diet-3.png",
    bgUrl: "/images/home/diet-bg.jpg",
    href: "/our-services/catheters",
  },
  {
    title: "Accessories",
    subTitle:
      "We are committed to being your one-stop shop for all your dialysis accessory needs",
    // iconUrl: "/images/home/Blood-Test-Lab-4.png",
    bgUrl: "/images/home/lab-test-bg.jpg",
    href: "/our-services/dialysis-accessories",
  },
  {
    title: "Kidney Needs Biopsy",
    subTitle:
      "Here to make the process as smooth and comfortable as possible by offering access",
    // iconUrl: "/images/home/Medicines-5.png",
    bgUrl: "/images/home/medicine-discount-bg.jpg",
    href: "/our-services/kideny-biopsy-gun",
  },
  {
    title: "E- Learning Course",
    subTitle:
      "we understand that navigating CKD can be overwhelming. That's why we've developed a patient-centered e-learning course",
    // iconUrl: "/images/home/Dialyzers-Catheter-6.png",
    bgUrl: "/images/home/dialysis-bg.jpg",
    href: "/our-services/e-learning",
  },
];

const contactUsSliderData = [
  {
    bgUrl: "images/home/missed-call-BG.png",
    imgUrl: "images/home_1/cta_img.png",
    title: "Don’t Let You & Your Loved Ones Healthy Life At Risk!",
    subTitle:
      "Schedule an appointment with one of our experienced medical professionals today!",
    subPara: "Give us missed call +91 91116 06162",
    fontStlye: "cs_fs_72 cs_semibold",
  },
  {
    bgUrl: "images/home/missed-call-BG.png",
    imgUrl: "images/home_1/cta_img.png",
    title: "Don’t Let You & Your Loved Ones Healthy Life At Risk!",
    subTitle:
      "Schedule an appointment with one of our experienced medical professionals today!",
    subPara: "Give us missed call +91 91116 06162",
    fontStlye: "cs_fs_72 cs_semibold",
  },
  {
    bgUrl: "images/home/missed-call-BG.png",
    imgUrl: "images/home_1/cta_img.png",
    title: "Don’t Let You & Your Loved Ones Healthy Life At Risk!",
    subTitle:
      "Schedule an appointment with one of our experienced medical professionals today!",
    subPara: "Give us missed call +91 91116 06162",
    fontStlye: "cs_fs_72 cs_semibold",
  },
];

// const brandData = [
//   {
//     imgUrl: "/images/brand_1_1.png",
//     imgAlt: "Brand",
//   },
//   {
//     imgUrl: "/images/brand_2_2.png",
//     imgAlt: "Brand",
//   },
//   {
//     imgUrl: "/images/brand_3_3.png",
//     imgAlt: "Brand",
//   },
//   {
//     imgUrl: "/images/brand_4_4.png",
//     imgAlt: "Brand",
//   },
//   {
//     imgUrl: "/images/brand_5_5.png",
//     imgAlt: "Brand",
//   },
//   {
//     imgUrl: "/images/brand_6_6.png",
//     imgAlt: "Brand",
//   },
//   {
//     imgUrl: "/images/brand_7_7.png",
//     imgAlt: "Brand",
//   },
// ];

export default function Home() {
  pageTitle("Home");
  const [isGrantModalOpen, setIsGrantModalOpen] = useState(false);

  const openGrantModal = () => setIsGrantModalOpen(true);
  const closeGrantModal = () => setIsGrantModalOpen(false);

  const [isArtModalOpen, setArtIsModalOpen] = useState(false);

  const openArtModal = () => setArtIsModalOpen(true);
  const closeArtModal = () => setArtIsModalOpen(false);

  return (
    <>
      {/* Banner Section Start */}
      <HeroStyle2
        title="KidneyNeeds<br/> Your Complete<br/> Kidney Health Solution"
        subTitle="Your trusted partner for comprehensive kidney care, providing convenient access to expert guidance, affordable medications, advanced diagnostics, and personalized, holistic support to empower your well-being."
        bgUrl="/images/home/home-page-1.jpg"
        imgUrl="/images/home_2/patents_1.png"
        videoBtnText="See how we work"
        videoUrl="https://www.youtube.com/embed/VcaAVWtP48A"
        btnText=""
        btnUrl="/"
        infoNavList={[
          {
            title: "Lab Tests",
            iconUrl: "/images/home/home-lab-test-icon.svg",
            href: "/",
          },
          {
            title: "Diet Chart",
            iconUrl: "/images/home/home-diet-chart-icon.svg",
            href: "/",
          },
          {
            title: "Dialysis",
            iconUrl: "/images/home/home-dialysis-icon.svg",
            href: "/",
          },
        ]}
        funfactList={[]}
        customClasses="cs_home_page_banner"
      />
      {/* Banner Section End */}

      {/* Start Info Section */}
      <Section
        topMd={50}
        topLg={50}
        topXl={50}
        bottomXl={80}
        bottomLg={80}
        bottomMd={80}
        className="cs_home_info_sec"
      >
        <div className="container">
          <div className="row g-4 g-xl-3 g-xxl-5">
            <div
              className="col-xl-6"
              style={{ cursor: "pointer" }}
              onClick={openGrantModal}
            >
              <div className="cs_iconbox cs_style_11">
                <div className="cs_iconbox_icon">
                  <img
                    loading="lazy"
                    src="/images/home/kn-home-small-images.jpg"
                    alt="Icon"
                  />
                </div>
                <div className="cs_iconbox_right">
                  <h3 className="cs_iconbox_title cs_fs_24 mb-0">
                    Grants & Funding
                  </h3>
                  <p className="cs_iconbox_subtitle mb-0 cs_heading_color">
                    Access information on research grant program, Including
                    available grants, funding cycle, eligibility requirements
                    and application instruction.
                  </p>
                </div>
              </div>
            </div>
            <div
              className="col-xl-6"
              style={{ cursor: "pointer" }}
              onClick={openArtModal}
            >
              <div className="cs_iconbox cs_style_11">
                <div className="cs_iconbox_icon">
                  <img
                    src="/images/home/kn-home-small-images-2.jpg"
                    alt="Icon"
                  />
                </div>
                <div className="cs_iconbox_right">
                  <h3 className="cs_iconbox_title cs_fs_24 mb-0">
                    Kidney Research Connect
                  </h3>
                  <p className="cs_iconbox_subtitle mb-0 cs_heading_color">
                    Sign up today to find patients and care partners to engage
                    in your patient-centered outcomes research (PCOR) projects.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Section>
      {/* End Info Section */}

      {/* Start Blog Section */}
      <Section bottomMd={130} bottomLg={120} bottomXl={110}>
        <BlogSection sectionTitle="" sectionTitleUp="" data={blogData} />
      </Section>
      {/* End Blog Section */}

      {/* Department Section Start */}
      <Section bottomMd={130} bottomLg={120} bottomXl={110}>
        <DepartmentSection
          sectionTitle="We Are Specialized in"
          bgUrl="images/home/counter-BG.jpg"
          data={departmentData}
        />
      </Section>
      {/* Department Section End */}

      {/* Our Department Section Start */}
      <Section bottomMd={130} bottomLg={120} bottomXl={110}>
        <DepartmentSectionStyle7
          sectionTitle="For Your Health"
          sectionTitleUp="OUR DEPARTMENTS"
          data={ourDepartmentData}
          sectionTitleUpBrandColor=""
        />
      </Section>
      {/* Our Department Section End */}

      {/* About Section Start */}
      <Section bottomMd={130} bottomLg={120} bottomXl={110}>
        <AboutSection
          imgUrl="/images/home/about.jpg"
          spiningImgUrl="/images/home_1/about_mini.svg"
          title="About Us"
          subTitle="KIDNEYNEEDS"
          featureList={[
            {
              featureListTitle:
                "KidneyNeeds is a team of experienced medical professionals",
              featureListSubTitle:
                "Dedicated to providing top-quality healthcare services. We believe in a holistic approach to healthcare that focuses on treating the whole person, not just the illness or symptoms.",
            },
          ]}
        />
      </Section>
      {/* About Section End */}

      {/* Start Our Clients Section */}
      {/* <Section bottomMd={130} bottomLg={120} bottomXl={110}>
        <TestimonialSectionStyle4
          sectionTitle="Discover the Stories of Health and Healing"
          sectionTitleUp="WHAT OUR PATIENTS SAY"
          avatarListTitle="150+ patients are reviews about KidneyNeeds"
          avatarList={[
            { imgUrl: "/images/home_4/avatar_1_1.png" },
            { imgUrl: "/images/home_4/avatar_2_2.png" },
            { imgUrl: "/images/home_4/avatar_3_3.png" },
            { imgUrl: "/images/home_4/avatar_4_4.png" },
            { imgUrl: "/images/home_4/avatar_5_5.png" },
          ]}
          data={testimonialData}
        />
      </Section> */}
      {/* End Our Clients Section */}

      {/* Our Facilities Start */}
      <Section bottomMd={130} bottomLg={120} bottomXl={110}>
        <GallerySection
          sectionTitle="Our Latest Activities"
          sectionTitleUp="HAVE A LOOK AT"
          data={galleryData}
        />
      </Section>
      {/* Our Facilities End */}

      {/* Start Banner Section */}
      <Section bottomMd={130} bottomLg={120} bottomXl={110}>
        {/* <Banner
          bgUrl="images/home/missed-call-BG.png"
          imgUrl="images/home_1/cta_img.png"
          title="Don’t Let You & Your Loved Ones Healthy Life At Risk!"
          subTitle="Schedule an appointment with one of our experienced medical professionals today!"
          subPara="Give us missed call +91 91116 06162"
          fontStyle="cs_fs_72 cs_semibold"
        /> */}
        <div className="container">
          <div className="position-relative">
            <ContactUsSlider data={contactUsSliderData} />
          </div>
        </div>
      </Section>
      {/* End Banner Section */}

      {/* About Us Section Start */}
      <Section bottomMd={130} bottomLg={120} bottomXl={110}>
        <FunFactSection
          bgUrl="images/home/counter-BG.jpg"
          fontColor="cs_white_color"
          data={funFactData}
        />
      </Section>
      {/* About Us Section End */}

      {/* Start Brand Section */}
      {/* <Section
        topMd={190}
        topLg={145}
        topXl={105}
        bottomMd={200}
        bottomLg={150}
        bottomXl={110}
      >
        <BrandsSectionStyle2 data={brandData} />
      </Section> */}
      {/* End Brand Section */}

      {/* Appointment And Upload Section Start */}
      <Section bottomMd={130} bottomLg={120} bottomXl={110}>
        <AppointmentWithContactInfoSection customClass="cs_upload_prescription" />
      </Section>
      {/* Appointment And Upload Section End */}

      {/* Blog Section Start */}
      <Section bottomMd={130} bottomLg={120} bottomXl={110}>
        <BlogSectionStyle5
          sectionTitle="Latest Update"
          sectionTitleUp="BLOG POSTS"
          sectionTitleDown=""
          sectionSubTitle=""
          data={homeBlogDatas().slice(0, 3)}
        />
      </Section>
      {/* Blog Section End */}

      {/* Start FAQ Section */}
      <Section bottomMd={130} bottomLg={120} bottomXl={110}>
        <FaqSection
          data={faqData}
          sectionTitle="Frequently Asked Questions"
          sectionTitleUp="What People"
        />
      </Section>
      {/* End FAQ Section */}

      <PopupModalComponent
        heading={"Grant Enquiry"}
        isOpen={isGrantModalOpen}
        onClose={closeGrantModal}
      />
      <PopupModalComponent
        heading={"Research Connect Enquiry"}
        isOpen={isArtModalOpen}
        onClose={closeArtModal}
      />
    </>
  );
}
