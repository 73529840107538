import i18n from "../../../../i18n/i18n";

export const secondBlogContentDatas = () => {
  const comprehensiveDialysisSolutionsListData = [
    {
      title: i18n.t(
        "blogs.102.innerHTML.comprehensiveDialysisSolutions.list1.title",
        {
          ns: "dialysisAccessoriesBlog",
        }
      ),
      description: i18n.t(
        "blogs.102.innerHTML.comprehensiveDialysisSolutions.list1.description",
        {
          ns: "dialysisAccessoriesBlog",
        }
      ),
      childList: [
        {
          title: i18n.t(
            "blogs.102.innerHTML.comprehensiveDialysisSolutions.list1.subLists.list1.title",
            {
              ns: "dialysisAccessoriesBlog",
            }
          ),
          description: i18n.t(
            "blogs.102.innerHTML.comprehensiveDialysisSolutions.list1.subLists.list1.description",
            {
              ns: "dialysisAccessoriesBlog",
            }
          ),
        },
        {
          title: i18n.t(
            "blogs.102.innerHTML.comprehensiveDialysisSolutions.list1.subLists.list2.title",
            {
              ns: "dialysisAccessoriesBlog",
            }
          ),
          description: i18n.t(
            "blogs.102.innerHTML.comprehensiveDialysisSolutions.list1.subLists.list2.description",
            {
              ns: "dialysisAccessoriesBlog",
            }
          ),
        },
        {
          title: i18n.t(
            "blogs.102.innerHTML.comprehensiveDialysisSolutions.list1.subLists.list3.title",
            {
              ns: "dialysisAccessoriesBlog",
            }
          ),
          description: i18n.t(
            "blogs.102.innerHTML.comprehensiveDialysisSolutions.list1.subLists.list3.description",
            {
              ns: "dialysisAccessoriesBlog",
            }
          ),
        },
      ],
    },
    {
      title: i18n.t(
        "blogs.102.innerHTML.comprehensiveDialysisSolutions.list2.title",
        {
          ns: "dialysisAccessoriesBlog",
        }
      ),
      description: i18n.t(
        "blogs.102.innerHTML.comprehensiveDialysisSolutions.list2.description",
        {
          ns: "dialysisAccessoriesBlog",
        }
      ),
      childList: [
        {
          title: i18n.t(
            "blogs.102.innerHTML.comprehensiveDialysisSolutions.list2.subLists.list1.title",
            {
              ns: "dialysisAccessoriesBlog",
            }
          ),
          description: i18n.t(
            "blogs.102.innerHTML.comprehensiveDialysisSolutions.list2.subLists.list1.description",
            {
              ns: "dialysisAccessoriesBlog",
            }
          ),
        },
        {
          title: i18n.t(
            "blogs.102.innerHTML.comprehensiveDialysisSolutions.list2.subLists.list2.title",
            {
              ns: "dialysisAccessoriesBlog",
            }
          ),
          description: i18n.t(
            "blogs.102.innerHTML.comprehensiveDialysisSolutions.list2.subLists.list2.description",
            {
              ns: "dialysisAccessoriesBlog",
            }
          ),
        },
      ],
    },
    {
      title: i18n.t(
        "blogs.102.innerHTML.comprehensiveDialysisSolutions.list3.title",
        {
          ns: "dialysisAccessoriesBlog",
        }
      ),
      description: i18n.t(
        "blogs.102.innerHTML.comprehensiveDialysisSolutions.list3.description",
        {
          ns: "dialysisAccessoriesBlog",
        }
      ),
    },
  ];

  const addressingSpecificNeedsListData = [
    {
      title: i18n.t("blogs.102.innerHTML.addressingSpecificNeeds.list1.title", {
        ns: "dialysisAccessoriesBlog",
      }),
      description: i18n.t(
        "blogs.102.innerHTML.addressingSpecificNeeds.list1.description",
        {
          ns: "dialysisAccessoriesBlog",
        }
      ),
    },
    {
      title: i18n.t("blogs.102.innerHTML.addressingSpecificNeeds.list2.title", {
        ns: "dialysisAccessoriesBlog",
      }),
      description: i18n.t(
        "blogs.102.innerHTML.addressingSpecificNeeds.list2.description",
        {
          ns: "dialysisAccessoriesBlog",
        }
      ),
    },
  ];

  const goingTheExtraMileListData = [
    {
      title: i18n.t("blogs.102.innerHTML.goingTheExtraMile.list1.title", {
        ns: "dialysisAccessoriesBlog",
      }),
      description: i18n.t(
        "blogs.102.innerHTML.goingTheExtraMile.list1.description",
        {
          ns: "dialysisAccessoriesBlog",
        }
      ),
    },
    {
      title: i18n.t("blogs.102.innerHTML.goingTheExtraMile.list2.title", {
        ns: "dialysisAccessoriesBlog",
      }),
      description: i18n.t(
        "blogs.102.innerHTML.goingTheExtraMile.list2.description",
        {
          ns: "dialysisAccessoriesBlog",
        }
      ),
    },
  ];

  const beyondProductsListData = [
    {
      title: i18n.t("blogs.102.innerHTML.beyondProducts.list1.title", {
        ns: "dialysisAccessoriesBlog",
      }),
      description: i18n.t(
        "blogs.102.innerHTML.beyondProducts.list1.description",
        {
          ns: "dialysisAccessoriesBlog",
        }
      ),
    },
    {
      title: i18n.t("blogs.102.innerHTML.beyondProducts.list2.title", {
        ns: "dialysisAccessoriesBlog",
      }),
      description: i18n.t(
        "blogs.102.innerHTML.beyondProducts.list2.description",
        {
          ns: "dialysisAccessoriesBlog",
        }
      ),
    },
  ];

  return {
    comprehensiveDialysisSolutionsListData,
    addressingSpecificNeedsListData,
    goingTheExtraMileListData,
    beyondProductsListData,
  };
};
