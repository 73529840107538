import React from "react";
import SectionHeading from "../SectionHeading";
import Spacing from "../Spacing";
// import AccordionWithComponent from "../AccordionWithComponent";
import SingleGridAccordion from "../SingleGridAccordion/SingleGridAccordion";

const TwoGridAccordion = ({
  bgUrl,
  headingTitle,
  headingSubTitle,
  list1 = [],
  list2 = [],
  customClasses = "",
}) => {
  return (
    <div className="container">
      <div className="cs_departments cs_style_1">
        <div
          className="cs_departments_bg cs_radius_25"
          style={{
            backgroundImage: `url(${bgUrl})`,
          }}
        />
        <SectionHeading
          title={headingTitle}
          subTitle={headingSubTitle}
          center
        />
        <Spacing md="72" lg="50" />
        <div className="cs_department_list">
          <div className="cs_banner cs_style_9 cs_white_bg cs_radius_30">
            <div className="row">
              <div className="col-lg-6 col-xl-6 cs_list_sec1">
                {/* <AccordionWithComponent
                  variant="cs_style1 cs_type_3"
                  data={list1}
                /> */}
                <SingleGridAccordion
                  accordionData={list1}
                  customClasses={customClasses}
                />
              </div>
              <div className="col-lg-6 col-xl-6 cs_list_sec2">
                <SingleGridAccordion
                  accordionData={list2}
                  customClasses={customClasses}
                />
                {/* <AccordionWithComponent
                  variant="cs_style1 cs_type_3"
                  data={list2}
                /> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TwoGridAccordion;
