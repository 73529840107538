import i18n from "../../../i18n/i18n";

export const counsellingContentDatas = () => {
  const kidneyDiseaseCounsellingListData = [
    {
      title: i18n.t("kidneyDiseaseCounsellingList.lists.list1.title", {
        ns: "counselling",
      }),
      subTitle: i18n.t("kidneyDiseaseCounsellingList.lists.list1.description", {
        ns: "counselling",
      }),
    },
    {
      title: i18n.t("kidneyDiseaseCounsellingList.lists.list2.title", {
        ns: "counselling",
      }),
      subTitle: i18n.t("kidneyDiseaseCounsellingList.lists.list2.description", {
        ns: "counselling",
      }),
    },
    {
      title: i18n.t("kidneyDiseaseCounsellingList.lists.list3.title", {
        ns: "counselling",
      }),
      subTitle: i18n.t("kidneyDiseaseCounsellingList.lists.list3.description", {
        ns: "counselling",
      }),
    },
  ];
  const medicalManagementListData = [
    {
      title: i18n.t("medicationManagementList.lists.list1.title", {
        ns: "counselling",
      }),
      subTitle: i18n.t("medicationManagementList.lists.list1.description", {
        ns: "counselling",
      }),
      iconUrl: "/images/kidney_educations/waste-removal.jpg",
    },
    {
      title: i18n.t("medicationManagementList.lists.list2.title", {
        ns: "counselling",
      }),
      subTitle: i18n.t("medicationManagementList.lists.list2.description", {
        ns: "counselling",
      }),
      iconUrl: "/images/kidney_educations/electrolyte-balance.jpg",
    },
    {
      title: i18n.t("medicationManagementList.lists.list3.title", {
        ns: "counselling",
      }),
      subTitle: i18n.t("medicationManagementList.lists.list3.description", {
        ns: "counselling",
      }),
      iconUrl: "/images/kidney_educations/blood-pressure.jpg",
    },
  ];

  const emotionalWellBeingListData = [
    {
      title: i18n.t("emotionalWellBeingList.lists.list1.title", {
        ns: "counselling",
      }),
      subTitle: i18n.t("emotionalWellBeingList.lists.list1.description", {
        ns: "counselling",
      }),
      iconUrl: "/images/kidney_educations/waste-removal.jpg",
    },
    {
      title: i18n.t("emotionalWellBeingList.lists.list2.title", {
        ns: "counselling",
      }),
      subTitle: i18n.t("emotionalWellBeingList.lists.list2.description", {
        ns: "counselling",
      }),
      iconUrl: "/images/kidney_educations/electrolyte-balance.jpg",
    },
    {
      title: i18n.t("emotionalWellBeingList.lists.list3.title", {
        ns: "counselling",
      }),
      subTitle: i18n.t("emotionalWellBeingList.lists.list3.description", {
        ns: "counselling",
      }),
      iconUrl: "/images/kidney_educations/blood-pressure.jpg",
    },
  ];

  const motivationalCareListData = [
    {
      title: i18n.t("motivationSelfCareList.lists.list1.title", {
        ns: "counselling",
      }),
      subTitle: i18n.t("motivationSelfCareList.lists.list1.description", {
        ns: "counselling",
      }),
      iconUrl: "/images/kidney_educations/waste-removal.jpg",
    },
    {
      title: i18n.t("motivationSelfCareList.lists.list2.title", {
        ns: "counselling",
      }),
      subTitle: i18n.t("motivationSelfCareList.lists.list2.description", {
        ns: "counselling",
      }),
      iconUrl: "/images/kidney_educations/electrolyte-balance.jpg",
    },
    {
      title: i18n.t("motivationSelfCareList.lists.list3.title", {
        ns: "counselling",
      }),
      subTitle: i18n.t("motivationSelfCareList.lists.list3.description", {
        ns: "counselling",
      }),
      iconUrl: "/images/kidney_educations/blood-pressure.jpg",
    },
  ];

  const kidneyDiseaseCounsellingDatas = [
    {
      title: i18n.t("kidneyDiseaseCounselling.title", {
        ns: "counselling",
      }),
      subTitle: i18n.t("kidneyDiseaseCounselling.description", {
        ns: "counselling",
      }),
      iconUrl: "/images/our_services/counselling/kidney-disease-01.jpg",
    },
    {
      title: i18n.t("medicationManagement.title", {
        ns: "counselling",
      }),
      subTitle: i18n.t("medicationManagement.description", {
        ns: "counselling",
      }),
      iconUrl: "/images/our_services/counselling/medication-02.jpg",
    },
    {
      title: i18n.t("emotionalWellBeing.title", {
        ns: "counselling",
      }),
      subTitle: i18n.t("emotionalWellBeing.description", {
        ns: "counselling",
      }),
      iconUrl: "/images/our_services/counselling/emotional-03.jpg",
    },
    {
      title: i18n.t("motivationSelfCare.title", {
        ns: "counselling",
      }),
      subTitle: i18n.t("motivationSelfCare.description", {
        ns: "counselling",
      }),
      iconUrl: "/images/our_services/counselling/motivation-04.jpg",
    },
  ];

  const specializedSupportForSeniorsData = [
    {
      title: i18n.t("specializedSupportForSeniorsList.lists.list1.title", {
        ns: "counselling",
      }),
      subTitle: i18n.t(
        "specializedSupportForSeniorsList.lists.list1.description",
        {
          ns: "counselling",
        }
      ),
      iconUrl: "/images/our_services/kidney-biopsy-gun/Icon 1-05.svg",
      number: "01",
    },
    {
      title: i18n.t("specializedSupportForSeniorsList.lists.list2.title", {
        ns: "counselling",
      }),
      subTitle: i18n.t(
        "specializedSupportForSeniorsList.lists.list2.description",
        {
          ns: "counselling",
        }
      ),
      iconUrl: "/images/our_services/kidney-biopsy-gun/Icon 1-06.svg",
      number: "02",
    },
    {
      title: i18n.t("specializedSupportForSeniorsList.lists.list3.title", {
        ns: "counselling",
      }),
      subTitle: i18n.t(
        "specializedSupportForSeniorsList.lists.list3.description",
        {
          ns: "counselling",
        }
      ),
      iconUrl: "/images/our_services/kidney-biopsy-gun/Icon 1-07.svg",
      number: "03",
    },
    {
      title: i18n.t("specializedSupportForSeniorsList.lists.list4.title", {
        ns: "counselling",
      }),
      subTitle: i18n.t(
        "specializedSupportForSeniorsList.lists.list4.description",
        {
          ns: "counselling",
        }
      ),
      iconUrl: "/images/our_services/kidney-biopsy-gun/Icon 1-05.svg",
      number: "04",
    },
    {
      title: i18n.t("specializedSupportForSeniorsList.lists.list5.title", {
        ns: "counselling",
      }),
      subTitle: i18n.t(
        "specializedSupportForSeniorsList.lists.list5.description",
        {
          ns: "counselling",
        }
      ),
      iconUrl: "/images/our_services/kidney-biopsy-gun/Icon 1-06.svg",
      number: "05",
    },
    {
      title: i18n.t("specializedSupportForSeniorsList.lists.list6.title", {
        ns: "counselling",
      }),
      subTitle: i18n.t(
        "specializedSupportForSeniorsList.lists.list6.description",
        {
          ns: "counselling",
        }
      ),
      iconUrl: "/images/our_services/kidney-biopsy-gun/Icon 1-07.svg",
      number: "06",
    },
  ];

  const understandingIllData = [
    {
      title: i18n.t("understandingIllList.list1.title", {
        ns: "counselling",
      }),
      subTitle: i18n.t("understandingIllList.list1.description", {
        ns: "counselling",
      }),
      iconUrl: "/images/our_services/counselling/Icon 1-01.svg",
    },
    {
      title: i18n.t("understandingIllList.list2.title", {
        ns: "counselling",
      }),
      subTitle: i18n.t("understandingIllList.list2.description", {
        ns: "counselling",
      }),
      iconUrl: "/images/our_services/counselling/Icon 1-02.svg",
    },
    {
      title: i18n.t("understandingIllList.list3.title", {
        ns: "counselling",
      }),
      subTitle: i18n.t("understandingIllList.list3.description", {
        ns: "counselling",
      }),
      iconUrl: "/images/our_services/counselling/Icon 1-03.svg",
    },
  ];

  const whyCounselling = [
    {
      title: i18n.t("whyCounsellingLists.list1.title", {
        ns: "counselling",
      }),
      subTitle: i18n.t("whyCounsellingLists.list1.description", {
        ns: "counselling",
      }),
      iconUrl: "/images/our_services/counselling/Icon 2-01.svg",
    },
    {
      title: i18n.t("whyCounsellingLists.list2.title", {
        ns: "counselling",
      }),
      subTitle: i18n.t("whyCounsellingLists.list2.description", {
        ns: "counselling",
      }),
      iconUrl: "/images/our_services/counselling/Icon 2-02.svg",
    },
    {
      title: i18n.t("whyCounsellingLists.list3.title", {
        ns: "counselling",
      }),
      subTitle: i18n.t("whyCounsellingLists.list3.description", {
        ns: "counselling",
      }),
      iconUrl: "/images/our_services/counselling/Icon 2-03.svg",
    },
  ];

  const motivationJourneyData = [
    {
      title: i18n.t("motivationJourneyLists.list1.title", {
        ns: "counselling",
      }),
      subTitle: i18n.t("motivationJourneyLists.list1.description", {
        ns: "counselling",
      }),
      iconUrl: "/images/our_services/counselling/Icon 3-01.svg",
    },
    {
      title: i18n.t("motivationJourneyLists.list2.title", {
        ns: "counselling",
      }),
      subTitle: i18n.t("motivationJourneyLists.list2.description", {
        ns: "counselling",
      }),
      iconUrl: "/images/our_services/counselling/Icon 3-02.svg",
    },
    {
      title: i18n.t("motivationJourneyLists.list3.title", {
        ns: "counselling",
      }),
      subTitle: i18n.t("motivationJourneyLists.list3.description", {
        ns: "counselling",
      }),
      iconUrl: "/images/our_services/counselling/Icon 3-03.svg",
    },
  ];

  const selfCareData = [
    {
      title: i18n.t("selfCareLists.list1.title", {
        ns: "counselling",
      }),
      subTitle: i18n.t("selfCareLists.list1.description", {
        ns: "counselling",
      }),
      href: "",
      iconUrl: "/images/our_services/counselling/Icon 2-04.svg",
    },
    {
      title: i18n.t("selfCareLists.list2.title", {
        ns: "counselling",
      }),
      subTitle: i18n.t("selfCareLists.list2.description", {
        ns: "counselling",
      }),
      href: "",
      iconUrl: "/images/our_services/counselling/Icon 2-05.svg",
    },
    {
      title: i18n.t("selfCareLists.list3.title", {
        ns: "counselling",
      }),
      subTitle: i18n.t("selfCareLists.list3.description", {
        ns: "counselling",
      }),
      href: "",
      iconUrl: "/images/our_services/counselling/Icon 2-06.svg",
    },
  ];
  const emotionalValidationData = [
    {
      title: i18n.t("emotionalValidList.list1.title", {
        ns: "counselling",
      }),
      subTitle: i18n.t("emotionalValidList.list1.description", {
        ns: "counselling",
      }),
      iconUrl: "/images/our_services/counselling/Icon 2-07.svg",
    },
    {
      title: i18n.t("emotionalValidList.list2.title", {
        ns: "counselling",
      }),
      subTitle: i18n.t("emotionalValidList.list2.description", {
        ns: "counselling",
      }),
      iconUrl: "/images/our_services/counselling/Icon 2-08.svg",
    },
    {
      title: i18n.t("emotionalValidList.list3.title", {
        ns: "counselling",
      }),
      subTitle: i18n.t("emotionalValidList.list3.description", {
        ns: "counselling",
      }),
      iconUrl: "/images/our_services/counselling/Icon 2-09.svg",
    },
  ];

  const knowledgeEmpowerData = [
    {
      title: i18n.t("knowledgeEmpowerLists.list1.title", {
        ns: "counselling",
      }),
      subTitle: i18n.t("knowledgeEmpowerLists.list1.description", {
        ns: "counselling",
      }),
      href: "",
      iconUrl: "/images/our_services/counselling/Icon 2-10.svg",
    },
    {
      title: i18n.t("knowledgeEmpowerLists.list2.title", {
        ns: "counselling",
      }),
      subTitle: i18n.t("knowledgeEmpowerLists.list2.description", {
        ns: "counselling",
      }),
      href: "",
      iconUrl: "/images/our_services/counselling/Icon 2-11.svg",
    },
    {
      title: i18n.t("knowledgeEmpowerLists.list3.title", {
        ns: "counselling",
      }),
      subTitle: i18n.t("knowledgeEmpowerLists.list3.description", {
        ns: "counselling",
      }),
      href: "",
      iconUrl: "/images/our_services/counselling/Icon 2-12.svg",
    },
  ];

  const improvedCommData = [
    {
      title: i18n.t("improvedCommLists.list1.title", {
        ns: "counselling",
      }),
      subTitle: i18n.t("improvedCommLists.list1.description", {
        ns: "counselling",
      }),
      iconUrl: "/images/our_services/counselling/Icon 2-13.svg",
    },
    {
      title: i18n.t("improvedCommLists.list2.title", {
        ns: "counselling",
      }),
      subTitle: i18n.t("whyCounsellingLists.list2.description", {
        ns: "counselling",
      }),
      iconUrl: "/images/our_services/counselling/Icon 2-14.svg",
    },
    {
      title: i18n.t("improvedCommLists.list3.title", {
        ns: "counselling",
      }),
      subTitle: i18n.t("improvedCommLists.list3.description", {
        ns: "counselling",
      }),
      iconUrl: "/images/our_services/counselling/Icon 2-15.svg",
    },
  ];

  const overAllData = [
    {
      title: i18n.t("overAllLists.list1.title", {
        ns: "counselling",
      }),
      subTitle: i18n.t("overAllLists.list1.description", {
        ns: "counselling",
      }),
      iconUrl: "/images/our_services/counselling/Icon 2-16.svg",
    },
    {
      title: i18n.t("overAllLists.list2.title", {
        ns: "counselling",
      }),
      subTitle: i18n.t("overAllLists.list2.description", {
        ns: "counselling",
      }),
      iconUrl: "/images/our_services/counselling/Icon 2-17.svg",
    },
    {
      title: i18n.t("overAllLists.list3.title", {
        ns: "counselling",
      }),
      subTitle: i18n.t("overAllLists.list3.description", {
        ns: "counselling",
      }),
      iconUrl: "/images/our_services/counselling/Icon 2-18.svg",
    },
  ];

  const ourServiceBlogSectionDatas1 = [
    {
      title: i18n.t(
        "ourSpecializedCounsellingServiceBlogList.lists.list1.title",
        {
          ns: "counselling",
        }
      ),
      description: i18n.t(
        "ourSpecializedCounsellingServiceBlogList.lists.list1.description",
        {
          ns: "counselling",
        }
      ),
      btnRedirectText: "Get Lifeline Support",
      imgURL: "/images/our_services/counselling/last-icon-01.png",
    },
    {
      title: i18n.t(
        "ourSpecializedCounsellingServiceBlogList.lists.list2.title",
        {
          ns: "counselling",
        }
      ),
      description: i18n.t(
        "ourSpecializedCounsellingServiceBlogList.lists.list2.description",
        {
          ns: "counselling",
        }
      ),
      btnRedirectText: "Get Expert Guidance",
      imgURL: "/images/our_services/counselling/img-with-text-icon-02.jpg",
    },
    {
      title: i18n.t(
        "ourSpecializedCounsellingServiceBlogList.lists.list3.title",
        {
          ns: "counselling",
        }
      ),
      description: i18n.t(
        "ourSpecializedCounsellingServiceBlogList.lists.list3.description",
        {
          ns: "counselling",
        }
      ),
      btnRedirectText: "Start Your Journey",
      imgURL: "/images/our_services/counselling/img-with-text-icon-03.jpg",
    },
    {
      title: i18n.t(
        "ourSpecializedCounsellingServiceBlogList.lists.list4.title",
        {
          ns: "counselling",
        }
      ),
      description: i18n.t(
        "ourSpecializedCounsellingServiceBlogList.lists.list4.description",
        {
          ns: "counselling",
        }
      ),
      btnRedirectText: "Get Comprehensive Care",
      imgURL: "/images/our_services/counselling/img-with-text-icon-04.jpg",
    },
  ];

  const blogSectionDatas2 = [
    {
      title: "Knowledge Hub",
      subTitle:
        "Education and clinical tools for the prevention and management of kidney disease...",
      btnText: "Learn More",
      href: "/kidney-education/kidney-diseases",
      socialShare: false,
    },
    {
      title: "Know About Us",
      subTitle:
        "Best affordable kidney medical health and wellness services in INDIA...",
      btnText: "Learn More",
      href: "/our-services/counselling",
      socialShare: false,
    },
    {
      title: "Our Service",
      subTitle:
        "Our research creates knowledge about and treatments for kidney needs that are among...",
      btnText: "Learn More",
      href: "/our-services/counselling",
      socialShare: false,
    },
    {
      title: "Are You in Risk?",
      subTitle:
        "Know your health status up to date for you and your family, take an action today...",
      btnText: "Learn More",
      href: "/contact",
      socialShare: false,
    },
  ];

  return {
    kidneyDiseaseCounsellingListData,
    motivationalCareListData,
    emotionalWellBeingListData,
    medicalManagementListData,
    kidneyDiseaseCounsellingDatas,
    specializedSupportForSeniorsData,
    understandingIllData,
    whyCounselling,
    motivationJourneyData,
    selfCareData,
    emotionalValidationData,
    knowledgeEmpowerData,
    improvedCommData,
    overAllData,
    ourServiceBlogSectionDatas1,
    blogSectionDatas2,
  };
};
