import { Trans } from "react-i18next";
import i18n from "../../../../i18n/i18n";

export const secondBlogContentDatas = () => {
  const whyCathetersAreNecessaryListData = [
    {
      title: i18n.t(
        "blogs.102.innerHTML.whyCathetersAreNecessary.list1.title",
        {
          ns: "cathetersBlogs",
        }
      ),
      description: i18n.t(
        "blogs.102.innerHTML.whyCathetersAreNecessary.list1.description",
        {
          ns: "cathetersBlogs",
        }
      ),
    },
    {
      title: i18n.t(
        "blogs.102.innerHTML.whyCathetersAreNecessary.list2.title",
        {
          ns: "cathetersBlogs",
        }
      ),
      description: i18n.t(
        "blogs.102.innerHTML.whyCathetersAreNecessary.list2.description",
        {
          ns: "cathetersBlogs",
        }
      ),
    },
    {
      title: i18n.t(
        "blogs.102.innerHTML.whyCathetersAreNecessary.list3.title",
        {
          ns: "cathetersBlogs",
        }
      ),
      description: i18n.t(
        "blogs.102.innerHTML.whyCathetersAreNecessary.list3.description",
        {
          ns: "cathetersBlogs",
        }
      ),
    },
  ];

  const typesOfDialysisCathetersListData = [
    {
      title: i18n.t(
        "blogs.102.innerHTML.typesOfDialysisCatheters.nonTunneledCatheters.title",
        {
          ns: "cathetersBlogs",
        }
      ),
      childList: [
        {
          title: i18n.t(
            "blogs.102.innerHTML.typesOfDialysisCatheters.nonTunneledCatheters.list1.title",
            {
              ns: "cathetersBlogs",
            }
          ),
          description: i18n.t(
            "blogs.102.innerHTML.typesOfDialysisCatheters.nonTunneledCatheters.list1.description",
            {
              ns: "cathetersBlogs",
            }
          ),
        },
        {
          title: i18n.t(
            "blogs.102.innerHTML.typesOfDialysisCatheters.nonTunneledCatheters.list2.title",
            {
              ns: "cathetersBlogs",
            }
          ),
          description: i18n.t(
            "blogs.102.innerHTML.typesOfDialysisCatheters.nonTunneledCatheters.list2.description",
            {
              ns: "cathetersBlogs",
            }
          ),
        },
        {
          title: i18n.t(
            "blogs.102.innerHTML.typesOfDialysisCatheters.nonTunneledCatheters.list3.title",
            {
              ns: "cathetersBlogs",
            }
          ),
          description: i18n.t(
            "blogs.102.innerHTML.typesOfDialysisCatheters.nonTunneledCatheters.list3.description",
            {
              ns: "cathetersBlogs",
            }
          ),
        },
      ],
    },
    {
      title: i18n.t(
        "blogs.102.innerHTML.typesOfDialysisCatheters.tunneledCatheters.title",
        {
          ns: "cathetersBlogs",
        }
      ),
      childList: [
        {
          title: i18n.t(
            "blogs.102.innerHTML.typesOfDialysisCatheters.tunneledCatheters.list1.title",
            {
              ns: "cathetersBlogs",
            }
          ),
          description: i18n.t(
            "blogs.102.innerHTML.typesOfDialysisCatheters.tunneledCatheters.list1.description",
            {
              ns: "cathetersBlogs",
            }
          ),
        },
        {
          title: i18n.t(
            "blogs.102.innerHTML.typesOfDialysisCatheters.tunneledCatheters.list2.title",
            {
              ns: "cathetersBlogs",
            }
          ),
          description: i18n.t(
            "blogs.102.innerHTML.typesOfDialysisCatheters.tunneledCatheters.list2.description",
            {
              ns: "cathetersBlogs",
            }
          ),
          subChildList: [
            {
              description: i18n.t(
                "blogs.102.innerHTML.typesOfDialysisCatheters.tunneledCatheters.list2.subLists.list1",
                {
                  ns: "cathetersBlogs",
                }
              ),
            },
            {
              description: i18n.t(
                "blogs.102.innerHTML.typesOfDialysisCatheters.tunneledCatheters.list2.subLists.list2",
                {
                  ns: "cathetersBlogs",
                }
              ),
            },
            {
              description: i18n.t(
                "blogs.102.innerHTML.typesOfDialysisCatheters.tunneledCatheters.list2.subLists.list3",
                {
                  ns: "cathetersBlogs",
                }
              ),
            },
            {
              description: i18n.t(
                "blogs.102.innerHTML.typesOfDialysisCatheters.tunneledCatheters.list2.subLists.list4",
                {
                  ns: "cathetersBlogs",
                }
              ),
            },
          ],
        },
        {
          title: i18n.t(
            "blogs.102.innerHTML.typesOfDialysisCatheters.tunneledCatheters.list3.title",
            {
              ns: "cathetersBlogs",
            }
          ),
          description: i18n.t(
            "blogs.102.innerHTML.typesOfDialysisCatheters.tunneledCatheters.list3.description",
            {
              ns: "cathetersBlogs",
            }
          ),
        },
      ],
    },
  ];

  const placementSitesListData = [
    {
      title: i18n.t("blogs.102.innerHTML.placementSites.list1.title", {
        ns: "cathetersBlogs",
      }),
      description: i18n.t(
        "blogs.102.innerHTML.placementSites.list1.description",
        {
          ns: "cathetersBlogs",
        }
      ),
    },
    {
      title: i18n.t("blogs.102.innerHTML.placementSites.list2.title", {
        ns: "cathetersBlogs",
      }),
      description: i18n.t(
        "blogs.102.innerHTML.placementSites.list2.description",
        {
          ns: "cathetersBlogs",
        }
      ),
    },
    {
      title: i18n.t("blogs.102.innerHTML.placementSites.list3.title", {
        ns: "cathetersBlogs",
      }),
      description: i18n.t(
        "blogs.102.innerHTML.placementSites.list3.description",
        {
          ns: "cathetersBlogs",
        }
      ),
    },
  ];

  const additionalFactorsListData = [
    {
      title: i18n.t("blogs.102.innerHTML.additionalFactors.list1.title", {
        ns: "cathetersBlogs",
      }),
      description: i18n.t(
        "blogs.102.innerHTML.additionalFactors.list1.description",
        {
          ns: "cathetersBlogs",
        }
      ),
    },
    {
      title: i18n.t("blogs.102.innerHTML.additionalFactors.list2.title", {
        ns: "cathetersBlogs",
      }),
      description: i18n.t(
        "blogs.102.innerHTML.additionalFactors.list2.description",
        {
          ns: "cathetersBlogs",
        }
      ),
    },
  ];

  const makingInformedDecisionListData = [
    {
      title: i18n.t("blogs.102.innerHTML.makingInformedDecision.list1.title", {
        ns: "cathetersBlogs",
      }),
      description: i18n.t(
        "blogs.102.innerHTML.makingInformedDecision.list1.description",
        {
          ns: "cathetersBlogs",
        }
      ),
    },
    {
      title: i18n.t("blogs.102.innerHTML.makingInformedDecision.list2.title", {
        ns: "cathetersBlogs",
      }),
      description: i18n.t(
        "blogs.102.innerHTML.makingInformedDecision.list2.description",
        {
          ns: "cathetersBlogs",
        }
      ),
    },
    {
      title: i18n.t("blogs.102.innerHTML.makingInformedDecision.list3.title", {
        ns: "cathetersBlogs",
      }),
      description: i18n.t(
        "blogs.102.innerHTML.makingInformedDecision.list3.description",
        {
          ns: "cathetersBlogs",
        }
      ),
    },
    {
      title: i18n.t("blogs.102.innerHTML.makingInformedDecision.list4.title", {
        ns: "cathetersBlogs",
      }),
      description: i18n.t(
        "blogs.102.innerHTML.makingInformedDecision.list4.description",
        {
          ns: "cathetersBlogs",
        }
      ),
    },
    {
      title: i18n.t("blogs.102.innerHTML.makingInformedDecision.list5.title", {
        ns: "cathetersBlogs",
      }),
      description: i18n.t(
        "blogs.102.innerHTML.makingInformedDecision.list5.description",
        {
          ns: "cathetersBlogs",
        }
      ),
    },
    {
      title: i18n.t("blogs.102.innerHTML.makingInformedDecision.list6.title", {
        ns: "cathetersBlogs",
      }),
      description: i18n.t(
        "blogs.102.innerHTML.makingInformedDecision.list6.description",
        {
          ns: "cathetersBlogs",
        }
      ),
    },
  ];

  const kidneyNeedsYourPartnerListData = [
    {
      title: i18n.t("blogs.102.innerHTML.kidneyNeedsYourPartner.list1.title", {
        ns: "cathetersBlogs",
      }),
      description: i18n.t(
        "blogs.102.innerHTML.kidneyNeedsYourPartner.list1.description",
        {
          ns: "cathetersBlogs",
        }
      ),
    },
    {
      title: i18n.t("blogs.102.innerHTML.kidneyNeedsYourPartner.list2.title", {
        ns: "cathetersBlogs",
      }),
      description: i18n.t(
        "blogs.102.innerHTML.kidneyNeedsYourPartner.list2.description",
        {
          ns: "cathetersBlogs",
        }
      ),
    },
    {
      title: i18n.t("blogs.102.innerHTML.kidneyNeedsYourPartner.list3.title", {
        ns: "cathetersBlogs",
      }),
      description: i18n.t(
        "blogs.102.innerHTML.kidneyNeedsYourPartner.list3.description",
        {
          ns: "cathetersBlogs",
        }
      ),
    },
    {
      title: i18n.t("blogs.102.innerHTML.kidneyNeedsYourPartner.list4.title", {
        ns: "cathetersBlogs",
      }),
      description: i18n.t(
        "blogs.102.innerHTML.kidneyNeedsYourPartner.list4.description",
        {
          ns: "cathetersBlogs",
        }
      ),
    },
    {
      title: i18n.t("blogs.102.innerHTML.kidneyNeedsYourPartner.list5.title", {
        ns: "cathetersBlogs",
      }),
      description: i18n.t(
        "blogs.102.innerHTML.kidneyNeedsYourPartner.list5.description",
        {
          ns: "cathetersBlogs",
        }
      ),
    },
  ];

  const addressingMisconceptionsListData = [
    {
      title: (
        <Trans
          i18nKey={i18n.t(
            "blogs.102.innerHTML.addressingMisconceptions.list1.title",
            {
              ns: "cathetersBlogs",
            }
          )}
          components={{
            dQuotes: <q />,
          }}
        />
      ),
      description: (
        <Trans
          i18nKey={i18n.t(
            "blogs.102.innerHTML.addressingMisconceptions.list1.description",
            {
              ns: "cathetersBlogs",
            }
          )}
          components={{
            dQuotes: <q />,
          }}
        />
      ),
    },
    {
      title: i18n.t(
        "blogs.102.innerHTML.addressingMisconceptions.list2.title",
        {
          ns: "cathetersBlogs",
        }
      ),
      description: i18n.t(
        "blogs.102.innerHTML.addressingMisconceptions.list2.description",
        {
          ns: "cathetersBlogs",
        }
      ),
    },
  ];

  return {
    whyCathetersAreNecessaryListData,
    typesOfDialysisCathetersListData,
    placementSitesListData,
    additionalFactorsListData,
    makingInformedDecisionListData,
    kidneyNeedsYourPartnerListData,
    addressingMisconceptionsListData,
  };
};
