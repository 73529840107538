export const appointmentTemplate = (input) => {
    return `<!DOCTYPE html>
  <html lang="en">
  <head>
      <meta charset="UTF-8">
      <meta name="viewport" content="width=device-width, initial-scale=1.0">
      <title>Appointment request</title>
  </head>
  <body>
      <h2>Appointment request</h2>
      <p><strong>Name:</strong> ${input.name}</p>
      <p><strong>Phone number:</strong> ${input.phoneNumber}</p>
      <p><strong>Preferred Date:</strong> ${input.preferredDate}</p>
      <p><strong>Preferred Time:</strong> ${input.preferredTime}</p>
      <p><strong>Reason for Visit:</strong> ${input.reason}</p>
  </body>
  </html>`;
  };
  