import React from "react";
import { Link } from "react-router-dom";
import Section from "../Section";
import Breadcrumb from "../Breadcrumb";
import { Icon } from "@iconify/react";
import Spacing from "../Spacing";
import Sidebar from "../Sidebar";
import { pageTitle } from "../../helpers/PageTitle";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
// import BannerSectionStyle9 from '../Section/BannerSection/BannerSectionStyle9';
// import Post from '../Post';
// import AuthorWidget from '../Widget/AuthorWidget';
// import CommentsWidget from '../Widget/CommentsWidget';
// import ReplyWidget from '../Widget/ReplyWidget';

// const relatedBlog = [
//   {
//     title:
//       'The Importance of Mental Health: Understanding and Managing Anxiety Disorders',
//     thumbUrl: '/images/blog/post_1.jpeg',
//     date: 'March 12',
//     btnText: 'Learn More',
//     href: '/blog/blog-details',
//   },
//   {
//     title: `A Parent's Guide to Childhood Vaccinations: What You Need to Know`,
//     thumbUrl: '/images/blog/post_2.jpeg',
//     date: 'March 11',
//     btnText: 'Learn More',
//     href: '/blog/blog-details',
//   },
//   {
//     title: 'Preventing Heart Disease: Tips for a Heart-Healthy Lifestyle',
//     thumbUrl: '/images/blog/post_3.jpeg',
//     date: 'March 9',
//     btnText: 'Learn More',
//     href: '/blog/blog-details',
//   },
// ];

export default function BlogDetails(props) {
  pageTitle("Blog Details");

  const { t } = useTranslation(["blogData"]);
  let { blogId } = useParams();

  if (
    blogId === "104" ||
    blogId === "107" ||
    blogId === "110" ||
    blogId === "113" ||
    blogId === "116"
  )
    blogId = "101";

  if (
    blogId === "105" ||
    blogId === "108" ||
    blogId === "111" ||
    blogId === "114" ||
    blogId === "117"
  )
    blogId = "102";

  if (
    blogId === "106" ||
    blogId === "109" ||
    blogId === "112" ||
    blogId === "115" ||
    blogId === "118"
  )
    blogId = "103";

  return (
    <>
      <Section topMd={170} bottomMd={54} bottomLg={54}>
        <Breadcrumb title={t(`blogs.${blogId}.blogHeading`)} />
      </Section>
      <div className="container">
        <div className="cs_blog_details_info">
          <div className="cs_blog_details_info_left">
            <div className="cs_blog_details_tags">
              {t(`blogs.${blogId}.tags`).map((item, index) => (
                <Link key={index} to={item.href}>
                  {item.tag}
                </Link>
              ))}
            </div>
            <div className="cs_blog_details_date">
              {t(`blogs.${blogId}.date`)} | {t(`blogs.${blogId}.author`)}
            </div>
          </div>
          <div className="cs_social_links_wrap">
            <h2>Share:</h2>
            <div className="cs_social_links">
              <Link to="/">
                <Icon icon="fa-brands:facebook-f" />
              </Link>
              <Link to="/">
                <Icon icon="fa-brands:linkedin-in" />
              </Link>
              <Link to="/">
                <Icon icon="fa-brands:twitter" />
              </Link>
            </div>
          </div>
        </div>
        <Spacing md="55" />
        <img
          src={t(`blogs.${blogId}.image`)}
          alt="Blog Details"
          className="w-100 cs_radius_20"
        />
        <Spacing md="90" lg="50" />
        <div className="row">
          <div className="col-lg-8">
            <div>
              <div
                className="cs_blog_details"
                dangerouslySetInnerHTML={{
                  __html: t(`blogs.${blogId}.innerHTML`),
                }}
              ></div>
            </div>
            <Spacing md="85" />
            {/* <AuthorWidget
              imgUrl="/images/blog/author.png"
              name="Author Bio"
              description="John Smith is a freelance writer and content strategist with a passion for helping businesses tell their stories. With over 10 years of experience in the industry, John has worked with a wide range of clients, from startups to Fortune 500 companies. He holds a Bachelor's degree in English from the University of California, Los Angeles (UCLA), and is an avid reader and traveler in his free time. Follow him on Twitter @johnsmithwriter for the latest updates on his work."
            /> */}
            {/* <Spacing md="110" /> */}
            {/* <CommentsWidget title="Comments" /> */}
            {/* <Spacing md="92" />
            <ReplyWidget title="Leave a Reply" /> */}
          </div>
          <div className="col-lg-4">
            <Sidebar />
          </div>
        </div>
        {/* <Spacing md="135" lg="100" />
        <h2 className="mb-0 cs_fs_40 cs_medium">Related Articles</h2>
        <Spacing md="57" /> */}
        {/* <div className="row cs_gap_y_40">
          {relatedBlog?.map((item, index) => (
            <div className="col-xl-4 col-md-6" key={index}>
              <Post {...item} />
            </div>
          ))}
        </div> */}
      </div>
      {/* <Spacing md="200" xl="150" lg="110" /> */}
      {/* <Section className="cs_footer_margin_0">
        <BannerSectionStyle9
          title="Don’t Let Your Health <br />Take a Backseat!"
          subTitle="Schedule an appointment with one of our experienced <br />medical professionals today!"
          imgUrl="/images/doctors/banner_img_3.png"
        />
      </Section> */}
    </>
  );
}
