import React from "react";
import parse from "html-react-parser";
import Button from "../../Button";
import Spacing from "../../Spacing";

export default function KidneyEducationBannerStyle({
  bgUrl,
  imgUrl,
  title,
  subHeadTitle = "",
  subTitle,
  subTitle2 = "",
  btnText,
  btnUrl,
  customeThemeClass = "",
}) {
  return (
    <section
      className="cs_banner cs_style_3 cs_bg_filed cs_kidney_education_banner cs_kidney_needs_banner"
      style={{ backgroundImage: `url(${bgUrl})` }}
    >
      {imgUrl && (
        <div className="cs_banner_img">
          <img loading="lazy" src={imgUrl} alt="Banner" className="cs_main_banner_img" />
        </div>
      )}
      <div className="container">
        <div className="cs_banner_text">
          <h2 className="cs_banner_title cs_fs_60">{parse(title)}</h2>
          {subHeadTitle && (
            <h3 className={`cs_hero_title cs_fs_48 ${customeThemeClass}`}>
              {subHeadTitle}
            </h3>
          )}
          <p className="cs_banner_subtitle cs_fs_20 mb-0 cs_heading_color">
            {parse(subTitle)}
          </p>
          {subTitle2 && (
            <p className="cs_hero_subtitle cs_fs_20 cs_heading_color">
              {parse(subTitle2)}
            </p>
          )}
          {btnText && (
            <>
              <Spacing md="25" lg="25" xl="25" />
              <Button btnUrl={btnUrl} btnText={btnText} />
            </>
          )}
        </div>
      </div>
    </section>
  );
}
