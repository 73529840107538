import { Icon } from "@iconify/react";
import React from "react";
import { useTranslation } from "react-i18next";
import { Link, useParams } from "react-router-dom";

const BlogDetailInfoSection = ({ jsonFileName }) => {
  const { t } = useTranslation([jsonFileName]);
  let { blogId } = useParams();

  if (
    blogId === "104" ||
    blogId === "107" ||
    blogId === "110" ||
    blogId === "113" ||
    blogId === "116"
  )
    blogId = "101";

  if (
    blogId === "105" ||
    blogId === "108" ||
    blogId === "111" ||
    blogId === "114" ||
    blogId === "117"
  )
    blogId = "102";

  if (
    blogId === "106" ||
    blogId === "109" ||
    blogId === "112" ||
    blogId === "115" ||
    blogId === "118"
  )
    blogId = "103";

  return (
    <div className="cs_blog_details_info">
      <div className="cs_blog_details_info_left">
        <div className="cs_blog_details_tags">
          {t(`blogs.${blogId}.tags`).map((item, index) => (
            <Link key={index} to={item.href}>
              {item.tag}
            </Link>
          ))}
        </div>
        <div className="cs_blog_details_date">
          {t(`blogs.${blogId}.date`)} | {t(`blogs.${blogId}.author`)}
        </div>
      </div>
      <div className="cs_social_links_wrap">
        <h2>Share:</h2>
        <div className="cs_social_links">
          <Link to="/">
            <Icon icon="fa-brands:facebook-f" />
          </Link>
          <Link to="/">
            <Icon icon="fa-brands:linkedin-in" />
          </Link>
          <Link to="/">
            <Icon icon="fa-brands:twitter" />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default BlogDetailInfoSection;
