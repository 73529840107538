import React from "react";
import { useTranslation } from "react-i18next";
import Spacing from "../../../../components/Spacing";
import BlogOrderedList from "../../../Blogs/BlogComponents/BlogOrderedList";
import { secondBlogContentDatas } from "./secondBlogContents";
import BlogList from "../../../Blogs/BlogComponents/BlogList";

const SecondBlog = () => {
  const { t } = useTranslation(["dialysisAccessoriesBlog"]);
  const {
    comprehensiveDialysisSolutionsListData,
    addressingSpecificNeedsListData,
    goingTheExtraMileListData,
    beyondProductsListData,
  } = secondBlogContentDatas();

  return (
    <>
      <p>{t(`blogs.102.innerHTML.blogDescription`)}</p>

      <Spacing md="20" lg="20" />
      <h2>{t(`blogs.102.innerHTML.comprehensiveDialysisSolutions.title`)}</h2>
      <div className="cs_blog_ord_list">
        <BlogOrderedList data={comprehensiveDialysisSolutionsListData} />
      </div>

      <Spacing md="10" lg="10" />
      <h2>{t(`blogs.102.innerHTML.addressingSpecificNeeds.title`)}</h2>
      <div className="cs_blog_un_ord_list">
        <BlogList data={addressingSpecificNeedsListData} />
      </div>

      <Spacing md="30" lg="30" />
      <h2>{t(`blogs.102.innerHTML.goingTheExtraMile.title`)}</h2>
      <div className="cs_blog_un_ord_list">
        <BlogList data={goingTheExtraMileListData} />
      </div>

      <Spacing md="30" lg="30" />
      <h2>{t(`blogs.102.innerHTML.affordability.title`)}</h2>
      <p>{t(`blogs.102.innerHTML.affordability.description`)}</p>

      <Spacing md="30" lg="30" />
      <h2>{t(`blogs.102.innerHTML.beyondProducts.title`)}</h2>
      <p>{t(`blogs.102.innerHTML.beyondProducts.description`)}</p>
      <div className="cs_blog_un_ord_list">
        <BlogList data={beyondProductsListData} />
      </div>

      <Spacing md="30" lg="30" />
      <h2>{t(`blogs.102.innerHTML.kidneyNeedsYourTrustedPartner.title`)}</h2>
      <p>
        {t(
          `blogs.102.innerHTML.kidneyNeedsYourTrustedPartner.description.para1`
        )}
      </p>
      <p>
        {t(
          `blogs.102.innerHTML.kidneyNeedsYourTrustedPartner.description.para2`
        )}
      </p>

      <Spacing md="30" lg="30" />
      <h2>{t(`blogs.102.innerHTML.disclaimer.title`)}</h2>
      <p>{t(`blogs.102.innerHTML.disclaimer.description`)}</p>

      <Spacing md="30" lg="30" />
      <h3>{t(`blogs.102.innerHTML.notes`)}</h3>
    </>
  );
};

export default SecondBlog;
