import React from "react";
import RecentPostWidget from "../../components/Widget/RecentPostWidget";
import NewsletterStyle5 from "../../components/Widget/NewsletterStyle5";

const BlogSidebar = ({ blogDatas }) => {
  return (
    <div className="cs_sidebar">
      {/* <div className="cs_sidebar_item widget_categories">
        <SideMenuWidget title="Popular Categories" data={categoryData} />
      </div> */}
      <div className="cs_sidebar_item">
        <RecentPostWidget
          title="Popular Articles"
          data={blogDatas().slice(0, 5)}
        />
      </div>
      <div className="cs_sidebar_item widget_categories">
        <NewsletterStyle5 title="Newsletter Sign Up Form" />
      </div>
    </div>
  );
};

export default BlogSidebar;
