import { Trans } from "react-i18next";
import i18n from "../../../i18n/i18n";

export const medicinesContentDatas = () => {
  const importantDescNotesData = [
    {
      title: i18n.t("importantDescNotes.para1", {
        ns: "medicines",
      }),
    },
    {
      title: i18n.t("importantDescNotes.para2", {
        ns: "medicines",
      }),
    },
    {
      title: i18n.t("importantDescNotes.para3", {
        ns: "medicines",
      }),
    },
    {
      title: i18n.t("importantDescNotes.para4", {
        ns: "medicines",
      }),
    },
    {
      title: i18n.t("importantDescNotes.para5", {
        ns: "medicines",
      }),
    },
    {
      title: (
        <Trans
          i18nKey={i18n.t("importantDescNotes.para6", {
            ns: "medicines",
          })}
          components={{
            styledFont: <i />,
          }}
        />
      ),
    },
  ];

  const howAntihypertensivesBenefitYourKidneysData = [
    {
      title: i18n.t(
        "howAntihypertensivesBenefitYourKidneys.lists.list1.title",
        {
          ns: "medicines",
        }
      ),
      subTitle: i18n.t(
        "howAntihypertensivesBenefitYourKidneys.lists.list1.description",
        {
          ns: "medicines",
        }
      ),
      href: "#",
      iconUrl: "/images/kidney_educations/medicines/Icon 1-01.svg",
    },
    {
      title: i18n.t(
        "howAntihypertensivesBenefitYourKidneys.lists.list2.title",
        {
          ns: "medicines",
        }
      ),
      subTitle: i18n.t(
        "howAntihypertensivesBenefitYourKidneys.lists.list2.description",
        {
          ns: "medicines",
        }
      ),
      href: "#",
      iconUrl: "/images/kidney_educations/medicines/Icon 1-02.svg",
    },
  ];

  const typesOfAntihypertensivesData1 = [
    {
      title: i18n.t(
        "typesOfAntihypertensivesLists.list1.subLists.list1.title",
        {
          ns: "medicines",
        }
      ),
      subTitle: i18n.t(
        "typesOfAntihypertensivesLists.list1.subLists.list1.description",
        {
          ns: "medicines",
        }
      ),
    },
  ];

  const typesOfAntihypertensivesData2 = [
    {
      title: i18n.t(
        "typesOfAntihypertensivesLists.list2.subLists.list1.title",
        {
          ns: "medicines",
        }
      ),
      subTitle: i18n.t(
        "typesOfAntihypertensivesLists.list2.subLists.list1.description",
        {
          ns: "medicines",
        }
      ),
    },
  ];

  const typesOfAntihypertensivesData3 = [
    {
      title: i18n.t(
        "typesOfAntihypertensivesLists.list3.subLists.list1.title",
        {
          ns: "medicines",
        }
      ),
      subTitle: i18n.t(
        "typesOfAntihypertensivesLists.list3.subLists.list1.description",
        {
          ns: "medicines",
        }
      ),
    },
  ];

  const typesOfAntihypertensivesData4 = [
    {
      title: i18n.t(
        "typesOfAntihypertensivesLists.list4.subLists.list1.title",
        {
          ns: "medicines",
        }
      ),
      subTitle: i18n.t(
        "typesOfAntihypertensivesLists.list4.subLists.list1.description",
        {
          ns: "medicines",
        }
      ),
    },
  ];

  const typesOfAntihypertensivesData5 = [
    {
      title: i18n.t(
        "typesOfAntihypertensivesLists.list5.subLists.list1.title",
        {
          ns: "medicines",
        }
      ),
      subTitle: i18n.t(
        "typesOfAntihypertensivesLists.list5.subLists.list1.description",
        {
          ns: "medicines",
        }
      ),
    },
  ];

  const whySecondLineMedicationsData = [
    {
      title: i18n.t("whySecondLineMedicationsLists.list1.title", {
        ns: "medicines",
      }),
      subTitle: i18n.t("whySecondLineMedicationsLists.list1.description", {
        ns: "medicines",
      }),
      iconUrl: "/images/kidney_educations/medicines/Icon 1-03.svg",
    },
    {
      title: i18n.t("whySecondLineMedicationsLists.list2.title", {
        ns: "medicines",
      }),
      subTitle: i18n.t("whySecondLineMedicationsLists.list2.description", {
        ns: "medicines",
      }),
      iconUrl: "/images/kidney_educations/medicines/Icon 1-04.svg",
    },
  ];

  const ckdMedicationSubData1 = [
    {
      title: i18n.t("commonSecondLineMedicationsCKD.lists.list1.subTitle", {
        ns: "medicines",
      }),
      subTitle: i18n.t(
        "commonSecondLineMedicationsCKD.lists.list1.subDescription",
        {
          ns: "medicines",
        }
      ),
    },
  ];

  const ckdMedicationSubData4 = [
    {
      title: i18n.t("commonSecondLineMedicationsCKD.lists.list4.subTitle", {
        ns: "medicines",
      }),
      subTitle: i18n.t(
        "commonSecondLineMedicationsCKD.lists.list4.subDescription",
        {
          ns: "medicines",
        }
      ),
    },
  ];

  const typesOfInsulinData = [
    {
      title: i18n.t("typesOfInsulin.lists.list1.title", {
        ns: "medicines",
      }),
      subTitle: i18n.t("typesOfInsulin.lists.list1.description", {
        ns: "medicines",
      }),
      iconUrl: "/images/kidney_educations/medicines/Icon 1-05.svg",
    },
    {
      title: i18n.t("typesOfInsulin.lists.list2.title", {
        ns: "medicines",
      }),
      subTitle: i18n.t("typesOfInsulin.lists.list2.description", {
        ns: "medicines",
      }),
      iconUrl: "/images/kidney_educations/medicines/Icon 1-06.svg",
    },
    {
      title: i18n.t("typesOfInsulin.lists.list3.title", {
        ns: "medicines",
      }),
      subTitle: i18n.t("typesOfInsulin.lists.list3.description", {
        ns: "medicines",
      }),
      iconUrl: "/images/kidney_educations/medicines/Icon 1-07.svg",
    },
    {
      title: i18n.t("typesOfInsulin.lists.list4.title", {
        ns: "medicines",
      }),
      subTitle: i18n.t("typesOfInsulin.lists.list4.description", {
        ns: "medicines",
      }),
      iconUrl: "/images/kidney_educations/medicines/Icon 1-08.svg",
    },
  ];

  const metforminData = [
    {
      subTitle: i18n.t("metformin.lists.list1.description", {
        ns: "medicines",
      }),
    },
    {
      subTitle: i18n.t("metformin.lists.list2.description", {
        ns: "medicines",
      }),
    },
    {
      subTitle: i18n.t("metformin.lists.list3.description", {
        ns: "medicines",
      }),
    },
    {
      subTitle: (
        <Trans
          i18nKey={i18n.t("metformin.lists.list4.description", {
            ns: "medicines",
          })}
          components={{
            boldText: <b />,
          }}
        />
      ),
    },
  ];

  const sulfonylureasData = [
    {
      subTitle: i18n.t("sulfonylureas.lists.list1.description", {
        ns: "medicines",
      }),
    },
    {
      subTitle: i18n.t("sulfonylureas.lists.list2.description", {
        ns: "medicines",
      }),
    },
    {
      subTitle: i18n.t("sulfonylureas.lists.list3.description", {
        ns: "medicines",
      }),
    },
    {
      subTitle: (
        <Trans
          i18nKey={i18n.t("sulfonylureas.lists.list4.description", {
            ns: "medicines",
          })}
          components={{
            boldText: <b />,
          }}
        />
      ),
    },
  ];

  const inhibitorsData = [
    {
      subTitle: i18n.t("inhibitors.lists.list1.description", {
        ns: "medicines",
      }),
    },
    {
      subTitle: i18n.t("inhibitors.lists.list2.description", {
        ns: "medicines",
      }),
    },
    {
      subTitle: i18n.t("inhibitors.lists.list3.description", {
        ns: "medicines",
      }),
    },
    {
      subTitle: (
        <Trans
          i18nKey={i18n.t("inhibitors.lists.list4.description", {
            ns: "medicines",
          })}
          components={{
            boldText: <b />,
          }}
        />
      ),
    },
  ];

  const receptorAgonistsData = [
    {
      subTitle: i18n.t("receptorAgonists.lists.list1.description", {
        ns: "medicines",
      }),
    },
    {
      subTitle: i18n.t("receptorAgonists.lists.list2.description", {
        ns: "medicines",
      }),
    },
    {
      subTitle: i18n.t("receptorAgonists.lists.list3.description", {
        ns: "medicines",
      }),
    },
    {
      subTitle: (
        <Trans
          i18nKey={i18n.t("receptorAgonists.lists.list4.description", {
            ns: "medicines",
          })}
          components={{
            boldText: <b />,
          }}
        />
      ),
    },
  ];

  const anemiaManagementData = [
    {
      title: i18n.t("anemiaManagementLists.list1.title", {
        ns: "medicines",
      }),
      subTitle: i18n.t("anemiaManagementLists.list1.description", {
        ns: "medicines",
      }),
      iconUrl: "/images/kidney_educations/medicines/Icon 1-09.svg",
    },
    {
      title: i18n.t("anemiaManagementLists.list2.title", {
        ns: "medicines",
      }),
      subTitle: i18n.t("anemiaManagementLists.list2.description", {
        ns: "medicines",
      }),
      iconUrl: "/images/kidney_educations/medicines/Icon 1-10.svg",
    },
  ];

  const chronicKidneyDiseaseMineralBoneDisorderData = [
    {
      title: i18n.t(
        "chronicKidneyDiseaseMineralBoneDisorderLists.list1.title",
        {
          ns: "medicines",
        }
      ),
      subTitle: i18n.t(
        "chronicKidneyDiseaseMineralBoneDisorderLists.list1.description",
        {
          ns: "medicines",
        }
      ),
      iconUrl: "/images/kidney_educations/medicines/Icon 1-11.svg",
    },
    {
      title: i18n.t(
        "chronicKidneyDiseaseMineralBoneDisorderLists.list2.title",
        {
          ns: "medicines",
        }
      ),
      subTitle: i18n.t(
        "chronicKidneyDiseaseMineralBoneDisorderLists.list2.description",
        {
          ns: "medicines",
        }
      ),
      iconUrl: "/images/kidney_educations/medicines/Icon 1-12.svg",
    },
    {
      title: i18n.t(
        "chronicKidneyDiseaseMineralBoneDisorderLists.list3.title",
        {
          ns: "medicines",
        }
      ),
      subTitle: i18n.t(
        "chronicKidneyDiseaseMineralBoneDisorderLists.list3.description",
        {
          ns: "medicines",
        }
      ),
      iconUrl: "/images/kidney_educations/medicines/Icon 1-13.svg",
    },
  ];

  return {
    importantDescNotesData,
    howAntihypertensivesBenefitYourKidneysData,
    typesOfAntihypertensivesData1,
    typesOfAntihypertensivesData2,
    typesOfAntihypertensivesData3,
    typesOfAntihypertensivesData4,
    typesOfAntihypertensivesData5,
    whySecondLineMedicationsData,
    ckdMedicationSubData1,
    ckdMedicationSubData4,
    typesOfInsulinData,
    metforminData,
    sulfonylureasData,
    inhibitorsData,
    receptorAgonistsData,
    anemiaManagementData,
    chronicKidneyDiseaseMineralBoneDisorderData,
  };
};
