import i18n from "../../../i18n/i18n";

export const kidneyBiopsyContentDatas = () => {
  const whyKidneyBiopsyPerformedData = [
    {
      title: i18n.t("whyKidneyBiopsyPerformedLists.list1.title", {
        ns: "kidneyBiopsy",
      }),
      subTitle: i18n.t("whyKidneyBiopsyPerformedLists.list1.description", {
        ns: "kidneyBiopsy",
      }),
      iconUrl: "/images/kidney_educations/kidney-biopsy/Icon 1-01.svg",
    },
    {
      title: i18n.t("whyKidneyBiopsyPerformedLists.list2.title", {
        ns: "kidneyBiopsy",
      }),
      subTitle: i18n.t("whyKidneyBiopsyPerformedLists.list2.description", {
        ns: "kidneyBiopsy",
      }),
      iconUrl: "/images/kidney_educations/kidney-biopsy/Icon 2-01.svg",
    },
    {
      title: i18n.t("whyKidneyBiopsyPerformedLists.list3.title", {
        ns: "kidneyBiopsy",
      }),
      subTitle: i18n.t("whyKidneyBiopsyPerformedLists.list3.description", {
        ns: "kidneyBiopsy",
      }),
      iconUrl: "/images/kidney_educations/kidney-biopsy/Icon 3-01.svg",
    },
    {
      title: i18n.t("whyKidneyBiopsyPerformedLists.list4.title", {
        ns: "kidneyBiopsy",
      }),
      subTitle: i18n.t("whyKidneyBiopsyPerformedLists.list4.description", {
        ns: "kidneyBiopsy",
      }),
      iconUrl: "/images/kidney_educations/kidney-biopsy/Icon 4-01.svg",
    },
  ];

  const typesOfKidneyBiopsiesData1 = [
    {
      title: i18n.t("typesOfKidneyBiopsiesLists.list1.subLists.list1.title", {
        ns: "kidneyBiopsy",
      }),
      subTitle: i18n.t(
        "typesOfKidneyBiopsiesLists.list1.subLists.list1.description",
        {
          ns: "kidneyBiopsy",
        }
      ),
    },
    {
      title: i18n.t("typesOfKidneyBiopsiesLists.list1.subLists.list2.title", {
        ns: "kidneyBiopsy",
      }),
      subTitle: i18n.t(
        "typesOfKidneyBiopsiesLists.list1.subLists.list2.description",
        {
          ns: "kidneyBiopsy",
        }
      ),
    },
    {
      title: i18n.t("typesOfKidneyBiopsiesLists.list1.subLists.list3.title", {
        ns: "kidneyBiopsy",
      }),
      subTitle: i18n.t(
        "typesOfKidneyBiopsiesLists.list1.subLists.list3.description",
        {
          ns: "kidneyBiopsy",
        }
      ),
    },
  ];

  return { whyKidneyBiopsyPerformedData, typesOfKidneyBiopsiesData1 };
};
