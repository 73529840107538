import React from "react";
import ImageWithTextOverlay from "./ImageWithTextOverlay";

const ImageWithTextOverlaySection = ({ imageWithTextData = [] }) => {
  return (
    <>
      <div className="container">
        <div className="row gy-4">
          {imageWithTextData?.map((item, index) => (
            <div className="col-lg-3 col-md-6" key={index}>
              <ImageWithTextOverlay {...item} />
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default ImageWithTextOverlaySection;
