import { Icon } from "@iconify/react";
import React from "react";

export default function ContactInfoWidget({ iconColor = "" }) {
  return (
    <ul className="cs_contact_widget">
      <li>
        <i className="cs_white_bg">
          <Icon
            icon="ep:location"
            style={{ color: iconColor ? iconColor : "" }}
          />
        </i>
        Khutani Bhimtal, Nainital, Uttarakhand - 263136
      </li>
      <li>
        <i className="cs_white_bg">
          <Icon
            icon="fluent:call-24-regular"
            style={{ color: iconColor ? iconColor : "" }}
          />
        </i>
        +91 91116 06162
      </li>
      <li>
        <i className="cs_white_bg">
          <Icon
            icon="bi:envelope"
            style={{ color: iconColor ? iconColor : "" }}
          />
        </i>
        support@kidneyneeds.com
      </li>
    </ul>
  );
}
