import React from "react";
import { useTranslation } from "react-i18next";
import Spacing from "../../../../components/Spacing";

const SecondBlog = () => {
  const { t } = useTranslation(["kidneyBiopsyBlogs"]);

  return (
    <>
      <p>{t(`blogs.102.innerHTML.blogDescription`)}</p>

      <Spacing md="20" lg="20" />
      <h2>{t(`blogs.102.innerHTML.criticalityInstrumentQuality.title`)}</h2>
      <p>{t(`blogs.102.innerHTML.criticalityInstrumentQuality.description`)}</p>

      <Spacing md="20" lg="20" />
      <h2>
        {t(`blogs.102.innerHTML.prioritizingPatientWellbeingAndSafety.title`)}
      </h2>
      <p>
        {t(
          `blogs.102.innerHTML.prioritizingPatientWellbeingAndSafety.description`
        )}
      </p>

      <Spacing md="20" lg="20" />
      <h2>{t(`blogs.102.innerHTML.efficiencyAndCostEffectiveness.title`)}</h2>
      <p>
        {t(`blogs.102.innerHTML.efficiencyAndCostEffectiveness.description`)}
      </p>

      <Spacing md="20" lg="20" />
      <h2>{t(`blogs.102.innerHTML.kidneyNeedsYourTrustedPartner.title`)}</h2>
      <p>
        {t(`blogs.102.innerHTML.kidneyNeedsYourTrustedPartner.description`)}
      </p>

      <Spacing md="20" lg="20" />
      <h2>{t(`blogs.102.innerHTML.featuredProduct.title`)}</h2>
      <p>{t(`blogs.102.innerHTML.featuredProduct.description.para1`)}</p>
      <p>{t(`blogs.102.innerHTML.featuredProduct.description.para2`)}</p>
      <p>{t(`blogs.102.innerHTML.featuredProduct.description.para3`)}</p>
      <p>{t(`blogs.102.innerHTML.featuredProduct.description.para4`)}</p>

      <Spacing md="20" lg="20" />
      <h2>{t(`blogs.102.innerHTML.disclaimer.title`)}</h2>
      <p>{t(`blogs.102.innerHTML.disclaimer.description`)}</p>
    </>
  );
};

export default SecondBlog;
