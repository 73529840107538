import i18n from "../../../../i18n/i18n";

export const firstBlogContentDatas = () => {
  const preBiopsyTestsListData = [
    {
      title: i18n.t("blogs.101.innerHTML.preBiopsyTests.list1.title", {
        ns: "kidneyBiopsyBlogs",
      }),
      description: i18n.t(
        "blogs.101.innerHTML.preBiopsyTests.list1.description",
        {
          ns: "kidneyBiopsyBlogs",
        }
      ),
    },
    {
      title: i18n.t("blogs.101.innerHTML.preBiopsyTests.list2.title", {
        ns: "kidneyBiopsyBlogs",
      }),
      description: i18n.t(
        "blogs.101.innerHTML.preBiopsyTests.list2.description",
        {
          ns: "kidneyBiopsyBlogs",
        }
      ),
    },
    {
      title: i18n.t("blogs.101.innerHTML.preBiopsyTests.list3.title", {
        ns: "kidneyBiopsyBlogs",
      }),
      description: i18n.t(
        "blogs.101.innerHTML.preBiopsyTests.list3.description",
        {
          ns: "kidneyBiopsyBlogs",
        }
      ),
    },
    {
      title: i18n.t("blogs.101.innerHTML.preBiopsyTests.list4.title", {
        ns: "kidneyBiopsyBlogs",
      }),
      description: i18n.t(
        "blogs.101.innerHTML.preBiopsyTests.list4.description",
        {
          ns: "kidneyBiopsyBlogs",
        }
      ),
    },
    {
      title: i18n.t("blogs.101.innerHTML.preBiopsyTests.list5.title", {
        ns: "kidneyBiopsyBlogs",
      }),
      description: i18n.t(
        "blogs.101.innerHTML.preBiopsyTests.list5.description",
        {
          ns: "kidneyBiopsyBlogs",
        }
      ),
      childList: [
        {
          title: i18n.t(
            "blogs.101.innerHTML.preBiopsyTests.list5.subLists.list1.title",
            {
              ns: "kidneyBiopsyBlogs",
            }
          ),
          description: i18n.t(
            "blogs.101.innerHTML.preBiopsyTests.list5.subLists.list1.description",
            {
              ns: "kidneyBiopsyBlogs",
            }
          ),
        },
        {
          title: i18n.t(
            "blogs.101.innerHTML.preBiopsyTests.list5.subLists.list2.title",
            {
              ns: "kidneyBiopsyBlogs",
            }
          ),
          description: i18n.t(
            "blogs.101.innerHTML.preBiopsyTests.list5.subLists.list2.description",
            {
              ns: "kidneyBiopsyBlogs",
            }
          ),
        },
        {
          title: i18n.t(
            "blogs.101.innerHTML.preBiopsyTests.list5.subLists.list3.title",
            {
              ns: "kidneyBiopsyBlogs",
            }
          ),
          description: i18n.t(
            "blogs.101.innerHTML.preBiopsyTests.list5.subLists.list3.description",
            {
              ns: "kidneyBiopsyBlogs",
            }
          ),
        },
        {
          title: i18n.t(
            "blogs.101.innerHTML.preBiopsyTests.list5.subLists.list4.title",
            {
              ns: "kidneyBiopsyBlogs",
            }
          ),
          description: i18n.t(
            "blogs.101.innerHTML.preBiopsyTests.list5.subLists.list4.description",
            {
              ns: "kidneyBiopsyBlogs",
            }
          ),
        },
      ],
    },
  ];

  const analyzingBiopsySampleListData = [
    {
      title: i18n.t("blogs.101.innerHTML.analyzingBiopsySample.list1.title", {
        ns: "kidneyBiopsyBlogs",
      }),
      description: i18n.t(
        "blogs.101.innerHTML.analyzingBiopsySample.list1.description",
        {
          ns: "kidneyBiopsyBlogs",
        }
      ),
    },
    {
      title: i18n.t("blogs.101.innerHTML.analyzingBiopsySample.list2.title", {
        ns: "kidneyBiopsyBlogs",
      }),
      description: i18n.t(
        "blogs.101.innerHTML.analyzingBiopsySample.list2.description",
        {
          ns: "kidneyBiopsyBlogs",
        }
      ),
    },
    {
      title: i18n.t("blogs.101.innerHTML.analyzingBiopsySample.list3.title", {
        ns: "kidneyBiopsyBlogs",
      }),
      description: i18n.t(
        "blogs.101.innerHTML.analyzingBiopsySample.list3.description",
        {
          ns: "kidneyBiopsyBlogs",
        }
      ),
    },
  ];

  const watchForSignsAndSymptomsListData = [
    {
      title: i18n.t(
        "blogs.101.innerHTML.watchForSignsAndSymptoms.list1.title",
        {
          ns: "kidneyBiopsyBlogs",
        }
      ),
      description: i18n.t(
        "blogs.101.innerHTML.watchForSignsAndSymptoms.list1.description",
        {
          ns: "kidneyBiopsyBlogs",
        }
      ),
    },
    {
      title: i18n.t(
        "blogs.101.innerHTML.watchForSignsAndSymptoms.list2.title",
        {
          ns: "kidneyBiopsyBlogs",
        }
      ),
      description: i18n.t(
        "blogs.101.innerHTML.watchForSignsAndSymptoms.list2.description",
        {
          ns: "kidneyBiopsyBlogs",
        }
      ),
    },
    {
      title: i18n.t(
        "blogs.101.innerHTML.watchForSignsAndSymptoms.list3.title",
        {
          ns: "kidneyBiopsyBlogs",
        }
      ),
      description: i18n.t(
        "blogs.101.innerHTML.watchForSignsAndSymptoms.list3.description",
        {
          ns: "kidneyBiopsyBlogs",
        }
      ),
    },
    {
      title: i18n.t(
        "blogs.101.innerHTML.watchForSignsAndSymptoms.list4.title",
        {
          ns: "kidneyBiopsyBlogs",
        }
      ),
      description: i18n.t(
        "blogs.101.innerHTML.watchForSignsAndSymptoms.list4.description",
        {
          ns: "kidneyBiopsyBlogs",
        }
      ),
    },
  ];

  return {
    preBiopsyTestsListData,
    analyzingBiopsySampleListData,
    watchForSignsAndSymptomsListData,
  };
};
