import { Trans } from "react-i18next";
import i18n from "../../../i18n/i18n";
import { Link } from "react-router-dom";

export const dialysisContentDatas = () => {
  const whyDialysisIsNeededData = [
    {
      title: i18n.t("whyDialysisIsNeededLists.list1.title", {
        ns: "dialysis",
      }),
      subTitle: (
        <Trans
          i18nKey={i18n.t("whyDialysisIsNeededLists.list1.description", {
            ns: "dialysis",
          })}
          components={{
            aText: <Link className="cs_site_branding" to="/" />,
          }}
        />
      ),
      iconUrl: "/images/kidney_educations/waste-removal.jpg",
    },
    {
      title: i18n.t("whyDialysisIsNeededLists.list2.title", {
        ns: "dialysis",
      }),
      subTitle: (
        <Trans
          i18nKey={i18n.t("whyDialysisIsNeededLists.list2.description", {
            ns: "dialysis",
          })}
          components={{
            aText: <Link className="cs_site_branding" to="/" />,
          }}
        />
      ),
      iconUrl: "/images/kidney_educations/electrolyte-balance.jpg",
    },
    {
      title: i18n.t("whyDialysisIsNeededLists.list3.title", {
        ns: "dialysis",
      }),
      subTitle: (
        <Trans
          i18nKey={i18n.t("whyDialysisIsNeededLists.list3.description", {
            ns: "dialysis",
          })}
          components={{
            aText: <Link className="cs_site_branding" to="/" />,
          }}
        />
      ),
      iconUrl: "/images/kidney_educations/blood-pressure.jpg",
    },
    {
      title: i18n.t("whyDialysisIsNeededLists.list4.title", {
        ns: "dialysis",
      }),
      subTitle: (
        <Trans
          i18nKey={i18n.t("whyDialysisIsNeededLists.list4.description", {
            ns: "dialysis",
          })}
          components={{
            aText: <Link className="cs_site_branding" to="/" />,
          }}
        />
      ),
      iconUrl: "/images/kidney_educations/excess-fluid.jpg",
    },
  ];

  const typesOfDialysisData1 = [
    {
      title: i18n.t("typesOfDialysisLists.list1.subLists.list1.title", {
        ns: "dialysis",
      }),
      subTitle: i18n.t(
        "typesOfDialysisLists.list1.subLists.list1.description",
        {
          ns: "dialysis",
        }
      ),
    },
    {
      title: i18n.t("typesOfDialysisLists.list1.subLists.list2.title", {
        ns: "dialysis",
      }),
      subTitle: i18n.t(
        "typesOfDialysisLists.list1.subLists.list2.description",
        {
          ns: "dialysis",
        }
      ),
    },
    {
      title: i18n.t("typesOfDialysisLists.list1.subLists.list3.title", {
        ns: "dialysis",
      }),
      subTitle: i18n.t(
        "typesOfDialysisLists.list1.subLists.list3.description",
        {
          ns: "dialysis",
        }
      ),
    },
  ];

  const typesOfDialysisData2 = [
    {
      title: i18n.t("typesOfDialysisLists.list2.subLists.list1.title", {
        ns: "dialysis",
      }),
      subTitle: i18n.t(
        "typesOfDialysisLists.list2.subLists.list1.description",
        {
          ns: "dialysis",
        }
      ),
    },
    {
      title: i18n.t("typesOfDialysisLists.list2.subLists.list2.title", {
        ns: "dialysis",
      }),
      subTitle: `${i18n.t(
        "typesOfDialysisLists.list2.subLists.list2.description",
        {
          ns: "dialysis",
        }
      )}:`,
      childList: [
        {
          title: i18n.t(
            "typesOfDialysisLists.list2.subLists.list2.subLists.list1.title",
            {
              ns: "dialysis",
            }
          ),
          subTitle: i18n.t(
            "typesOfDialysisLists.list2.subLists.list2.subLists.list1.description",
            {
              ns: "dialysis",
            }
          ),
        },
        {
          title: i18n.t(
            "typesOfDialysisLists.list2.subLists.list2.subLists.list2.title",
            {
              ns: "dialysis",
            }
          ),
          subTitle: i18n.t(
            "typesOfDialysisLists.list2.subLists.list2.subLists.list2.description",
            {
              ns: "dialysis",
            }
          ),
        },
      ],
    },
    {
      title: i18n.t("typesOfDialysisLists.list2.subLists.list3.title", {
        ns: "dialysis",
      }),
      subTitle: i18n.t(
        "typesOfDialysisLists.list2.subLists.list3.description",
        {
          ns: "dialysis",
        }
      ),
    },
  ];

  const choosingDialysisOptionData = [
    {
      title: i18n.t("choosingDialysisOptionLists.list1.title", {
        ns: "dialysis",
      }),
      subTitle: i18n.t("choosingDialysisOptionLists.list1.description", {
        ns: "dialysis",
      }),
      iconUrl: "/images/kidney_educations/medical-condition.svg",
    },
    {
      title: i18n.t("choosingDialysisOptionLists.list2.title", {
        ns: "dialysis",
      }),
      subTitle: i18n.t("choosingDialysisOptionLists.list2.description", {
        ns: "dialysis",
      }),
      iconUrl: "/images/kidney_educations/lifestyle.svg",
    },
    {
      title: i18n.t("choosingDialysisOptionLists.list3.title", {
        ns: "dialysis",
      }),
      subTitle: i18n.t("choosingDialysisOptionLists.list3.description", {
        ns: "dialysis",
      }),
      iconUrl: "/images/kidney_educations/vascular-access.svg",
    },
    {
      title: i18n.t("choosingDialysisOptionLists.list4.title", {
        ns: "dialysis",
      }),
      subTitle: i18n.t("choosingDialysisOptionLists.list4.description", {
        ns: "dialysis",
      }),
      iconUrl: "/images/kidney_educations/support-system.svg",
    },
  ];

  const livingWithDialysisData = [
    {
      title: i18n.t("livingWithDialysisLists.list1.title", {
        ns: "dialysis",
      }),
      subTitle: i18n.t("livingWithDialysisLists.list1.description", {
        ns: "dialysis",
      }),
      iconUrl: "/images/kidney_educations/diet-dialysis.svg",
    },
    {
      title: i18n.t("livingWithDialysisLists.list2.title", {
        ns: "dialysis",
      }),
      subTitle: i18n.t("livingWithDialysisLists.list2.description", {
        ns: "dialysis",
      }),
      iconUrl: "/images/kidney_educations/medications.svg",
    },
    {
      title: i18n.t("livingWithDialysisLists.list3.title", {
        ns: "dialysis",
      }),
      subTitle: i18n.t("livingWithDialysisLists.list3.description", {
        ns: "dialysis",
      }),
      iconUrl: "/images/kidney_educations/exercise.svg",
    },
    {
      title: (
        <Trans
          i18nKey={i18n.t("livingWithDialysisLists.list4.title", {
            ns: "dialysis",
          })}
          components={{
            breakTxt: <br />,
          }}
        />
      ),
      subTitle: i18n.t("livingWithDialysisLists.list4.description", {
        ns: "dialysis",
      }),
      iconUrl: "/images/kidney_educations/emotinal-wellbeing.svg",
    },
  ];

  return {
    whyDialysisIsNeededData,
    typesOfDialysisData1,
    typesOfDialysisData2,
    choosingDialysisOptionData,
    livingWithDialysisData,
  };
};
