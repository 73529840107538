import React, { useRef } from "react";
import { pageTitle } from "../../../helpers/PageTitle";
import { Trans, useTranslation } from "react-i18next";
import Section from "../../../components/Section";
import FeaturesSectionStyle5 from "../../../components/Section/FeaturesSection/FeaturesSectionStyle5";
import { kidneyDietContentDatas } from "./kidneyDietContents";
import Spacing from "../../../components/Spacing";
import AwardSection from "../../../components/Section/AwardSection";
import FeaturesSectionStyle2 from "../../../components/Section/FeaturesSection/FeaturesSectionStyle2";
import FeaturesSectionStyle3 from "../../../components/Section/FeaturesSection/FeaturesSectionStyle3";
import KidneyBreadcrumStyle from "../../../components/Breadcrumb/KidneyBreadcrumStyle";
import KidneyEducationBannerStyle from "../../../components/Section/BannerSection/KidneyEducationBannerStyle";
import ScrollToSection from "../../../components/ScrollToSection";

const KidneyDiet = () => {
  pageTitle("Kidney Diet");
  const { t } = useTranslation(["kidneyDiet"]);

  const understandingYourNeedRef = useRef(null);
  const nutritionalEducationRef = useRef(null);
  const personalizedDietPlansRef = useRef(null);
  const beyondDietRef = useRef(null);

  const scrollToLists = [
    {
      btnName: "Understanding your needs",
      btnRef: understandingYourNeedRef,
      btnImage: "/images/our_services/kidney-diet/kidney-diet-icon-01.svg",
    },
    {
      btnName: "Nutritional value education",
      btnRef: nutritionalEducationRef,
      btnImage: "/images/our_services/kidney-diet/kidney-diet-icon-02.svg",
    },
    {
      btnName: "Personalized diet plans",
      btnRef: personalizedDietPlansRef,
      btnImage: "/images/our_services/kidney-diet/kidney-diet-icon-03.svg",
    },
    {
      btnName: "Support beyond diet",
      btnRef: beyondDietRef,
      btnImage: "/images/our_services/kidney-diet/kidney-diet-icon-04.svg",
    },
  ];

  const {
    understandingYourNeedsData,
    nutritionalValueEducationData,
    personalizedDietPlansData,
    supportBeyondDietData,
  } = kidneyDietContentDatas();

  return (
    <>
      {/* Breadcrumb Section Start */}
      <Section className="cs_kideny_education">
        <KidneyBreadcrumStyle />
      </Section>
      {/* Breadcrumb Section End */}

      {/* Banner Section Start */}
      {/* <BannerSectionStyle5
        bgUrl="/images/departments/banner_bg.svg"
        imgUrl="/images/departments/banner_img.png"
        title={t("bannerContent.title")}
        subHeadTitle={`${t("bannerContent.subTitle")}:`}
        subTitle={t("bannerContent.description")}
        customClasses="cs_about_kidney"
        customeThemeClass="cs_our_services_color"
      /> */}
      <Section className="cs_banner_styles">
        <KidneyEducationBannerStyle
          bgUrl="/images/our_services/kidney-diet/kidney-diet-web-banner-with-gradient.jpg"
          // imgUrl="/images/our_services/kidney-diet/kidney-diet-web-banner.jpg"
          title={t("bannerContent.title")}
          subHeadTitle={`${t("bannerContent.subTitle")}:`}
          subTitle={t("bannerContent.description")}
          customeThemeClass=""
        />
      </Section>
      {/* Banner Section End */}

      {/* Section Navigation Start */}
      <Section
        topMd={50}
        topLg={50}
        topXl={50}
        bottomXl={80}
        bottomLg={80}
        bottomMd={80}
        className="cs_white_bg"
      >
        <ScrollToSection btnLists={scrollToLists} brandName="our-services" />
      </Section>
      {/* Section Navigation End */}

      {/* Understanding Your Needs Section Start */}
      <Section
        bottomMd={130}
        bottomLg={120}
        bottomXl={110}
        className="cs_white_bg cs_dialysis_accessories"
        sectionRef={understandingYourNeedRef}
      >
        <FeaturesSectionStyle2
          sectionTitle={t("understandingYourNeeds.title")}
          sectoinSubTitle={`${t("understandingYourNeeds.description")}:`}
          imgUrl="/images/our_services/kidney-diet/understanding-needs-new.jpg"
          data={understandingYourNeedsData}
        />
        <Spacing md="30" lg="30" xl="30" />
        <div className="container">
          <div className="row">
            <p className="m-0 cs_iconbox_subtitle">{`${t(
              "understandingYourNeeds.hints"
            )}`}</p>
          </div>
        </div>
      </Section>
      {/* Understanding Your Needs Section End */}

      {/* Nutritional Value Education Section Start */}
      <Section
        bottomMd={130}
        bottomLg={120}
        bottomXl={110}
        className="cs_white_bg cs_counselling"
        sectionRef={nutritionalEducationRef}
      >
        <AwardSection
          sectionTitle={t("nutritionalValueEducation.title")}
          subTitleTrans={
            <Trans
              i18nKey={`${t("nutritionalValueEducation.description")}:`}
              components={{
                boldText: <b />,
                doubleQuotes: <q />,
              }}
            />
          }
          data={nutritionalValueEducationData}
        />
        <Spacing md="30" lg="30" xl="30" />
        <div className="container">
          <div className="row">
            <p className="m-0 cs_iconbox_subtitle">{`${t(
              "nutritionalValueEducation.hints"
            )}`}</p>
          </div>
        </div>
      </Section>
      {/* Nutritional Value Education Section End */}

      {/* Personalized Diet Plans Section Start */}
      <Section
        bottomMd={130}
        bottomLg={120}
        bottomXl={110}
        className="cs_white_bg cs_counselling"
        sectionRef={personalizedDietPlansRef}
      >
        <FeaturesSectionStyle5
          sectionTitle={t("personalizedDietPlans.title")}
          sectionSubTitle={`${t("personalizedDietPlans.description")}:`}
          data={personalizedDietPlansData}
        />
        <Spacing md="30" lg="30" xl="30" />
        <div className="container">
          <div className="row">
            <p className="m-0 cs_iconbox_subtitle">{`${t(
              "personalizedDietPlans.hints"
            )}`}</p>
          </div>
        </div>
      </Section>
      {/* Personalized Diet Plans Section End */}

      {/* Support Beyond Diet Section Start */}
      <Section
        bottomMd={130}
        bottomLg={120}
        bottomXl={110}
        className="cs_white_bg cs_counselling"
        sectionRef={beyondDietRef}
      >
        <FeaturesSectionStyle3
          sectionTitle={t("supportBeyondDiet.title")}
          subTitle={`${t("supportBeyondDiet.description")}:`}
          data={supportBeyondDietData}
        />
        <Spacing md="30" lg="30" xl="30" />
        <div className="container">
          <div className="row">
            <p className="m-0 cs_iconbox_subtitle">{`${t(
              "supportBeyondDiet.hints"
            )}`}</p>
          </div>
        </div>
      </Section>
      {/* Support Beyond Diet Section End */}
    </>
  );
};

export default KidneyDiet;
