import React from "react";
import CustomSuspense from "../../../components/CustomSuspence";
const LazyBiposyGun = React.lazy(() => import("./KidneyBiopsyGun"));

const KidneyBiposyGun = () => {
  return (
    <CustomSuspense loadingLabel="Loading Counselling">
      <LazyBiposyGun />
    </CustomSuspense>
  );
};

export default KidneyBiposyGun;
