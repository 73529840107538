import React from "react";
import SectionHeading from "../../SectionHeading";
import Spacing from "../../Spacing";
import AccordionWithComponent from "../../AccordionWithComponent";

const AwardWithAccordionSection = ({
  sectionTitle,
  sectionSubTitle = "",
  subTitleTrans = "",
  sectionSubTitle2 = "",
  data,
  accordionInfos = {},
}) => {
  const getIconWithAccordionList = (item, itemPosition) => {
    const accordionData = accordionInfos[itemPosition];

    return (
      <>
        <div className="cs_iconbox cs_style_1 cs_shadow_1 cs_radius_15 cs_awar_with_accordion">
          <div className="cs_iconbox_top">
            <div className="cs_iconbox_icon cs_radius_15 cs_accent_bg cs_center">
              <img loading="lazy" src={item.iconUrl} alt="Icon" />
            </div>
            <h2 className="cs_iconbox_title cs_medium cs_fs_20 m-0">
              {item.title}
            </h2>
          </div>
          <p className="cs_iconbox_text">{item.subTitle}</p>
          {Object.keys(accordionData).length > 0 && (
            <>
              <Spacing md="20" lg="15" xl="10" />
              <AccordionWithComponent
                variant={`cs_style1 cs_type_3 ${accordionData?.classes}`}
                data={accordionData?.data}
              />
            </>
          )}
        </div>
      </>
    );
  };

  return (
    <div className="container">
      {(sectionTitle || sectionSubTitle) && (
        <>
          <SectionHeading
            title={sectionTitle}
            subTitle={sectionSubTitle}
            subTitleTrans={subTitleTrans}
            subTitle2={sectionSubTitle2}
          />
          <Spacing md="72" lg="50" />
        </>
      )}
      <div className="row gy-4">
        {data?.map((item, index) => (
          <div className="col-xxl-3 col-md-6" key={index}>
            {getIconWithAccordionList(item, index)}
          </div>
        ))}
      </div>
    </div>
  );
};

export default AwardWithAccordionSection;
