import React from "react";
import { useTranslation } from "react-i18next";
import BlogOrderedList from "../../../Blogs/BlogComponents/BlogOrderedList";
import { firstBlogContentDatas } from "./firstBlogContents";

const FirstBlog = () => {
  const { t } = useTranslation(["cathetersBlogs"]);

  const { firstBlogListData } = firstBlogContentDatas();

  return (
    <>
      <p>{t(`blogs.101.innerHTML.blogDescription`)}</p>
      <div className="cs_blog_ord_list">
        <BlogOrderedList data={firstBlogListData} />
      </div>
      <p>{t(`blogs.101.innerHTML.paraContents.description`)}</p>
      <p>
        <b>{t(`blogs.101.innerHTML.disclaimer.title`)}: </b>
        <span>{t(`blogs.101.innerHTML.disclaimer.description`)}</span>
      </p>
    </>
  );
};

export default FirstBlog;
