import i18n from "../../../../i18n/i18n";

export const firstBlogContentDatas = () => {
  const metallicNeedlesListData = [
    {
      title: i18n.t("blogs.101.innerHTML.metallicNeedles.advantages.title", {
        ns: "dialysisAccessoriesBlog",
      }),
      childList: [
        {
          title: i18n.t(
            "blogs.101.innerHTML.metallicNeedles.advantages.list1.title",
            {
              ns: "dialysisAccessoriesBlog",
            }
          ),
          description: i18n.t(
            "blogs.101.innerHTML.metallicNeedles.advantages.list1.description",
            {
              ns: "dialysisAccessoriesBlog",
            }
          ),
        },
        {
          title: i18n.t(
            "blogs.101.innerHTML.metallicNeedles.advantages.list2.title",
            {
              ns: "dialysisAccessoriesBlog",
            }
          ),
          description: i18n.t(
            "blogs.101.innerHTML.metallicNeedles.advantages.list2.description",
            {
              ns: "dialysisAccessoriesBlog",
            }
          ),
        },
        {
          title: i18n.t(
            "blogs.101.innerHTML.metallicNeedles.advantages.list3.title",
            {
              ns: "dialysisAccessoriesBlog",
            }
          ),
          description: i18n.t(
            "blogs.101.innerHTML.metallicNeedles.advantages.list3.description",
            {
              ns: "dialysisAccessoriesBlog",
            }
          ),
        },
      ],
    },
    {
      title: i18n.t(
        "blogs.101.innerHTML.metallicNeedles.considerations.title",
        {
          ns: "dialysisAccessoriesBlog",
        }
      ),
      childList: [
        {
          title: i18n.t(
            "blogs.101.innerHTML.metallicNeedles.considerations.list1.title",
            {
              ns: "dialysisAccessoriesBlog",
            }
          ),
          description: i18n.t(
            "blogs.101.innerHTML.metallicNeedles.considerations.list1.description",
            {
              ns: "dialysisAccessoriesBlog",
            }
          ),
        },
        {
          title: i18n.t(
            "blogs.101.innerHTML.metallicNeedles.considerations.list2.title",
            {
              ns: "dialysisAccessoriesBlog",
            }
          ),
          description: i18n.t(
            "blogs.101.innerHTML.metallicNeedles.considerations.list2.description",
            {
              ns: "dialysisAccessoriesBlog",
            }
          ),
        },
      ],
    },
  ];

  const plasticNeedlesListData = [
    {
      title: i18n.t("blogs.101.innerHTML.plasticNeedles.advantages.title", {
        ns: "dialysisAccessoriesBlog",
      }),
      childList: [
        {
          title: i18n.t(
            "blogs.101.innerHTML.plasticNeedles.advantages.list1.title",
            {
              ns: "dialysisAccessoriesBlog",
            }
          ),
          description: i18n.t(
            "blogs.101.innerHTML.plasticNeedles.advantages.list1.description",
            {
              ns: "dialysisAccessoriesBlog",
            }
          ),
        },
        {
          title: i18n.t(
            "blogs.101.innerHTML.plasticNeedles.advantages.list2.title",
            {
              ns: "dialysisAccessoriesBlog",
            }
          ),
          description: i18n.t(
            "blogs.101.innerHTML.plasticNeedles.advantages.list2.description",
            {
              ns: "dialysisAccessoriesBlog",
            }
          ),
        },
        {
          title: i18n.t(
            "blogs.101.innerHTML.plasticNeedles.advantages.list3.title",
            {
              ns: "dialysisAccessoriesBlog",
            }
          ),
          description: i18n.t(
            "blogs.101.innerHTML.plasticNeedles.advantages.list3.description",
            {
              ns: "dialysisAccessoriesBlog",
            }
          ),
        },
      ],
    },
    {
      title: i18n.t("blogs.101.innerHTML.plasticNeedles.considerations.title", {
        ns: "dialysisAccessoriesBlog",
      }),
      childList: [
        {
          title: i18n.t(
            "blogs.101.innerHTML.plasticNeedles.considerations.list1.title",
            {
              ns: "dialysisAccessoriesBlog",
            }
          ),
          description: i18n.t(
            "blogs.101.innerHTML.plasticNeedles.considerations.list1.description",
            {
              ns: "dialysisAccessoriesBlog",
            }
          ),
        },
        {
          title: i18n.t(
            "blogs.101.innerHTML.plasticNeedles.considerations.list2.title",
            {
              ns: "dialysisAccessoriesBlog",
            }
          ),
          description: i18n.t(
            "blogs.101.innerHTML.plasticNeedles.considerations.list2.description",
            {
              ns: "dialysisAccessoriesBlog",
            }
          ),
        },
        {
          title: i18n.t(
            "blogs.101.innerHTML.plasticNeedles.considerations.list3.title",
            {
              ns: "dialysisAccessoriesBlog",
            }
          ),
          description: i18n.t(
            "blogs.101.innerHTML.plasticNeedles.considerations.list3.description",
            {
              ns: "dialysisAccessoriesBlog",
            }
          ),
        },
      ],
    },
  ];

  const choosingTheRightNeedleListData = [
    {
      title: i18n.t("blogs.101.innerHTML.choosingTheRightNeedle.list1.title", {
        ns: "dialysisAccessoriesBlog",
      }),
      description: i18n.t(
        "blogs.101.innerHTML.choosingTheRightNeedle.list1.description",
        {
          ns: "dialysisAccessoriesBlog",
        }
      ),
    },
    {
      title: i18n.t("blogs.101.innerHTML.choosingTheRightNeedle.list2.title", {
        ns: "dialysisAccessoriesBlog",
      }),
      description: i18n.t(
        "blogs.101.innerHTML.choosingTheRightNeedle.list2.description",
        {
          ns: "dialysisAccessoriesBlog",
        }
      ),
    },
    {
      title: i18n.t("blogs.101.innerHTML.choosingTheRightNeedle.list3.title", {
        ns: "dialysisAccessoriesBlog",
      }),
      description: i18n.t(
        "blogs.101.innerHTML.choosingTheRightNeedle.list3.description",
        {
          ns: "dialysisAccessoriesBlog",
        }
      ),
    },
  ];

  return {
    metallicNeedlesListData,
    plasticNeedlesListData,
    choosingTheRightNeedleListData,
  };
};
