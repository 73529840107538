import React from "react";

const WhatsAppChatBot = () => {
  const phoneNumber = "+919111606162";
  const message = "Hello! I need help with your service.";

  // Encode the message for URL compatibility
  const encodedMessage = encodeURIComponent(message);

  // WhatsApp URL scheme
  const whatsappUrl = `https://wa.me/${phoneNumber}?text=${encodedMessage}`;

  return (
    <div style={{ position: "fixed", bottom: 20, right: 20, zIndex: 9999 }}>
      <a href={whatsappUrl} target="_blank" rel="noopener noreferrer">
        <img
          src="/images/home/whats-app-icon.svg"
          alt="Chat with us on WhatsApp"
          style={{
            width: 60,
            height: 60,
            borderRadius: "50%",
            cursor: "pointer",
          }}
        />
      </a>
    </div>
  );
};

export default WhatsAppChatBot;
