import React from "react";
import { useParams } from "react-router-dom";
import { pageTitle } from "../../helpers/PageTitle";
import BlogBreadCrumbSection from "../Blogs/BlogBreadCrumbSection";
import BlogDetailInfoSection from "../Blogs/BlogDetailInfoSection";
import Spacing from "../../components/Spacing";
import BlogSidebar from "../Blogs/BlogSidebar";
import BlogImageSection from "../Blogs/BlogImageSection";
import FirstBlog from "./FirstBlog";
import { homeBlogDatas } from "../../constants/common-contents";
import SecondBlog from "../OurServices/Catheters/Blogs/SecondBlog";
import ThirdBlog from "../OurServices/Catheters/Blogs/ThirdBlog";

const BlogDetails = () => {
  pageTitle("Home Blogs");

  const { blogId } = useParams();

  return (
    <>
      <BlogBreadCrumbSection jsonFileName="homeBlog" unUsedValues="home" />
      <div className="container">
        <BlogDetailInfoSection jsonFileName="homeBlog" />
        <Spacing md="55" />
        <BlogImageSection jsonFileName="homeBlog" />
        <Spacing md="90" lg="50" />
        <div className="row">
          <div className="col-lg-8">
            <div className="cs_blog_details">
              {blogId === "101" && <FirstBlog />}
              {blogId === "102" && <SecondBlog />}
              {blogId === "103" && <ThirdBlog />}
            </div>
          </div>
          <div className="col-lg-4">
            <BlogSidebar blogDatas={homeBlogDatas} />
          </div>
        </div>
        <Spacing md="30" lg="30" />
      </div>
    </>
  );
};

export default BlogDetails;
