import React from "react";
import SectionHeading from "../../SectionHeading";
import Spacing from "../../Spacing";
import IconBoxStyle14 from "../../IconBox/IconBoxStyle14";

export default function DepartmentSectionStyle7({
  sectionTitle,
  sectionTitleUp,
  data,
  sectionTitleUpBrandColor = "",
}) {
  return (
    <div className="container">
      <SectionHeading
        title={sectionTitle}
        titleUp={sectionTitleUp}
        sectionTitleUpBrandColor={sectionTitleUpBrandColor}
      />
      <Spacing md="72" lg="50" />
      <div className="row">
        {data?.map((item, index) => (
          <div className="col-xl-4 col-md-6" key={index}>
            <IconBoxStyle14 {...item} />
          </div>
        ))}
      </div>
    </div>
  );
}
