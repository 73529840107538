import React from "react";
import CustomSuspense from "../../../components/CustomSuspence";
const LazyDialysisAccessories = React.lazy(() => import("./DialysisAccessories"));

const DialysisAccessories = () => {
  return (
    <CustomSuspense loadingLabel="Loading Counselling">
      <LazyDialysisAccessories />
    </CustomSuspense>
  );
};

export default DialysisAccessories;
