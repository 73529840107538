import React from "react";
import CustomSuspense from "../../../components/CustomSuspence";
const LazyMedicationService = React.lazy(() => import("./MedicationService"));

const MedicationServices = () => {
  return (
    <CustomSuspense loadingLabel="Loading medicines">
      <LazyMedicationService />
    </CustomSuspense>
  );
};

export default MedicationServices;
