/* eslint-disable jsx-a11y/anchor-has-content */
import React from "react";
import Section from "../../components/Section";
import { Trans, useTranslation } from "react-i18next";
import Spacing from "../../components/Spacing";
import { privacyPolicyContentDatas } from "./privacyPolicyContents";
import BlogOrderedList from "../Blogs/BlogComponents/BlogOrderedList";

const PrivacyPolicy = () => {
  const { t } = useTranslation(["privacyPolicy"]);
  const { privacyPolicyListData } = privacyPolicyContentDatas();

  return (
    <Section
      topMd={150}
      topLg={150}
      topXl={150}
      bottomXl={80}
      bottomLg={80}
      bottomMd={80}
    >
      <div className="container">
        <div className="cs_blog_details">
          <h2>{t(`privacy.title`)}</h2>
          <p className="cs_custom-para-link">
            <Trans
              i18nKey={t("privacy.description")}
              components={{
                anchorText: (
                  <a
                    href="https://www.kidneyneeds.com/"
                    rel="noreferrer"
                    target="_blank"
                  />
                ),
                boldText: <b />,
              }}
            />
          </p>

          <Spacing md="30" lg="30" />
          <h2>{t(`contentsOfPrivacyPolicy.title`)}</h2>
          <p>{t(`contentsOfPrivacyPolicy.description.para1`)}</p>
          <p>{t(`contentsOfPrivacyPolicy.description.para2`)}</p>

          <Spacing md="30" lg="30" />
          <h2>{t(`user.title`)}</h2>
          <p>{t(`user.description`)}</p>

          <Spacing md="30" lg="30" />
          <h2>{t(`kidneyneeds.title`)}</h2>
          <p>{t(`kidneyneeds.description`)}</p>

          <Spacing md="30" lg="30" />
          <h2>{t(`data.title`)}</h2>
          <p>{t(`data.description`)}</p>

          <Spacing md="30" lg="30" />
          <h2>{t(`cookies.title`)}</h2>
          <p>{t(`cookies.description`)}</p>

          <Spacing md="30" lg="30" />
          <h2>{t(`partners.title`)}</h2>
          <p>{t(`partners.description`)}</p>

          <Spacing md="30" lg="30" />
          <h2>{t(`ServiceProviders.title`)}</h2>
          <p>{t(`ServiceProviders.description`)}</p>

          <Spacing md="30" lg="30" />
          <h2>{t(`privacyPolicy.title`)}</h2>
          <div className="cs_blog_ord_list">
            <BlogOrderedList data={privacyPolicyListData} />
          </div>
        </div>
      </div>
    </Section>
  );
};

export default PrivacyPolicy;
