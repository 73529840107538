import React, { useRef } from "react";
import axios from "axios";
import { prescriptionTemplate } from "../../constants/email-template/upload-prescription";
import { toastSuccess, toastFailure } from "../../helpers/toastHelper";

const baseUrl = process.env.REACT_APP_BASE_API_URL;

export default function UploadPrescriptionForm({
  label,
  btnText,
  btnArrowUrl,
}) {

  const fileRef = useRef(null);



  const handleSubmit = async (e) => {
    e.preventDefault();

    const file = fileRef.current.files[0];
    if (!file) {
      toastFailure("Please upload a file!");
      return;
    } else {
      const fileSizeLimit = 2 * 1024 * 1024; // 2MB in bytes
      if (file.size > fileSizeLimit) {
        toastFailure("File size exceeds 2MB limit.");
        return;
      }

      const allowedTypes = [
        "image/jpeg",
        "image/png",
        "application/pdf",
        "application/msword",
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      ];
      if (!allowedTypes.includes(file.type)) {
        toastFailure("Only JPEG, PNG, PDF and word files are allowed.");
        return;
      }
      const data = new FormData();

      data.append("file", file);
      data.append("body", prescriptionTemplate());
      data.append("subject", "Prescription delivery request");

      try {
        await axios.post(`${baseUrl}/contact-us`, data, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
        toastSuccess("Prescription uploaded!");
        resetForm();
      } catch (error) {
        console.log(error)
        toastFailure("Something went wrong. Please retry later.");
      }
    }
  };

  const resetForm = () => {
    fileRef.current.value = null;
  };

  return (
    <>
      {label && <p>Your Email</p>}
      <form
        onSubmit={handleSubmit}
        className="cs_newsletter_form cs_upload_form"
      >
        <input
          type="file"
          ref={fileRef}
          className="cs_form_field"
          id="upload_prescription"
          name="upload_prescription"
        ></input>

        <button  style= {{marginTop: 30}} className="cs_btn cs_style_1">
          <span>Submit</span>
          <i>
            <img loading="lazy" src="/images/icons/arrow_white.svg" alt="Icon" />
            <img loading="lazy" src="/images/icons/arrow_white.svg" alt="Icon" />
          </i>
        </button>
      </form>
    </>
  );
}
