import React from "react";
import CustomSuspense from "../../../components/CustomSuspence";
const LazyCatheters = React.lazy(() => import("./Catheters"));

const Catheter = () => {
  return (
    <CustomSuspense loadingLabel="Loading Catheter">
      <LazyCatheters />
    </CustomSuspense>
  );
};

export default Catheter;