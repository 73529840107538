import i18n from "../../../i18n/i18n";

export const cathetersContentDatas = () => {
  const whyChooseDialysisCatheterData = [
    {
      title: i18n.t("whyChooseDialysisCatheterLists.list1.title", {
        ns: "catheters",
      }),
      subTitle: i18n.t("whyChooseDialysisCatheterLists.list1.description", {
        ns: "catheters",
      }),
      iconUrl: "/images/kidney_educations/catheters/Icon 2-01.svg",
    },
    {
      title: i18n.t("whyChooseDialysisCatheterLists.list2.title", {
        ns: "catheters",
      }),
      subTitle: i18n.t("whyChooseDialysisCatheterLists.list2.description", {
        ns: "catheters",
      }),
      iconUrl: "/images/kidney_educations/catheters/Icon 2-02.svg",
    },
    {
      title: i18n.t("whyChooseDialysisCatheterLists.list3.title", {
        ns: "catheters",
      }),
      subTitle: i18n.t("whyChooseDialysisCatheterLists.list3.description", {
        ns: "catheters",
      }),
      iconUrl: "/images/kidney_educations/catheters/Icon 2-03.svg",
    },
  ];

  const typesOfDialysisCathetersData = [
    {
      title: i18n.t("typesOfDialysisCathetersLists.type1.title", {
        ns: "catheters",
      }),
      subTitle: i18n.t("typesOfDialysisCathetersLists.type1.description", {
        ns: "catheters",
      }),
      iconUrl: "/images/kidney_educations/catheters/Icon 2-04.svg",
      href: "#",
    },
    {
      title: i18n.t("typesOfDialysisCathetersLists.type2.title", {
        ns: "catheters",
      }),
      subTitle: i18n.t("typesOfDialysisCathetersLists.type2.description", {
        ns: "catheters",
      }),
      iconUrl: "/images/kidney_educations/catheters/Icon 2-05.svg",
      href: "#",
    },
  ];

  const catheterPlacementProcedureData = [
    {
      title: i18n.t("catheterPlacementProcedureLists.list1.title", {
        ns: "catheters",
      }),
      subTitle: i18n.t("catheterPlacementProcedureLists.list1.description", {
        ns: "catheters",
      }),
      iconUrl: "/images/kidney_educations/catheters/Icon 2-06.svg",
      number: "01",
    },
    {
      title: i18n.t("catheterPlacementProcedureLists.list2.title", {
        ns: "catheters",
      }),
      subTitle: i18n.t("catheterPlacementProcedureLists.list2.description", {
        ns: "catheters",
      }),
      iconUrl: "/images/kidney_educations/catheters/Icon 2-07.svg",
      number: "02",
    },
    {
      title: i18n.t("catheterPlacementProcedureLists.list3.title", {
        ns: "catheters",
      }),
      subTitle: i18n.t("catheterPlacementProcedureLists.list3.description", {
        ns: "catheters",
      }),
      iconUrl: "/images/kidney_educations/catheters/Icon 2-08.svg",
      number: "03",
    },
  ];

  const caringForDialysisCatheterData = [
    {
      title: i18n.t("caringForDialysisCatheterLists.list1.title", {
        ns: "catheters",
      }),
      subTitle: i18n.t("caringForDialysisCatheterLists.list1.description", {
        ns: "catheters",
      }),
      iconUrl: "/images/kidney_educations/catheters/Icon 3-01.svg",
    },
    {
      title: i18n.t("caringForDialysisCatheterLists.list2.title", {
        ns: "catheters",
      }),
      subTitle: i18n.t("caringForDialysisCatheterLists.list2.description", {
        ns: "catheters",
      }),
      iconUrl: "/images/kidney_educations/catheters/Icon 3-02.svg",
    },
    {
      title: i18n.t("caringForDialysisCatheterLists.list3.title", {
        ns: "catheters",
      }),
      subTitle: i18n.t("caringForDialysisCatheterLists.list3.description", {
        ns: "catheters",
      }),
      iconUrl: "/images/kidney_educations/catheters/Icon 3-03.svg",
    },
    {
      title: i18n.t("caringForDialysisCatheterLists.list4.title", {
        ns: "catheters",
      }),
      subTitle: i18n.t("caringForDialysisCatheterLists.list4.description", {
        ns: "catheters",
      }),
      iconUrl: "/images/kidney_educations/catheters/Icon 3-04.svg",
    },
    {
      title: i18n.t("caringForDialysisCatheterLists.list5.title", {
        ns: "catheters",
      }),
      subTitle: i18n.t("caringForDialysisCatheterLists.list5.description", {
        ns: "catheters",
      }),
      iconUrl: "/images/kidney_educations/catheters/Icon 3-05.svg",
    },
  ];

  const tunneledCatheterAdvantagesData = [
    {
      title: i18n.t("tunneledCatheterAdvantages.lists.list1.title", {
        ns: "catheters",
      }),
      subTitle: i18n.t("tunneledCatheterAdvantages.lists.list1.description", {
        ns: "catheters",
      }),
    },
    {
      title: i18n.t("tunneledCatheterAdvantages.lists.list2.title", {
        ns: "catheters",
      }),
      subTitle: i18n.t("tunneledCatheterAdvantages.lists.list2.description", {
        ns: "catheters",
      }),
    },
    {
      title: i18n.t("tunneledCatheterAdvantages.lists.list3.title", {
        ns: "catheters",
      }),
      subTitle: i18n.t("tunneledCatheterAdvantages.lists.list3.description", {
        ns: "catheters",
      }),
    },
  ];

  const tunneledCatheterDisadvantagesData = [
    {
      title: i18n.t("tunneledCatheterDisadvantages.lists.list1.title", {
        ns: "catheters",
      }),
      subTitle: i18n.t(
        "tunneledCatheterDisadvantages.lists.list1.description",
        {
          ns: "catheters",
        }
      ),
    },
    {
      title: i18n.t("tunneledCatheterDisadvantages.lists.list2.title", {
        ns: "catheters",
      }),
      subTitle: i18n.t(
        "tunneledCatheterDisadvantages.lists.list2.description",
        {
          ns: "catheters",
        }
      ),
    },
  ];

  const nonTunneledCatheterAdvantagesData = [
    {
      title: i18n.t("nonTunneledCatheterAdvantages.lists.list1.title", {
        ns: "catheters",
      }),
      subTitle: i18n.t(
        "nonTunneledCatheterAdvantages.lists.list1.description",
        {
          ns: "catheters",
        }
      ),
    },
    {
      title: i18n.t("nonTunneledCatheterAdvantages.lists.list2.title", {
        ns: "catheters",
      }),
      subTitle: i18n.t(
        "nonTunneledCatheterAdvantages.lists.list2.description",
        {
          ns: "catheters",
        }
      ),
    },
    {
      title: i18n.t("nonTunneledCatheterAdvantages.lists.list3.title", {
        ns: "catheters",
      }),
    },
  ];

  const nonTunneledCatheterDisadvantagesData = [
    {
      title: i18n.t("nonTunneledCatheterDisadvantages.lists.list1.title", {
        ns: "catheters",
      }),
      subTitle: i18n.t(
        "nonTunneledCatheterDisadvantages.lists.list1.description",
        {
          ns: "catheters",
        }
      ),
    },
    {
      title: i18n.t("nonTunneledCatheterDisadvantages.lists.list2.title", {
        ns: "catheters",
      }),
      subTitle: i18n.t(
        "nonTunneledCatheterDisadvantages.lists.list2.description",
        {
          ns: "catheters",
        }
      ),
    },
    {
      title: i18n.t("nonTunneledCatheterDisadvantages.lists.list3.title", {
        ns: "catheters",
      }),
      subTitle: i18n.t(
        "nonTunneledCatheterDisadvantages.lists.list3.description",
        {
          ns: "catheters",
        }
      ),
    },
  ];

  const factorsToConsiderWhenChoosingData = [
    {
      title: i18n.t("factorsToConsiderWhenChoosingLists.list1.title", {
        ns: "catheters",
      }),
      subTitle: i18n.t("factorsToConsiderWhenChoosingLists.list1.description", {
        ns: "catheters",
      }),
      iconUrl: "/images/kidney_educations/catheters/Icon 4-01.svg",
    },
    {
      title: i18n.t("factorsToConsiderWhenChoosingLists.list2.title", {
        ns: "catheters",
      }),
      subTitle: i18n.t("factorsToConsiderWhenChoosingLists.list2.description", {
        ns: "catheters",
      }),
      iconUrl: "/images/kidney_educations/catheters/Icon 4-02.svg",
    },
    {
      title: i18n.t("factorsToConsiderWhenChoosingLists.list3.title", {
        ns: "catheters",
      }),
      subTitle: i18n.t("factorsToConsiderWhenChoosingLists.list3.description", {
        ns: "catheters",
      }),
      iconUrl: "/images/kidney_educations/catheters/Icon 4-03.svg",
    },
    {
      title: i18n.t("factorsToConsiderWhenChoosingLists.list4.title", {
        ns: "catheters",
      }),
      subTitle: i18n.t("factorsToConsiderWhenChoosingLists.list4.description", {
        ns: "catheters",
      }),
      iconUrl: "/images/kidney_educations/catheters/Icon 4-04.svg",
    },
  ];

  return {
    whyChooseDialysisCatheterData,
    typesOfDialysisCathetersData,
    catheterPlacementProcedureData,
    caringForDialysisCatheterData,
    tunneledCatheterAdvantagesData,
    tunneledCatheterDisadvantagesData,
    nonTunneledCatheterAdvantagesData,
    nonTunneledCatheterDisadvantagesData,
    factorsToConsiderWhenChoosingData,
  };
};
