import React from "react";
import { firstBlogContentDatas } from "./firstBlogContents";
import { useTranslation } from "react-i18next";
import Spacing from "../../../../components/Spacing";
import BlogList from "../../../Blogs/BlogComponents/BlogList";

const FirstBlog = () => {
  const { t } = useTranslation(["medicineBlogs"]);
  const {
    realLifeConsequencesListData,
    whyDoTheseMistakesHappenListData,
    colorCodedEnvelopesListData,
    clearInstructionsListData,
    benefitsSpecializedPackagingListData,
    howThisHelpsYouListData,
    beyondThePackagingListData,
  } = firstBlogContentDatas();

  return (
    <>
      <h2>{t(`blogs.101.innerHTML.silentEpidemicMedicationErrors.title`)}</h2>
      <p>
        {t(
          `blogs.101.innerHTML.silentEpidemicMedicationErrors.description.para1`
        )}
      </p>
      <p>
        {t(
          `blogs.101.innerHTML.silentEpidemicMedicationErrors.description.para2`
        )}
      </p>
      <p>
        {t(
          `blogs.101.innerHTML.silentEpidemicMedicationErrors.description.para3`
        )}
      </p>

      <Spacing md="20" lg="20" />
      <h2>{t(`blogs.101.innerHTML.theChallenge.title`)}</h2>
      <p>{t(`blogs.101.innerHTML.theChallenge.description.para1`)}</p>
      <p>{t(`blogs.101.innerHTML.theChallenge.description.para2`)}</p>

      <Spacing md="20" lg="20" />
      <h2>{t(`blogs.101.innerHTML.realLifeConsequences.title`)}</h2>
      <p>{t(`blogs.101.innerHTML.realLifeConsequences.description`)}</p>
      <div className="cs_blog_un_ord_list">
        <BlogList data={realLifeConsequencesListData} />
      </div>

      <Spacing md="20" lg="20" />
      <p>{t(`blogs.101.innerHTML.notes`)}</p>

      <Spacing md="20" lg="20" />
      <h2>{t(`blogs.101.innerHTML.whyDoTheseMistakesHappen.title`)}</h2>
      <p>{t(`blogs.101.innerHTML.whyDoTheseMistakesHappen.description`)}</p>
      <div className="cs_blog_un_ord_list">
        <BlogList data={whyDoTheseMistakesHappenListData} />
      </div>

      <Spacing md="20" lg="20" />
      <p>{t(`blogs.101.innerHTML.notes2`)}</p>

      <Spacing md="20" lg="20" />
      <h2>{t(`blogs.101.innerHTML.kidneyNeedsSolution.title`)}</h2>
      <p>{t(`blogs.101.innerHTML.kidneyNeedsSolution.description`)}</p>

      <Spacing md="20" lg="20" />
      <h2>{t(`blogs.101.innerHTML.colorCodedEnvelopes.title`)}</h2>
      <p>{t(`blogs.101.innerHTML.colorCodedEnvelopes.description`)}</p>
      <div className="cs_blog_un_ord_list">
        <BlogList data={colorCodedEnvelopesListData} />
      </div>

      <Spacing md="30" lg="30" />
      <h2>{t(`blogs.101.innerHTML.clearInstructions.title`)}</h2>
      <p>{t(`blogs.101.innerHTML.clearInstructions.description`)}</p>
      <div className="cs_blog_un_ord_list">
        <BlogList data={clearInstructionsListData} />
      </div>

      <Spacing md="30" lg="30" />
      <h2>{t(`blogs.101.innerHTML.specialCareForColdChainMedicines.title`)}</h2>
      <p>
        {t(`blogs.101.innerHTML.specialCareForColdChainMedicines.description`)}
      </p>

      <Spacing md="20" lg="20" />
      <h2>{t(`blogs.101.innerHTML.remember.title`)}</h2>
      <p>{t(`blogs.101.innerHTML.remember.description`)}</p>

      <Spacing md="20" lg="20" />
      <h2>{t(`blogs.101.innerHTML.benefitsSpecializedPackaging.title`)}</h2>
      <div className="cs_blog_un_ord_list">
        <BlogList data={benefitsSpecializedPackagingListData} />
      </div>

      <Spacing md="30" lg="30" />
      <h2>{t(`blogs.101.innerHTML.howThisHelpsYou.title`)}</h2>
      <p>{t(`blogs.101.innerHTML.howThisHelpsYou.description`)}</p>
      <div className="cs_blog_un_ord_list">
        <BlogList data={howThisHelpsYouListData} />
      </div>

      <Spacing md="30" lg="30" />
      <h2>{t(`blogs.101.innerHTML.beyondThePackaging.title`)}</h2>
      <p>{t(`blogs.101.innerHTML.beyondThePackaging.description`)}</p>
      <div className="cs_blog_un_ord_list">
        <BlogList data={beyondThePackagingListData} />
      </div>

      <Spacing md="30" lg="30" />
      <h2>{t(`blogs.101.innerHTML.conclusion.title`)}</h2>
      <p>{t(`blogs.101.innerHTML.conclusion.description.para1`)}</p>
      <p>{t(`blogs.101.innerHTML.conclusion.description.para2`)}</p>
    </>
  );
};

export default FirstBlog;
