import React from "react";
import Section from "../Section";
import BlogSectionStyle2 from "../Section/BlogSection/BlogSectionStyle2";
import Breadcrumb from "../Breadcrumb";
import { pageTitle } from "../../helpers/PageTitle";
import { allBlogListDatas } from "../../constants/common-contents";

export default function Blog() {
  pageTitle("Blog");

  return (
    <>
      <Section topMd={170} bottomMd={96} bottomLg={70}>
        <Breadcrumb title="KidneyNeeds Blogs" />
      </Section>
      <Section bottomMd={200} bottomLg={150} bottomXl={110}>
        <BlogSectionStyle2 data={allBlogListDatas()} />
      </Section>
    </>
  );
}
