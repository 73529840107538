/* eslint-disable jsx-a11y/anchor-has-content */
import { Trans } from "react-i18next";
import i18n from "../../i18n/i18n";

export const termsConditionContentDatas = () => {
  const licenceToAccessListData = [
    {
      description: i18n.t("licenceToAccess.list1.description", {
        ns: "termsAndConditions",
      }),
    },
    {
      description: i18n.t("licenceToAccess.list2.description", {
        ns: "termsAndConditions",
      }),
    },
    {
      description: i18n.t("licenceToAccess.list3.description", {
        ns: "termsAndConditions",
      }),
    },
    {
      description: i18n.t("licenceToAccess.list4.description", {
        ns: "termsAndConditions",
      }),
    },
    {
      description: i18n.t("licenceToAccess.list5.description", {
        ns: "termsAndConditions",
      }),
    },
    {
      description: i18n.t("licenceToAccess.list6.description", {
        ns: "termsAndConditions",
      }),
    },
  ];

  const yourConductListData = [
    {
      description: i18n.t("yourConduct.list1.description", {
        ns: "termsAndConditions",
      }),
    },
    {
      description: i18n.t("yourConduct.list2.description", {
        ns: "termsAndConditions",
      }),
    },
    {
      description: i18n.t("yourConduct.list3.description", {
        ns: "termsAndConditions",
      }),
    },
  ];

  const yourAccountListData = [
    {
      description: i18n.t("yourAccount.list1.description", {
        ns: "termsAndConditions",
      }),
    },
    {
      description: i18n.t("yourAccount.list2.description", {
        ns: "termsAndConditions",
      }),
    },
    {
      description: i18n.t("yourAccount.list3.description", {
        ns: "termsAndConditions",
      }),
    },
    {
      description: i18n.t("yourAccount.list4.description", {
        ns: "termsAndConditions",
      }),
    },
    {
      description: i18n.t("yourAccount.list5.description", {
        ns: "termsAndConditions",
      }),
    },
    {
      description: i18n.t("yourAccount.list6.description", {
        ns: "termsAndConditions",
      }),
    },
    {
      description: i18n.t("yourAccount.list7.description", {
        ns: "termsAndConditions",
      }),
    },
    {
      description: i18n.t("yourAccount.list8.description", {
        ns: "termsAndConditions",
      }),
    },
    {
      description: i18n.t("yourAccount.list9.description", {
        ns: "termsAndConditions",
      }),
    },
    {
      description: i18n.t("yourAccount.list10.description", {
        ns: "termsAndConditions",
      }),
    },
  ];

  const unavailabilityOfProductListData = [
    {
      description: i18n.t("unavailabilityOfProduct.list1.description", {
        ns: "termsAndConditions",
      }),
    },
    {
      description: i18n.t("unavailabilityOfProduct.list2.description", {
        ns: "termsAndConditions",
      }),
    },
    {
      description: i18n.t("unavailabilityOfProduct.list3.description", {
        ns: "termsAndConditions",
      }),
    },
  ];

  const deliveryListData = [
    {
      description: i18n.t("delivery.list1.description", {
        ns: "termsAndConditions",
      }),
    },
    {
      description: i18n.t("delivery.list2.description", {
        ns: "termsAndConditions",
      }),
    },
    {
      description: i18n.t("delivery.list3.description", {
        ns: "termsAndConditions",
      }),
    },
  ];

  const returnNotApplicableListData = [
    {
      description: i18n.t("returnNotApplicable.list1.description", {
        ns: "termsAndConditions",
      }),
    },
    {
      description: i18n.t("returnNotApplicable.list2.description", {
        ns: "termsAndConditions",
      }),
    },
    {
      description: i18n.t("returnNotApplicable.list3.description", {
        ns: "termsAndConditions",
      }),
    },
    {
      description: i18n.t("returnNotApplicable.list4.description", {
        ns: "termsAndConditions",
      }),
    },
    {
      description: i18n.t("returnNotApplicable.list5.description", {
        ns: "termsAndConditions",
      }),
    },
    {
      description: i18n.t("returnNotApplicable.list6.description", {
        ns: "termsAndConditions",
      }),
    },
  ];

  const medicineDeliveryPolicyListData = [
    {
      description: i18n.t("medicineDeliveryPolicy.list1.description", {
        ns: "termsAndConditions",
      }),
    },
    {
      description: i18n.t("medicineDeliveryPolicy.list2.description", {
        ns: "termsAndConditions",
      }),
    },
    {
      description: i18n.t("medicineDeliveryPolicy.list3.description", {
        ns: "termsAndConditions",
      }),
    },
    {
      description: i18n.t("medicineDeliveryPolicy.list4.description", {
        ns: "termsAndConditions",
      }),
    },
    {
      description: i18n.t("medicineDeliveryPolicy.list5.description", {
        ns: "termsAndConditions",
      }),
    },
  ];

  const diagnosticServicePolicyListData = [
    {
      description: i18n.t("diagnosticServicePolicy.list1.description", {
        ns: "termsAndConditions",
      }),
    },
    {
      description: i18n.t("diagnosticServicePolicy.list2.description", {
        ns: "termsAndConditions",
      }),
    },
    {
      description: i18n.t("diagnosticServicePolicy.list3.description", {
        ns: "termsAndConditions",
      }),
    },
    {
      description: i18n.t("diagnosticServicePolicy.list4.description", {
        ns: "termsAndConditions",
      }),
    },
    {
      description: i18n.t("diagnosticServicePolicy.list5.description", {
        ns: "termsAndConditions",
      }),
    },
    {
      description: i18n.t("diagnosticServicePolicy.list6.description", {
        ns: "termsAndConditions",
      }),
    },
  ];

  const counsellingServicePolicyListData = [
    {
      description: i18n.t("counsellingServicePolicy.list1.description", {
        ns: "termsAndConditions",
      }),
    },
    {
      description: i18n.t("counsellingServicePolicy.list2.description", {
        ns: "termsAndConditions",
      }),
    },
    {
      description: i18n.t("counsellingServicePolicy.list3.description", {
        ns: "termsAndConditions",
      }),
    },
    {
      description: i18n.t("counsellingServicePolicy.list4.description", {
        ns: "termsAndConditions",
      }),
    },
    {
      description: i18n.t("counsellingServicePolicy.list5.description", {
        ns: "termsAndConditions",
      }),
    },
    {
      description: i18n.t("counsellingServicePolicy.list6.description", {
        ns: "termsAndConditions",
      }),
    },
  ];

  const educationalServicePolicyListData = [
    {
      description: i18n.t("educationalServicePolicy.list1.description", {
        ns: "termsAndConditions",
      }),
    },
    {
      description: i18n.t("educationalServicePolicy.list2.description", {
        ns: "termsAndConditions",
      }),
    },
    {
      description: i18n.t("educationalServicePolicy.list3.description", {
        ns: "termsAndConditions",
      }),
    },
    {
      description: i18n.t("educationalServicePolicy.list4.description", {
        ns: "termsAndConditions",
      }),
    },
  ];

  const ourAddressListData = [
    {
      title: i18n.t("ourAddress.list1.title", {
        ns: "termsAndConditions",
      }),
      description: (
        <span className="cs_custom-para-link">
          <Trans
            i18nKey={i18n.t("ourAddress.list1.description", {
              ns: "termsAndConditions",
            })}
            components={{
              anchorText: (
                <a
                  href="https://www.kidneyneeds.com/"
                  target="_blank"
                  rel="noreferrer"
                />
              ),
            }}
          />
        </span>
      ),
    },
    {
      title: i18n.t("ourAddress.list2.title", {
        ns: "termsAndConditions",
      }),
      description: (
        <span className="cs_custom-para-link">
          <Trans
            i18nKey={i18n.t("ourAddress.list2.description", {
              ns: "termsAndConditions",
            })}
            components={{
              anchorText: (
                <a
                  href="mailto:test@test.com"
                  target="_blank"
                  rel="noreferrer"
                />
              ),
            }}
          />
        </span>
      ),
    },
    {
      title: i18n.t("ourAddress.list3.title", {
        ns: "termsAndConditions",
      }),
      description: i18n.t("ourAddress.list3.description", {
        ns: "termsAndConditions",
      }),
    },
  ];

  return {
    licenceToAccessListData,
    yourConductListData,
    yourAccountListData,
    unavailabilityOfProductListData,
    deliveryListData,
    returnNotApplicableListData,
    medicineDeliveryPolicyListData,
    diagnosticServicePolicyListData,
    counsellingServicePolicyListData,
    educationalServicePolicyListData,
    ourAddressListData,
  };
};
