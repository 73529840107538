import React from "react";
import { secondBlogContentDatas } from "./secondBlogContents";
import { Trans, useTranslation } from "react-i18next";
import Spacing from "../../../../components/Spacing";
import BlogList from "../../../Blogs/BlogComponents/BlogList";

const SecondBlog = () => {
  const { t } = useTranslation(["medicineBlogs"]);
  const {
    perilsOfSubstandardMedicationsListData,
    makingOfQualityMedicationListData,
    understandingManufacturingModelsListData,
    patentedBrandedGenericListData,
    kidneyNeedsYourGuardianOfQualityListData,
  } = secondBlogContentDatas();

  return (
    <>
      <h2>{t(`blogs.102.innerHTML.introduction.title`)}</h2>
      <p>{t(`blogs.102.innerHTML.introduction.description`)}</p>

      <Spacing md="20" lg="20" />
      <h2>{t(`blogs.102.innerHTML.fragileBalance.title`)}</h2>
      <p>{t(`blogs.102.innerHTML.fragileBalance.description`)}</p>

      <Spacing md="20" lg="20" />
      <h2>{t(`blogs.102.innerHTML.perilsOfSubstandardMedications.title`)}</h2>
      <p>
        {t(`blogs.102.innerHTML.perilsOfSubstandardMedications.description`)}
      </p>
      <div className="cs_blog_un_ord_list">
        <BlogList data={perilsOfSubstandardMedicationsListData} />
      </div>

      <Spacing md="30" lg="30" />
      <h2>
        {t(`blogs.102.innerHTML.realWorldImpactForChronicConditions.title`)}
      </h2>
      <p>
        {t(
          `blogs.102.innerHTML.realWorldImpactForChronicConditions.description.para1`
        )}
      </p>
      <p>
        <i>
          {t(
            `blogs.102.innerHTML.realWorldImpactForChronicConditions.description.para2`
          )}
        </i>
      </p>
      <p>
        {t(
          `blogs.102.innerHTML.realWorldImpactForChronicConditions.description.para3`
        )}
      </p>

      <Spacing md="20" lg="20" />
      <h2>{t(`blogs.102.innerHTML.makingOfQualityMedication.title`)}</h2>
      <p>{t(`blogs.102.innerHTML.makingOfQualityMedication.description`)}</p>
      <div className="cs_blog_un_ord_list">
        <BlogList data={makingOfQualityMedicationListData} />
      </div>

      <Spacing md="20" lg="20" />
      <h2>{t(`blogs.102.innerHTML.understandingManufacturingModels.title`)}</h2>
      <p>
        {t(`blogs.102.innerHTML.understandingManufacturingModels.description`)}
      </p>
      <div className="cs_blog_un_ord_list">
        <BlogList data={understandingManufacturingModelsListData} />
      </div>

      <Spacing md="30" lg="30" />
      <h2>
        {t(`blogs.102.innerHTML.costFactorsFromManufacturingPatients.title`)}
      </h2>
      <p>
        {t(
          `blogs.102.innerHTML.costFactorsFromManufacturingPatients.description`
        )}
      </p>

      <Spacing md="20" lg="20" />
      <h2>{t(`blogs.102.innerHTML.patentedBrandedGeneric.title`)}</h2>
      <p>{t(`blogs.102.innerHTML.patentedBrandedGeneric.description`)}</p>
      <div className="cs_blog_un_ord_list">
        <BlogList data={patentedBrandedGenericListData} />
      </div>

      <Spacing md="30" lg="30" />
      <h2>
        {t(`blogs.102.innerHTML.areGenericsSameBrandedMedications.title`)}
      </h2>
      <p>
        {t(
          `blogs.102.innerHTML.areGenericsSameBrandedMedications.description.para1`
        )}
      </p>
      <p>
        <Trans
          i18nKey={t(
            "blogs.102.innerHTML.areGenericsSameBrandedMedications.description.para2"
          )}
          components={{
            fontStyle: <i />,
          }}
        />
      </p>
      <p>
        <i>
          {t(
            `blogs.102.innerHTML.areGenericsSameBrandedMedications.description.para3`
          )}
        </i>
      </p>

      <Spacing md="20" lg="20" />
      <h2>{t(`blogs.102.innerHTML.kidneyNeedsYourGuardianOfQuality.title`)}</h2>
      <p>
        {t(`blogs.102.innerHTML.kidneyNeedsYourGuardianOfQuality.description`)}
      </p>
      <div className="cs_blog_un_ord_list">
        <BlogList data={kidneyNeedsYourGuardianOfQualityListData} />
      </div>

      <Spacing md="30" lg="30" />
      <h2>{t(`blogs.102.innerHTML.beyondTheBasics.title`)}</h2>
      <p>{t(`blogs.102.innerHTML.beyondTheBasics.description`)}</p>

      <Spacing md="30" lg="30" />
      <h2>
        {t(`blogs.102.innerHTML.patientEmpowermentThroughKnowledge.title`)}
      </h2>
      <p>
        {t(
          `blogs.102.innerHTML.patientEmpowermentThroughKnowledge.description`
        )}
      </p>

      <Spacing md="30" lg="30" />
      <h2>{t(`blogs.102.innerHTML.distinctivePackaging.title`)}</h2>
      <p>{t(`blogs.102.innerHTML.distinctivePackaging.description.para1`)}</p>
      <p>
        <Trans
          i18nKey={t(
            "blogs.102.innerHTML.distinctivePackaging.description.para2"
          )}
          components={{
            fontStyle: <i />,
          }}
        />
      </p>

      <Spacing md="30" lg="30" />
      <h2>{t(`blogs.102.innerHTML.conclusion.title`)}</h2>
      <p>{t(`blogs.102.innerHTML.conclusion.description`)}</p>
    </>
  );
};

export default SecondBlog;
