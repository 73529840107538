/* eslint-disable jsx-a11y/anchor-has-content */
import { Trans } from "react-i18next";
import i18n from "../../i18n/i18n";

export const privacyPolicyContentDatas = () => {
  const privacyPolicyListData = [
    {
      title: i18n.t("privacyPolicy.list1.title", {
        ns: "privacyPolicy",
      }),
      childList: [
        {
          description: (
            <span className="cs_custom-para-link">
              <Trans
                i18nKey={i18n.t(
                  "privacyPolicy.list1.subLists.list1.description",
                  {
                    ns: "privacyPolicy",
                  }
                )}
                components={{
                  anchorText: (
                    <a
                      href="https://www.kidneyneeds.com/"
                      target="_blank"
                      rel="noreferrer"
                    />
                  ),
                }}
              />
            </span>
          ),
        },
        {
          description: i18n.t(
            "privacyPolicy.list1.subLists.list2.description",
            {
              ns: "privacyPolicy",
            }
          ),
        },
        {
          description: i18n.t(
            "privacyPolicy.list1.subLists.list3.description",
            {
              ns: "privacyPolicy",
            }
          ),
        },
        {
          description: (
            <span className="cs_custom-para-link">
              <Trans
                i18nKey={i18n.t(
                  "privacyPolicy.list1.subLists.list4.description",
                  {
                    ns: "privacyPolicy",
                  }
                )}
                components={{
                  anchorText: (
                    <a
                      href="https://www.kidneyneeds.com/"
                      target="_blank"
                      rel="noreferrer"
                    />
                  ),
                }}
              />
            </span>
          ),
        },
        {
          description: (
            <span className="cs_custom-para-link">
              <Trans
                i18nKey={i18n.t(
                  "privacyPolicy.list1.subLists.list5.description",
                  {
                    ns: "privacyPolicy",
                  }
                )}
                components={{
                  anchorText: (
                    <a
                      href="https://www.kidneyneeds.com/"
                      target="_blank"
                      rel="noreferrer"
                    />
                  ),
                }}
              />
            </span>
          ),
        },
        {
          description: (
            <span className="cs_custom-para-link">
              <Trans
                i18nKey={i18n.t(
                  "privacyPolicy.list1.subLists.list6.description",
                  {
                    ns: "privacyPolicy",
                  }
                )}
                components={{
                  anchorText: (
                    <a
                      href="https://www.kidneyneeds.com/"
                      target="_blank"
                      rel="noreferrer"
                    />
                  ),
                }}
              />
            </span>
          ),
        },
      ],
    },
    {
      title: i18n.t("privacyPolicy.list2.title", {
        ns: "privacyPolicy",
      }),
      childList: [
        {
          title: i18n.t("privacyPolicy.list2.subLists.list1.title", {
            ns: "privacyPolicy",
          }),
          description: i18n.t(
            "privacyPolicy.list2.subLists.list1.description",
            {
              ns: "privacyPolicy",
            }
          ),
        },
        {
          title: i18n.t("privacyPolicy.list2.subLists.list2.title", {
            ns: "privacyPolicy",
          }),
          subChildList: [
            {
              title: i18n.t(
                "privacyPolicy.list2.subLists.list2.subLists.list1.title",
                {
                  ns: "privacyPolicy",
                }
              ),
              leastChild: [
                {
                  description: i18n.t(
                    "privacyPolicy.list2.subLists.list2.subLists.list1.subLists.list1.description",
                    {
                      ns: "privacyPolicy",
                    }
                  ),
                },
                {
                  description: i18n.t(
                    "privacyPolicy.list2.subLists.list2.subLists.list1.subLists.list2.description",
                    {
                      ns: "privacyPolicy",
                    }
                  ),
                },
                {
                  description: i18n.t(
                    "privacyPolicy.list2.subLists.list2.subLists.list1.subLists.list3.description",
                    {
                      ns: "privacyPolicy",
                    }
                  ),
                },
                {
                  description: i18n.t(
                    "privacyPolicy.list2.subLists.list2.subLists.list1.subLists.list4.description",
                    {
                      ns: "privacyPolicy",
                    }
                  ),
                },
                {
                  description: i18n.t(
                    "privacyPolicy.list2.subLists.list2.subLists.list1.subLists.list5.description",
                    {
                      ns: "privacyPolicy",
                    }
                  ),
                },
                {
                  description: i18n.t(
                    "privacyPolicy.list2.subLists.list2.subLists.list1.subLists.list6.description",
                    {
                      ns: "privacyPolicy",
                    }
                  ),
                },
                {
                  description: i18n.t(
                    "privacyPolicy.list2.subLists.list2.subLists.list1.subLists.list7.description",
                    {
                      ns: "privacyPolicy",
                    }
                  ),
                },
                {
                  description: i18n.t(
                    "privacyPolicy.list2.subLists.list2.subLists.list1.subLists.list8.description",
                    {
                      ns: "privacyPolicy",
                    }
                  ),
                },
                {
                  description: i18n.t(
                    "privacyPolicy.list2.subLists.list2.subLists.list1.subLists.list9.description",
                    {
                      ns: "privacyPolicy",
                    }
                  ),
                },
                {
                  description: i18n.t(
                    "privacyPolicy.list2.subLists.list2.subLists.list1.subLists.list10.description",
                    {
                      ns: "privacyPolicy",
                    }
                  ),
                },
                {
                  description: i18n.t(
                    "privacyPolicy.list2.subLists.list2.subLists.list1.subLists.list11.description",
                    {
                      ns: "privacyPolicy",
                    }
                  ),
                },
              ],
            },
            {
              title: i18n.t(
                "privacyPolicy.list2.subLists.list2.subLists.list2.title",
                {
                  ns: "privacyPolicy",
                }
              ),
              leastChild: [
                {
                  description: i18n.t(
                    "privacyPolicy.list2.subLists.list2.subLists.list2.subLists.list1.description",
                    {
                      ns: "privacyPolicy",
                    }
                  ),
                },
                {
                  description: i18n.t(
                    "privacyPolicy.list2.subLists.list2.subLists.list2.subLists.list2.description",
                    {
                      ns: "privacyPolicy",
                    }
                  ),
                },
                {
                  description: i18n.t(
                    "privacyPolicy.list2.subLists.list2.subLists.list2.subLists.list3.description",
                    {
                      ns: "privacyPolicy",
                    }
                  ),
                },
                {
                  description: i18n.t(
                    "privacyPolicy.list2.subLists.list2.subLists.list2.subLists.list4.description",
                    {
                      ns: "privacyPolicy",
                    }
                  ),
                },
                {
                  description: i18n.t(
                    "privacyPolicy.list2.subLists.list2.subLists.list2.subLists.list5.description",
                    {
                      ns: "privacyPolicy",
                    }
                  ),
                },
                {
                  description: i18n.t(
                    "privacyPolicy.list2.subLists.list2.subLists.list2.subLists.list6.description",
                    {
                      ns: "privacyPolicy",
                    }
                  ),
                },
                {
                  description: i18n.t(
                    "privacyPolicy.list2.subLists.list2.subLists.list2.subLists.list7.description",
                    {
                      ns: "privacyPolicy",
                    }
                  ),
                },
                {
                  description: i18n.t(
                    "privacyPolicy.list2.subLists.list2.subLists.list2.subLists.list8.description",
                    {
                      ns: "privacyPolicy",
                    }
                  ),
                },
              ],
            },
            {
              title: i18n.t(
                "privacyPolicy.list2.subLists.list2.subLists.list3.title",
                {
                  ns: "privacyPolicy",
                }
              ),
              leastChild: [
                {
                  description: i18n.t(
                    "privacyPolicy.list2.subLists.list2.subLists.list3.subLists.list1.description",
                    {
                      ns: "privacyPolicy",
                    }
                  ),
                },
                {
                  description: i18n.t(
                    "privacyPolicy.list2.subLists.list2.subLists.list3.subLists.list2.description",
                    {
                      ns: "privacyPolicy",
                    }
                  ),
                },
                {
                  description: i18n.t(
                    "privacyPolicy.list2.subLists.list2.subLists.list3.subLists.list3.description",
                    {
                      ns: "privacyPolicy",
                    }
                  ),
                },
                {
                  description: i18n.t(
                    "privacyPolicy.list2.subLists.list2.subLists.list3.subLists.list4.description",
                    {
                      ns: "privacyPolicy",
                    }
                  ),
                },
                {
                  description: i18n.t(
                    "privacyPolicy.list2.subLists.list2.subLists.list3.subLists.list5.description",
                    {
                      ns: "privacyPolicy",
                    }
                  ),
                },
                {
                  description: i18n.t(
                    "privacyPolicy.list2.subLists.list2.subLists.list3.subLists.list6.description",
                    {
                      ns: "privacyPolicy",
                    }
                  ),
                },
              ],
            },
            {
              title: i18n.t(
                "privacyPolicy.list2.subLists.list2.subLists.list4.title",
                {
                  ns: "privacyPolicy",
                }
              ),
              leastChild: [
                {
                  description: i18n.t(
                    "privacyPolicy.list2.subLists.list2.subLists.list4.subLists.list1.description",
                    {
                      ns: "privacyPolicy",
                    }
                  ),
                },
                {
                  description: i18n.t(
                    "privacyPolicy.list2.subLists.list2.subLists.list4.subLists.list2.description",
                    {
                      ns: "privacyPolicy",
                    }
                  ),
                },
                {
                  description: i18n.t(
                    "privacyPolicy.list2.subLists.list2.subLists.list4.subLists.list3.description",
                    {
                      ns: "privacyPolicy",
                    }
                  ),
                },
                {
                  description: i18n.t(
                    "privacyPolicy.list2.subLists.list2.subLists.list4.subLists.list4.description",
                    {
                      ns: "privacyPolicy",
                    }
                  ),
                },
                {
                  description: i18n.t(
                    "privacyPolicy.list2.subLists.list2.subLists.list4.subLists.list5.description",
                    {
                      ns: "privacyPolicy",
                    }
                  ),
                },
                {
                  description: i18n.t(
                    "privacyPolicy.list2.subLists.list2.subLists.list4.subLists.list6.description",
                    {
                      ns: "privacyPolicy",
                    }
                  ),
                },
              ],
            },
            {
              title: i18n.t(
                "privacyPolicy.list2.subLists.list2.subLists.list5.title",
                {
                  ns: "privacyPolicy",
                }
              ),
              leastChild: [
                {
                  description: i18n.t(
                    "privacyPolicy.list2.subLists.list2.subLists.list5.subLists.list1.description",
                    {
                      ns: "privacyPolicy",
                    }
                  ),
                },
                {
                  description: i18n.t(
                    "privacyPolicy.list2.subLists.list2.subLists.list5.subLists.list2.description",
                    {
                      ns: "privacyPolicy",
                    }
                  ),
                },
                {
                  description: i18n.t(
                    "privacyPolicy.list2.subLists.list2.subLists.list5.subLists.list3.description",
                    {
                      ns: "privacyPolicy",
                    }
                  ),
                },
                {
                  description: i18n.t(
                    "privacyPolicy.list2.subLists.list2.subLists.list5.subLists.list4.description",
                    {
                      ns: "privacyPolicy",
                    }
                  ),
                },
                {
                  description: i18n.t(
                    "privacyPolicy.list2.subLists.list2.subLists.list5.subLists.list5.description",
                    {
                      ns: "privacyPolicy",
                    }
                  ),
                },
              ],
            },
          ],
        },
        {
          title: i18n.t(
            "privacyPolicy.list2.subLists.list2.subLists.list6.title",
            {
              ns: "privacyPolicy",
            }
          ),
          subChildList: [
            {
              description: i18n.t(
                "privacyPolicy.list2.subLists.list2.subLists.list6.subLists.list1.description",
                {
                  ns: "privacyPolicy",
                }
              ),
            },
            {
              description: i18n.t(
                "privacyPolicy.list2.subLists.list2.subLists.list6.subLists.list2.description",
                {
                  ns: "privacyPolicy",
                }
              ),
            },
            {
              description: i18n.t(
                "privacyPolicy.list2.subLists.list2.subLists.list6.subLists.list3.description",
                {
                  ns: "privacyPolicy",
                }
              ),
            },
          ],
        },
        {
          title: i18n.t(
            "privacyPolicy.list2.subLists.list2.subLists.list7.title",
            {
              ns: "privacyPolicy",
            }
          ),
          description: i18n.t(
            "privacyPolicy.list2.subLists.list2.subLists.list7.description",
            {
              ns: "privacyPolicy",
            }
          ),
        },
      ],
    },
    {
      title: i18n.t("privacyPolicy.list3.title", {
        ns: "privacyPolicy",
      }),
      description: i18n.t("privacyPolicy.list3.description", {
        ns: "privacyPolicy",
      }),
      childList: [
        {
          description: i18n.t(
            "privacyPolicy.list3.subLists.list1.description",
            {
              ns: "privacyPolicy",
            }
          ),
        },
        {
          description: i18n.t(
            "privacyPolicy.list3.subLists.list2.description",
            {
              ns: "privacyPolicy",
            }
          ),
        },
        {
          description: i18n.t(
            "privacyPolicy.list3.subLists.list3.description",
            {
              ns: "privacyPolicy",
            }
          ),
        },
        {
          description: i18n.t(
            "privacyPolicy.list3.subLists.list4.description",
            {
              ns: "privacyPolicy",
            }
          ),
        },
        {
          description: i18n.t(
            "privacyPolicy.list3.subLists.list5.description",
            {
              ns: "privacyPolicy",
            }
          ),
        },
        {
          description: i18n.t(
            "privacyPolicy.list3.subLists.list6.description",
            {
              ns: "privacyPolicy",
            }
          ),
        },
        {
          description: i18n.t(
            "privacyPolicy.list3.subLists.list7.description",
            {
              ns: "privacyPolicy",
            }
          ),
        },
        {
          description: i18n.t(
            "privacyPolicy.list3.subLists.list8.description",
            {
              ns: "privacyPolicy",
            }
          ),
        },
        {
          description: i18n.t(
            "privacyPolicy.list3.subLists.list9.description",
            {
              ns: "privacyPolicy",
            }
          ),
        },
        {
          description: i18n.t(
            "privacyPolicy.list3.subLists.list10.description",
            {
              ns: "privacyPolicy",
            }
          ),
        },
        {
          description: i18n.t(
            "privacyPolicy.list3.subLists.list11.description",
            {
              ns: "privacyPolicy",
            }
          ),
        },
        {
          description: i18n.t(
            "privacyPolicy.list3.subLists.list12.description",
            {
              ns: "privacyPolicy",
            }
          ),
        },
        {
          description: i18n.t(
            "privacyPolicy.list3.subLists.list13.description",
            {
              ns: "privacyPolicy",
            }
          ),
        },
        {
          description: i18n.t(
            "privacyPolicy.list3.subLists.list14.description",
            {
              ns: "privacyPolicy",
            }
          ),
        },
        {
          description: i18n.t(
            "privacyPolicy.list3.subLists.list15.description",
            {
              ns: "privacyPolicy",
            }
          ),
        },
        {
          description: i18n.t(
            "privacyPolicy.list3.subLists.list16.description",
            {
              ns: "privacyPolicy",
            }
          ),
        },
        {
          description: i18n.t(
            "privacyPolicy.list3.subLists.list17.description",
            {
              ns: "privacyPolicy",
            }
          ),
        },
        {
          description: i18n.t(
            "privacyPolicy.list3.subLists.list18.description",
            {
              ns: "privacyPolicy",
            }
          ),
        },
        {
          description: i18n.t(
            "privacyPolicy.list3.subLists.list19.description",
            {
              ns: "privacyPolicy",
            }
          ),
        },
        {
          description: i18n.t(
            "privacyPolicy.list3.subLists.list20.description",
            {
              ns: "privacyPolicy",
            }
          ),
        },
        {
          description: i18n.t(
            "privacyPolicy.list3.subLists.list21.description",
            {
              ns: "privacyPolicy",
            }
          ),
        },
        {
          description: i18n.t(
            "privacyPolicy.list3.subLists.list22.description",
            {
              ns: "privacyPolicy",
            }
          ),
        },
      ],
    },
    {
      title: i18n.t("privacyPolicy.list4.title", {
        ns: "privacyPolicy",
      }),
      description: i18n.t("privacyPolicy.list4.description", {
        ns: "privacyPolicy",
      }),
      childList: [
        {
          title: i18n.t("privacyPolicy.list4.subLists.list1.title", {
            ns: "privacyPolicy",
          }),
          description: i18n.t(
            "privacyPolicy.list4.subLists.list1.description",
            {
              ns: "privacyPolicy",
            }
          ),
        },
        {
          title: i18n.t("privacyPolicy.list4.subLists.list2.title", {
            ns: "privacyPolicy",
          }),
          description: i18n.t(
            "privacyPolicy.list4.subLists.list2.description",
            {
              ns: "privacyPolicy",
            }
          ),
        },
        {
          title: i18n.t("privacyPolicy.list4.subLists.list3.title", {
            ns: "privacyPolicy",
          }),
          description: i18n.t(
            "privacyPolicy.list4.subLists.list3.description",
            {
              ns: "privacyPolicy",
            }
          ),
        },
        {
          title: i18n.t("privacyPolicy.list4.subLists.list4.title", {
            ns: "privacyPolicy",
          }),
          description: i18n.t(
            "privacyPolicy.list4.subLists.list4.description",
            {
              ns: "privacyPolicy",
            }
          ),
        },
        {
          title: i18n.t("privacyPolicy.list4.subLists.list5.title", {
            ns: "privacyPolicy",
          }),
          description: i18n.t(
            "privacyPolicy.list4.subLists.list5.description",
            {
              ns: "privacyPolicy",
            }
          ),
        },
        {
          title: i18n.t("privacyPolicy.list4.subLists.list6.title", {
            ns: "privacyPolicy",
          }),
          description: i18n.t(
            "privacyPolicy.list4.subLists.list6.description",
            {
              ns: "privacyPolicy",
            }
          ),
        },
        {
          description: i18n.t("privacyPolicy.list4.notes", {
            ns: "privacyPolicy",
          }),
        },
      ],
    },
    {
      title: i18n.t("privacyPolicy.list5.title", {
        ns: "privacyPolicy",
      }),
      childList: [
        {
          description: i18n.t(
            "privacyPolicy.list5.subLists.list1.description",
            {
              ns: "privacyPolicy",
            }
          ),
        },
        {
          description: i18n.t(
            "privacyPolicy.list5.subLists.list2.description",
            {
              ns: "privacyPolicy",
            }
          ),
        },
        {
          description: i18n.t(
            "privacyPolicy.list5.subLists.list3.description",
            {
              ns: "privacyPolicy",
            }
          ),
        },
      ],
    },
    {
      title: i18n.t("privacyPolicy.list6.title", {
        ns: "privacyPolicy",
      }),
      description: (
        <span className="cs_custom-para-link">
          <Trans
            i18nKey={i18n.t("privacyPolicy.list6.description", {
              ns: "privacyPolicy",
            })}
            components={{
              anchorText: (
                <a
                  href="https://www.kidneyneeds.com/"
                  target="_blank"
                  rel="noreferrer"
                />
              ),
            }}
          />
        </span>
      ),
    },
    {
      title: i18n.t("privacyPolicy.list7.title", {
        ns: "privacyPolicy",
      }),
      description: i18n.t("privacyPolicy.list7.description", {
        ns: "privacyPolicy",
      }),
      childList: [
        {
          description: i18n.t("privacyPolicy.list7.notes.para1", {
            ns: "privacyPolicy",
          }),
          subChildList: [
            {
              description: i18n.t(
                "privacyPolicy.list7.notes.subLists.list1.description",
                {
                  ns: "privacyPolicy",
                }
              ),
            },
            {
              description: i18n.t(
                "privacyPolicy.list7.notes.subLists.list2.description",
                {
                  ns: "privacyPolicy",
                }
              ),
            },
          ],
        },
        {
          description: i18n.t("privacyPolicy.list7.notes.para2", {
            ns: "privacyPolicy",
          }),
        },
      ],
    },
    {
      title: i18n.t("privacyPolicy.list8.title", {
        ns: "privacyPolicy",
      }),
      description: i18n.t("privacyPolicy.list8.description", {
        ns: "privacyPolicy",
      }),
    },
    {
      title: i18n.t("privacyPolicy.list9.title", {
        ns: "privacyPolicy",
      }),
      description: i18n.t("privacyPolicy.list9.description", {
        ns: "privacyPolicy",
      }),
      childList: [
        {
          title: i18n.t("privacyPolicy.list9.subLists.list1.title", {
            ns: "privacyPolicy",
          }),
          description: i18n.t(
            "privacyPolicy.list9.subLists.list1.description",
            {
              ns: "privacyPolicy",
            }
          ),
        },
        {
          title: i18n.t("privacyPolicy.list9.subLists.list2.title", {
            ns: "privacyPolicy",
          }),
          description: i18n.t(
            "privacyPolicy.list9.subLists.list2.description",
            {
              ns: "privacyPolicy",
            }
          ),
        },
        {
          title: i18n.t("privacyPolicy.list9.subLists.list3.title", {
            ns: "privacyPolicy",
          }),
          description: i18n.t(
            "privacyPolicy.list9.subLists.list3.description",
            {
              ns: "privacyPolicy",
            }
          ),
        },
        {
          title: i18n.t("privacyPolicy.list9.subLists.list4.title", {
            ns: "privacyPolicy",
          }),
          description: i18n.t(
            "privacyPolicy.list9.subLists.list4.description",
            {
              ns: "privacyPolicy",
            }
          ),
        },
        {
          title: i18n.t("privacyPolicy.list9.subLists.list5.title", {
            ns: "privacyPolicy",
          }),
          description: i18n.t(
            "privacyPolicy.list9.subLists.list5.description",
            {
              ns: "privacyPolicy",
            }
          ),
        },
        {
          title: i18n.t("privacyPolicy.list9.subLists.list6.title", {
            ns: "privacyPolicy",
          }),
          description: i18n.t(
            "privacyPolicy.list9.subLists.list6.description",
            {
              ns: "privacyPolicy",
            }
          ),
        },
        {
          title: i18n.t("privacyPolicy.list9.subLists.list7.title", {
            ns: "privacyPolicy",
          }),
          description: i18n.t(
            "privacyPolicy.list9.subLists.list7.description",
            {
              ns: "privacyPolicy",
            }
          ),
        },
        {
          title: i18n.t("privacyPolicy.list9.subLists.list8.title", {
            ns: "privacyPolicy",
          }),
          description: i18n.t(
            "privacyPolicy.list9.subLists.list8.description",
            {
              ns: "privacyPolicy",
            }
          ),
        },
      ],
    },
    {
      title: i18n.t("privacyPolicy.list10.title", {
        ns: "privacyPolicy",
      }),
      description: i18n.t("privacyPolicy.list10.description", {
        ns: "privacyPolicy",
      }),
    },
    {
      title: i18n.t("privacyPolicy.list11.title", {
        ns: "privacyPolicy",
      }),
      description: i18n.t("privacyPolicy.list11.description", {
        ns: "privacyPolicy",
      }),
      childList: [
        {
          description: (
            <Trans
              i18nKey={i18n.t("privacyPolicy.list11.notes", {
                ns: "privacyPolicy",
              })}
              components={{
                boldText: <b />,
              }}
            />
          ),
        },
      ],
    },
    {
      title: i18n.t("privacyPolicy.list12.title", {
        ns: "privacyPolicy",
      }),
      description: i18n.t("privacyPolicy.list12.description", {
        ns: "privacyPolicy",
      }),
    },
    {
      title: i18n.t("privacyPolicy.list13.title", {
        ns: "privacyPolicy",
      }),
      description: i18n.t("privacyPolicy.list13.description", {
        ns: "privacyPolicy",
      }),
      childList: [
        {
          title: i18n.t("privacyPolicy.list13.subLists.list1.title", {
            ns: "privacyPolicy",
          }),
          description: i18n.t(
            "privacyPolicy.list15.subLists.list1.description",
            {
              ns: "privacyPolicy",
            }
          ),
        },
        {
          title: i18n.t("privacyPolicy.list13.subLists.list2.title", {
            ns: "privacyPolicy",
          }),
          description: i18n.t(
            "privacyPolicy.list15.subLists.list2.description",
            {
              ns: "privacyPolicy",
            }
          ),
        },
        {
          title: i18n.t("privacyPolicy.list13.subLists.list3.title", {
            ns: "privacyPolicy",
          }),
          description: i18n.t(
            "privacyPolicy.list15.subLists.list3.description",
            {
              ns: "privacyPolicy",
            }
          ),
        },
        {
          title: i18n.t("privacyPolicy.list13.subLists.list4.title", {
            ns: "privacyPolicy",
          }),
          description: i18n.t(
            "privacyPolicy.list15.subLists.list4.description",
            {
              ns: "privacyPolicy",
            }
          ),
        },
      ],
    },
    {
      title: i18n.t("privacyPolicy.list14.title", {
        ns: "privacyPolicy",
      }),
      description: i18n.t("privacyPolicy.list14.description", {
        ns: "privacyPolicy",
      }),
    },
    {
      title: i18n.t("privacyPolicy.list15.title", {
        ns: "privacyPolicy",
      }),
      childList: [
        {
          description: i18n.t(
            "privacyPolicy.list15.subLists.list1.description",
            {
              ns: "privacyPolicy",
            }
          ),
        },
        {
          description: i18n.t(
            "privacyPolicy.list15.subLists.list2.description",
            {
              ns: "privacyPolicy",
            }
          ),
        },
        {
          description: i18n.t(
            "privacyPolicy.list15.subLists.list3.description",
            {
              ns: "privacyPolicy",
            }
          ),
        },
        {
          description: i18n.t(
            "privacyPolicy.list15.subLists.list4.description",
            {
              ns: "privacyPolicy",
            }
          ),
        },
        {
          description: i18n.t(
            "privacyPolicy.list15.subLists.list5.description",
            {
              ns: "privacyPolicy",
            }
          ),
        },
        {
          description: i18n.t(
            "privacyPolicy.list15.subLists.list6.description",
            {
              ns: "privacyPolicy",
            }
          ),
        },
      ],
    },
  ];

  return { privacyPolicyListData };
};
