import { Icon } from "@iconify/react";
import React, { useState, useRef } from "react";
import DatePicker from "react-datepicker";
import axios from "axios";

import { appointmentTemplate } from "../../constants/email-template/book-appointment";
import { toastSuccess, toastFailure } from "../../helpers/toastHelper";

const baseUrl = process.env.REACT_APP_BASE_API_URL;

export default function AppointmentForm() {
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedReason, setSelectedReason] = useState(null);

  const nameRef = useRef(null);
  const phoneNumberRef = useRef(null);
  const preferredDate = useRef(null);
  const preferredTime = useRef(null);
  const reason1 = useRef(null);
  const reason2 = useRef(null);
  const reason3 = useRef(null);

  const handleSubmit = async (e) => {
    e.preventDefault();
    // Create a new FormData object to send data as multipart/form-data
    if (
      !nameRef.current.value ||
      !phoneNumberRef.current.value ||
      !preferredTime.current.value ||
      !preferredDate.current.input.value
      (!reason1.current.checked &&
        !reason2.current.checked &&
        !reason3.current.checked)
    ) {
      toastFailure("Please fill in all required fields!");
      return;
    }

    let reason = "";
    if (reason1.current.checked) {
      reason = reason1.current.value;
    } else if (reason2.current.checked) {
      reason = reason2.current.value;
    } else if (reason3.current.checked) {
      reason = reason3.current.value;
    }

    const data = new FormData();

    data.append(
      "body",
      appointmentTemplate({
        name: nameRef.current.value,
        phoneNumber: phoneNumberRef.current.value,
        preferredDate: preferredDate.current.input.value,
        preferredTime: preferredTime.current.value,
        reason: reason,
      })
    );
    data.append("subject", "Appointment request");

    try {
      await axios.post(`${baseUrl}/contact-us`, data);
      toastSuccess("Message Sent!");
      resetForm();
    } catch (error) {
      toastFailure("Something went wrong. Please retry later.");
    }
  };

  const resetForm = () => {
    nameRef.current.value = "";
    phoneNumberRef.current.value = "";
    preferredDate.current.value = "";
    preferredTime.current.value = "";
    reason1.current.checked = "";
    reason2.current.checked = "";
    reason3.current.checked = "";
    setSelectedDate(null);
  };

  return (
    <form onSubmit={handleSubmit} className="row">
      <div className="col-lg-6">
        <label className="cs_input_label cs_heading_color">Name<sup>*</sup></label>
        <input
          required
          ref={nameRef}
          type="text"
          className="cs_form_field"
          placeholder="Arjun"
        />
        <div className="cs_height_42 cs_height_xl_25" />
      </div>
      <div className="col-lg-6">
        <label className="cs_input_label cs_heading_color">Phone Number<sup>*</sup></label>
        <input
          type="text"
          ref={phoneNumberRef}
          className="cs_form_field"
          placeholder="+91 99999 88888"
          pattern="^(\+91\s?)?[6-9]\d{9}$"
          required
        />
        <div className="cs_height_42 cs_height_xl_25" />
      </div>
      {/* <div className="col-lg-12">
        <label className="cs_input_label cs_heading_color">
          Medical Record Number
        </label>
        <input
          type="text"
          className="cs_form_field"
          placeholder="123456-7890-0987"
        />
        <div className="cs_height_42 cs_height_xl_25" />
      </div> */}
      <div className="col-lg-6">
        <label className="cs_input_label cs_heading_color">
          Preferred Date<sup>*</sup>
        </label>

        <div className="cs_with_icon_input">
          <DatePicker
            selected={selectedDate}
            onChange={(date) => setSelectedDate(date)}
            dateFormat="dd/MM/yyyy"
            ref={preferredDate}
            minDate={new Date()}
            isClearable
            placeholderText="dd/mm/yyyy"
          />
          <i>
            <Icon icon="fa6-solid:calendar-days" />
          </i>
        </div>
        <div className="cs_height_42 cs_height_xl_25" />
      </div>
      <div className="col-lg-6">
        <label className="cs_input_label cs_heading_color">
          Preferred Time<sup>*</sup>
        </label>
        <div className="cs_with_icon_input">
          <input
            ref={preferredTime}
            type="time"
            className="cs_form_field cs_timepicker"
            placeholder="10:00AM"
          />
          <i>
            <Icon icon="fa6-regular:clock" />
          </i>
        </div>
        <div className="cs_height_42 cs_height_xl_25" />
      </div>
      <div className="col-lg-12">
        <label className="cs_input_label cs_heading_color">
          Reason for Visit<sup>*</sup>
        </label>
        <div
          className="cs_radio_group"
          onChange={(event) => setSelectedReason(event.target.value)}
        >
          <div className="cs_radio_wrap">
            <input
              ref={reason1}
              className="cs_radio_input"
              type="radio"
              name="reasonForVisit"
              id="Routine Checkup"
              defaultValue="routineCheckup"
            />
            <label className="cs_radio_label" htmlFor="routineCheckup">
              Routine Checkup
            </label>
          </div>
          <div className="cs_radio_wrap">
            <input
              ref={reason2}
              className="cs_radio_input"
              type="radio"
              name="reasonForVisit"
              id="newPatientVisit"
              defaultValue="New Patient Visit"
              defaultChecked=""
            />
            <label className="cs_radio_label" htmlFor="newPatientVisit">
              New Patient Visit
            </label>
          </div>
          <div className="cs_radio_wrap">
            <input
              ref={reason3}
              className="cs_radio_input"
              type="radio"
              name="reasonForVisit"
              id="specificConcern"
              defaultValue="Specific Concern"
            />
            <label className="cs_radio_label" htmlFor="specificConcern">
              Specific Concern (Keep Privacy)
            </label>
          </div>
        </div>
        <div className="cs_height_42 cs_height_xl_25" />
      </div>
      {/* <div className="col-lg-12">
        <label className="cs_input_label cs_heading_color">Department</label>
        <div className="cs_radio_group">
          <div className="cs_radio_wrap">
            <input
              className="cs_radio_input"
              type="radio"
              name="department"
              id="pediatric"
              defaultValue="pediatric"
            />
            <label className="cs_radio_label" htmlFor="pediatric">
              Pediatric
            </label>
          </div>
          <div className="cs_radio_wrap">
            <input
              className="cs_radio_input"
              type="radio"
              name="department"
              id="obstetricsGynecology"
              defaultValue="obstetricsGynecology"
              defaultChecked=""
            />
            <label className="cs_radio_label" htmlFor="obstetricsGynecology">
              Obstetrics and Gynecology
            </label>
          </div>
          <div className="cs_radio_wrap">
            <input
              className="cs_radio_input"
              type="radio"
              name="department"
              id="cardiology"
              defaultValue="cardiology"
            />
            <label className="cs_radio_label" htmlFor="cardiology">
              Cardiology
            </label>
          </div>
          <div className="cs_radio_wrap">
            <input
              className="cs_radio_input"
              type="radio"
              name="department"
              id="neurology"
              defaultValue="neurology"
            />
            <label className="cs_radio_label" htmlFor="neurology">
              Neurology
            </label>
          </div>
        </div>
        <div className="cs_height_42 cs_height_xl_25" />
      </div> */}
      {selectedReason === "specificConcern" && (
        <div className="col-lg-12">
          <textarea
            rows={2}
            className="cs_form_field"
            placeholder="Comments (Optional)..."
          />
          <div className="cs_height_42 cs_height_xl_25" />
        </div>
      )}
      <div className="col-lg-12">
        <button className="cs_btn cs_style_1">
          <span>Submit</span>
          <i>
            <img loading="lazy" src="/images/icons/arrow_white.svg" alt="Icon" />
            <img loading="lazy" src="/images/icons/arrow_white.svg" alt="Icon" />
          </i>
        </button>
      </div>
    </form>
  );
}
