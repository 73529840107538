import React from "react";
import CustomTable from "../../../components/Table/CustomTable";
import Spacing from "../../../components/Spacing";

const BlogList = ({ data }) => {
  return (
    <ul>
      {data.map((item, index) => (
        <li key={index}>
          <b>{item.title} </b>
          {item.description && <span> {item.description}</span>}
          {item.childList && item.childList.length > 0 && (
            <ul>
              {item.childList.map((childItem, childIndex) => (
                <li key={childIndex}>
                  <b>{childItem.title} </b>
                  {childItem.description && (
                    <span> {childItem.description}</span>
                  )}
                  {childItem.subChildList &&
                    childItem.subChildList.length > 0 && (
                      <ul>
                        {childItem.subChildList.map((subItem, subIndex) => (
                          <li key={subIndex}>
                            {subItem.title && <b>{subItem.title} </b>}
                            {subItem.description && (
                              <span> {subItem.description}</span>
                            )}
                          </li>
                        ))}
                      </ul>
                    )}
                </li>
              ))}
            </ul>
          )}
          {item && item.enableTable && (
            <>
              <Spacing md="20" lg="20" />
              <CustomTable
                tableHead={item.tableHeadData || []}
                tableBody={item.tableBodyData || []}
                tableFooter={item.tableFooterData || []}
              />
            </>
          )}
        </li>
      ))}
    </ul>
  );
};

export default BlogList;
