import React, { useState } from "react";
import PopupModalComponent from "../PopupModal";

const ImageWithTextOverlay = ({
  title,
  description,
  btnRedirectText,
  imgURL,
  linkUrl,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);
  return (
    <div className="cs_img_with_txt_sec">
      <img loading="lazy" src={imgURL || "/images/home_3/blog_1.jpeg"} alt={title} />
      <div className="cs_img_with_txt_sec_info">
        <h4>{title}</h4>
        <p title={description}>{description}</p>
        {btnRedirectText && (
          <div className="cs_img_with_txt_sec_btn">
            <button
              style={{
                color: "white",
                paddingLeft: 25,
                paddingRight: 30,
                fontSize: 14,
              }}
              className="cs_btn cs_style_1"
              onClick={openModal}
            >
              <span>{btnRedirectText || "Contact us"} </span>
            </button>
          </div>
        )}
      </div>
      <PopupModalComponent isOpen={isModalOpen} onClose={closeModal} />
    </div>
  );
};

export default ImageWithTextOverlay;
