import i18n from "../../../../i18n/i18n";

export const secondBlogContentDatas = () => {
  const perilsOfSubstandardMedicationsListData = [
    {
      title: i18n.t(
        "blogs.102.innerHTML.perilsOfSubstandardMedications.list1.title",
        {
          ns: "medicineBlogs",
        }
      ),
      description: i18n.t(
        "blogs.102.innerHTML.perilsOfSubstandardMedications.list1.description",
        {
          ns: "medicineBlogs",
        }
      ),
    },
    {
      title: i18n.t(
        "blogs.102.innerHTML.perilsOfSubstandardMedications.list2.title",
        {
          ns: "medicineBlogs",
        }
      ),
      description: i18n.t(
        "blogs.102.innerHTML.perilsOfSubstandardMedications.list2.description",
        {
          ns: "medicineBlogs",
        }
      ),
    },
    {
      title: i18n.t(
        "blogs.102.innerHTML.perilsOfSubstandardMedications.list3.title",
        {
          ns: "medicineBlogs",
        }
      ),
      description: i18n.t(
        "blogs.102.innerHTML.perilsOfSubstandardMedications.list3.description",
        {
          ns: "medicineBlogs",
        }
      ),
    },
  ];

  const makingOfQualityMedicationListData = [
    {
      title: i18n.t(
        "blogs.102.innerHTML.makingOfQualityMedication.list1.title",
        {
          ns: "medicineBlogs",
        }
      ),
      description: i18n.t(
        "blogs.102.innerHTML.makingOfQualityMedication.list1.description",
        {
          ns: "medicineBlogs",
        }
      ),
    },
    {
      title: i18n.t(
        "blogs.102.innerHTML.makingOfQualityMedication.list2.title",
        {
          ns: "medicineBlogs",
        }
      ),
      description: i18n.t(
        "blogs.102.innerHTML.makingOfQualityMedication.list2.description",
        {
          ns: "medicineBlogs",
        }
      ),
    },
  ];

  const understandingManufacturingModelsListData = [
    {
      title: i18n.t(
        "blogs.102.innerHTML.understandingManufacturingModels.list1.title",
        {
          ns: "medicineBlogs",
        }
      ),
      description: i18n.t(
        "blogs.102.innerHTML.understandingManufacturingModels.list1.description",
        {
          ns: "medicineBlogs",
        }
      ),
    },
    {
      title: i18n.t(
        "blogs.102.innerHTML.understandingManufacturingModels.list2.title",
        {
          ns: "medicineBlogs",
        }
      ),
      description: i18n.t(
        "blogs.102.innerHTML.understandingManufacturingModels.list2.description",
        {
          ns: "medicineBlogs",
        }
      ),
    },
    {
      title: i18n.t(
        "blogs.102.innerHTML.understandingManufacturingModels.list3.title",
        {
          ns: "medicineBlogs",
        }
      ),
      description: i18n.t(
        "blogs.102.innerHTML.understandingManufacturingModels.list3.description",
        {
          ns: "medicineBlogs",
        }
      ),
    },
  ];

  const patentedBrandedGenericListData = [
    {
      title: i18n.t("blogs.102.innerHTML.patentedBrandedGeneric.list1.title", {
        ns: "medicineBlogs",
      }),
      description: i18n.t(
        "blogs.102.innerHTML.patentedBrandedGeneric.list1.description",
        {
          ns: "medicineBlogs",
        }
      ),
    },
    {
      title: i18n.t("blogs.102.innerHTML.patentedBrandedGeneric.list2.title", {
        ns: "medicineBlogs",
      }),
      description: i18n.t(
        "blogs.102.innerHTML.patentedBrandedGeneric.list2.description",
        {
          ns: "medicineBlogs",
        }
      ),
    },
    {
      title: i18n.t("blogs.102.innerHTML.patentedBrandedGeneric.list3.title", {
        ns: "medicineBlogs",
      }),
      description: i18n.t(
        "blogs.102.innerHTML.patentedBrandedGeneric.list3.description",
        {
          ns: "medicineBlogs",
        }
      ),
    },
  ];

  const kidneyNeedsYourGuardianOfQualityListData = [
    {
      title: i18n.t(
        "blogs.102.innerHTML.kidneyNeedsYourGuardianOfQuality.list1.title",
        {
          ns: "medicineBlogs",
        }
      ),
      description: i18n.t(
        "blogs.102.innerHTML.kidneyNeedsYourGuardianOfQuality.list1.description",
        {
          ns: "medicineBlogs",
        }
      ),
    },
    {
      title: i18n.t(
        "blogs.102.innerHTML.kidneyNeedsYourGuardianOfQuality.list2.title",
        {
          ns: "medicineBlogs",
        }
      ),
      description: i18n.t(
        "blogs.102.innerHTML.kidneyNeedsYourGuardianOfQuality.list2.description",
        {
          ns: "medicineBlogs",
        }
      ),
    },
    {
      title: i18n.t(
        "blogs.102.innerHTML.kidneyNeedsYourGuardianOfQuality.list3.title",
        {
          ns: "medicineBlogs",
        }
      ),
      description: i18n.t(
        "blogs.102.innerHTML.kidneyNeedsYourGuardianOfQuality.list3.description",
        {
          ns: "medicineBlogs",
        }
      ),
    },
    {
      title: i18n.t(
        "blogs.102.innerHTML.kidneyNeedsYourGuardianOfQuality.list4.title",
        {
          ns: "medicineBlogs",
        }
      ),
      description: i18n.t(
        "blogs.102.innerHTML.kidneyNeedsYourGuardianOfQuality.list4.description",
        {
          ns: "medicineBlogs",
        }
      ),
    },
    {
      title: i18n.t(
        "blogs.102.innerHTML.kidneyNeedsYourGuardianOfQuality.list5.title",
        {
          ns: "medicineBlogs",
        }
      ),
      description: i18n.t(
        "blogs.102.innerHTML.kidneyNeedsYourGuardianOfQuality.list5.description",
        {
          ns: "medicineBlogs",
        }
      ),
    },
  ];

  return {
    perilsOfSubstandardMedicationsListData,
    makingOfQualityMedicationListData,
    understandingManufacturingModelsListData,
    patentedBrandedGenericListData,
    kidneyNeedsYourGuardianOfQualityListData,
  };
};
