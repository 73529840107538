import React from "react";
import Section from "../../components/Section";
import BlogBreadcrumbStyle from "../../components/Breadcrumb/BlogBreadcrumbStyle";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

const BlogBreadCrumbSection = ({ jsonFileName, unUsedValues = "" }) => {
  const { t } = useTranslation([jsonFileName]);
  let { blogId } = useParams();

  if (
    blogId === "104" ||
    blogId === "107" ||
    blogId === "110" ||
    blogId === "113" ||
    blogId === "116"
  )
    blogId = "101";

  if (
    blogId === "105" ||
    blogId === "108" ||
    blogId === "111" ||
    blogId === "114" ||
    blogId === "117"
  )
    blogId = "102";

  if (
    blogId === "106" ||
    blogId === "109" ||
    blogId === "112" ||
    blogId === "115" ||
    blogId === "118"
  )
    blogId = "103";

  return (
    <Section topMd={170} bottomMd={54} bottomLg={54}>
      <BlogBreadcrumbStyle
        title={t(`blogs.${blogId}.blogHeading`)}
        unUsedValues={unUsedValues}
      />
    </Section>
  );
};

export default BlogBreadCrumbSection;
