import i18n from "../../../../i18n/i18n";

export const thirdBlogContentDatas = () => {
  const kidneyNeedsCatheterKitListData = [
    {
      title: i18n.t(
        "blogs.103.innerHTML.kidneyNeedsCatheterCareInfectionControlKit.list1.title",
        {
          ns: "cathetersBlogs",
        }
      ),
      description: i18n.t(
        "blogs.103.innerHTML.kidneyNeedsCatheterCareInfectionControlKit.list1.description",
        {
          ns: "cathetersBlogs",
        }
      ),
    },
    {
      title: i18n.t(
        "blogs.103.innerHTML.kidneyNeedsCatheterCareInfectionControlKit.list2.title",
        {
          ns: "cathetersBlogs",
        }
      ),
      description: i18n.t(
        "blogs.103.innerHTML.kidneyNeedsCatheterCareInfectionControlKit.list2.description",
        {
          ns: "cathetersBlogs",
        }
      ),
    },
    {
      title: i18n.t(
        "blogs.103.innerHTML.kidneyNeedsCatheterCareInfectionControlKit.list3.title",
        {
          ns: "cathetersBlogs",
        }
      ),
      description: i18n.t(
        "blogs.103.innerHTML.kidneyNeedsCatheterCareInfectionControlKit.list3.description",
        {
          ns: "cathetersBlogs",
        }
      ),
    },
    {
      title: i18n.t(
        "blogs.103.innerHTML.kidneyNeedsCatheterCareInfectionControlKit.list4.title",
        {
          ns: "cathetersBlogs",
        }
      ),
      description: i18n.t(
        "blogs.103.innerHTML.kidneyNeedsCatheterCareInfectionControlKit.list4.description",
        {
          ns: "cathetersBlogs",
        }
      ),
    },
    {
      title: i18n.t(
        "blogs.103.innerHTML.kidneyNeedsCatheterCareInfectionControlKit.list5.title",
        {
          ns: "cathetersBlogs",
        }
      ),
      description: i18n.t(
        "blogs.103.innerHTML.kidneyNeedsCatheterCareInfectionControlKit.list5.description",
        {
          ns: "cathetersBlogs",
        }
      ),
    },
  ];

  return { kidneyNeedsCatheterKitListData };
};
