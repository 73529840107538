import React from "react";
import { useTranslation } from "react-i18next";
import Spacing from "../../../../components/Spacing";
import BlogList from "../../../Blogs/BlogComponents/BlogList";
import { thirdBlogContentDatas } from "./thirdBlogContents";

const ThirdBlog = () => {
  const { t } = useTranslation(["cathetersBlogs"]);

  const { kidneyNeedsCatheterKitListData } = thirdBlogContentDatas();

  return (
    <>
      <p>{t(`blogs.103.innerHTML.blogDescription`)}</p>
      <Spacing md="10" lg="10" />
      <h2>{t(`blogs.103.innerHTML.understandingTheInfectionThreat.title`)}</h2>
      <p>
        {t(
          `blogs.103.innerHTML.understandingTheInfectionThreat.description.para1`
        )}
      </p>
      <p>
        {t(
          `blogs.103.innerHTML.understandingTheInfectionThreat.description.para2`
        )}
      </p>
      <Spacing md="20" lg="20" />
      <h2>{t(`blogs.103.innerHTML.kidneyNeedsPioneeringAffordable.title`)}</h2>
      <p>
        {t(`blogs.103.innerHTML.kidneyNeedsPioneeringAffordable.description`)}
      </p>
      <Spacing md="20" lg="20" />
      <h2>
        {t(
          `blogs.103.innerHTML.kidneyNeedsCatheterCareInfectionControlKit.title`
        )}
      </h2>
      <p>
        {t(
          `blogs.103.innerHTML.kidneyNeedsCatheterCareInfectionControlKit.description.para1`
        )}
      </p>
      <p>
        {t(
          `blogs.103.innerHTML.kidneyNeedsCatheterCareInfectionControlKit.description.para2`
        )}
      </p>
      <div className="cs_blog_un_ord_list">
        <BlogList data={kidneyNeedsCatheterKitListData} />
      </div>
      <Spacing md="30" lg="30" />
      <h2>{t(`blogs.103.innerHTML.empoweringPatientsImproving.title`)}</h2>
      <p>
        {t(`blogs.103.innerHTML.empoweringPatientsImproving.description.para1`)}
      </p>
      <p>
        {t(`blogs.103.innerHTML.empoweringPatientsImproving.description.para2`)}
      </p>
      <Spacing md="20" lg="20" />
      <h2>{t(`blogs.103.innerHTML.WordOfEncouragement.title`)}</h2>
      <p>{t(`blogs.103.innerHTML.WordOfEncouragement.description`)}</p>
      <Spacing md="20" lg="20" />
      <h2>{t(`blogs.103.innerHTML.ourCommitment.title`)}</h2>
      <p>{t(`blogs.103.innerHTML.ourCommitment.description`)}</p>
      <Spacing md="20" lg="20" />
      <h2>{t(`blogs.103.innerHTML.conclusion.title`)}:</h2>
      <p>
        <span>{t(`blogs.103.innerHTML.conclusion.description`)}</span>
      </p>
    </>
  );
};

export default ThirdBlog;
