import React from "react";
import { LifeLine } from "react-loading-indicators";

const Loading = ({
  color = "rgb(238, 40, 40)",
  size = "medium",
  text = "",
  textColor = "",
}) => {
  return (
    <div className="cs_loader_icon">
      <LifeLine color={color} size={size} text={text} textColor={textColor} />
    </div>
  );
};

export default Loading;
