import React from "react";

export default function List({ heading, description = "", iconUrl, data }) {
  return (
    <div
      className={`cs_list cs_style_1 ${
        description ? "cs_list_custom-style" : ""
      }`}
    >
      {heading && (
        <h2 className="cs_list_title cs_medium cs_fs_24">
          <img loading="lazy" src={iconUrl} alt="Icon" />
          {heading}
        </h2>
      )}
      {description && <p className="cs_list_sub-title"><em>{description}</em></p>}
      <ul>
        {data?.map((item, index) => (
          <li key={index}>
            {item.title && (
              <p className="cs_medium cs_heading_color mb-0">{item.title}</p>
            )}
            {item.subTitle && <p className="mb-0">{item.subTitle}</p>}
            {typeof item === "object" && "childList" in item && (
              <ul style={{ marginTop: "24px" }}>
                {item?.childList.map((item, index) => (
                  <li key={index}>
                    {item.title && (
                      <p className="cs_medium cs_heading_color mb-0">
                        {item.title}
                      </p>
                    )}

                    {item.subTitle && <p className="mb-0">{item.subTitle}</p>}
                  </li>
                ))}
              </ul>
            )}
          </li>
        ))}
      </ul>
    </div>
  );
}
