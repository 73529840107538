import React from "react";
import CustomSuspense from "../../../components/CustomSuspence";
const LazyLabTestService = React.lazy(() => import("./LabTestService"));

const LabTestService = () => {
  return (
    <CustomSuspense loadingLabel="Loading Lab Test Service">
      <LazyLabTestService />
    </CustomSuspense>
  );
};

export default LabTestService;
