import i18n from "../../../i18n/i18n";

export const kidneyDiseasesContentDatas = () => {
  const whatIsKidneyDiseaseData = [
    {
      title: i18n.t("whatIsKidneyDiseaseLists.list1.title", {
        ns: "aboutKidneyDiseases",
      }),
      subTitle: i18n.t("whatIsKidneyDiseaseLists.list1.description", {
        ns: "aboutKidneyDiseases",
      }),
      iconUrl: "/images/kidney_educations/about-kidney-diesease/Icon 1-01.svg",
    },
    {
      title: i18n.t("whatIsKidneyDiseaseLists.list2.title", {
        ns: "aboutKidneyDiseases",
      }),
      subTitle: i18n.t("whatIsKidneyDiseaseLists.list2.description", {
        ns: "aboutKidneyDiseases",
      }),
      iconUrl: "/images/kidney_educations/about-kidney-diesease/Icon 2-01.svg",
    },
  ];

  const signsYourKidneysData = [
    {
      title: i18n.t("signsYourKidneysMightNeedAttentionLists.list1.title", {
        ns: "aboutKidneyDiseases",
      }),
      subTitle: i18n.t(
        "signsYourKidneysMightNeedAttentionLists.list1.description",
        {
          ns: "aboutKidneyDiseases",
        }
      ),
      iconUrl: "/images/kidney_educations/about-kidney-diesease/Icon 3-01.svg",
    },
    {
      title: i18n.t("signsYourKidneysMightNeedAttentionLists.list2.title", {
        ns: "aboutKidneyDiseases",
      }),
      subTitle: i18n.t(
        "signsYourKidneysMightNeedAttentionLists.list2.description",
        {
          ns: "aboutKidneyDiseases",
        }
      ),
      iconUrl: "/images/kidney_educations/about-kidney-diesease/Icon 4-01.svg",
    },
    {
      title: i18n.t("signsYourKidneysMightNeedAttentionLists.list3.title", {
        ns: "aboutKidneyDiseases",
      }),
      subTitle: i18n.t(
        "signsYourKidneysMightNeedAttentionLists.list3.description",
        {
          ns: "aboutKidneyDiseases",
        }
      ),
      iconUrl: "/images/kidney_educations/about-kidney-diesease/Icon 5-01.svg",
    },
    {
      title: i18n.t("signsYourKidneysMightNeedAttentionLists.list4.title", {
        ns: "aboutKidneyDiseases",
      }),
      subTitle: i18n.t(
        "signsYourKidneysMightNeedAttentionLists.list4.description",
        {
          ns: "aboutKidneyDiseases",
        }
      ),
      iconUrl: "/images/kidney_educations/about-kidney-diesease/Icon 6-01.svg",
    },
  ];

  const importantRememberHints = [
    {
      title: i18n.t("importantNote.title", {
        ns: "aboutKidneyDiseases",
      }),
      description: i18n.t("importantNote.description", {
        ns: "aboutKidneyDiseases",
      }),
    },
    {
      title: i18n.t("remember.title", {
        ns: "aboutKidneyDiseases",
      }),
      description: i18n.t("remember.description", {
        ns: "aboutKidneyDiseases",
      }),
    },
  ];

  const majorKidneyDiseasesSubListData1 = [
    {
      title: i18n.t("majorKidneyDiseasesLists.list1.subLists.list1.title", {
        ns: "aboutKidneyDiseases",
      }),
      subTitle: i18n.t(
        "majorKidneyDiseasesLists.list1.subLists.list1.description",
        {
          ns: "aboutKidneyDiseases",
        }
      ),
    },
    {
      title: i18n.t("majorKidneyDiseasesLists.list1.subLists.list2.title", {
        ns: "aboutKidneyDiseases",
      }),
      subTitle: i18n.t(
        "majorKidneyDiseasesLists.list1.subLists.list2.description",
        {
          ns: "aboutKidneyDiseases",
        }
      ),
    },
    {
      title: i18n.t("majorKidneyDiseasesLists.list1.subLists.list3.title", {
        ns: "aboutKidneyDiseases",
      }),
      subTitle: i18n.t(
        "majorKidneyDiseasesLists.list1.subLists.list3.description",
        {
          ns: "aboutKidneyDiseases",
        }
      ),
    },
    {
      title: i18n.t("majorKidneyDiseasesLists.list1.subLists.list4.title", {
        ns: "aboutKidneyDiseases",
      }),
      subTitle: i18n.t(
        "majorKidneyDiseasesLists.list1.subLists.list4.description",
        {
          ns: "aboutKidneyDiseases",
        }
      ),
    },
  ];

  const majorKidneyDiseasesSubListData2 = [
    {
      title: i18n.t("majorKidneyDiseasesLists.list2.subLists.list1.title", {
        ns: "aboutKidneyDiseases",
      }),
      subTitle: i18n.t(
        "majorKidneyDiseasesLists.list2.subLists.list1.description",
        {
          ns: "aboutKidneyDiseases",
        }
      ),
    },
    {
      title: i18n.t("majorKidneyDiseasesLists.list2.subLists.list2.title", {
        ns: "aboutKidneyDiseases",
      }),
      subTitle: i18n.t(
        "majorKidneyDiseasesLists.list2.subLists.list2.description",
        {
          ns: "aboutKidneyDiseases",
        }
      ),
    },
    {
      title: i18n.t("majorKidneyDiseasesLists.list2.subLists.list3.title", {
        ns: "aboutKidneyDiseases",
      }),
      subTitle: i18n.t(
        "majorKidneyDiseasesLists.list2.subLists.list3.description",
        {
          ns: "aboutKidneyDiseases",
        }
      ),
    },
  ];

  const majorKidneyDiseasesSubListData3 = [
    {
      title: i18n.t("majorKidneyDiseasesLists.list3.subLists.list1.title", {
        ns: "aboutKidneyDiseases",
      }),
    },
    {
      title: i18n.t("majorKidneyDiseasesLists.list3.subLists.list2.title", {
        ns: "aboutKidneyDiseases",
      }),
    },
    {
      title: i18n.t("majorKidneyDiseasesLists.list3.subLists.list3.title", {
        ns: "aboutKidneyDiseases",
      }),
    },
  ];

  const majorKidneyDiseasesSubListData4 = [
    {
      title: i18n.t("majorKidneyDiseasesLists.list4.subLists.list1.title", {
        ns: "aboutKidneyDiseases",
      }),
      subTitle: i18n.t(
        "majorKidneyDiseasesLists.list4.subLists.list1.description",
        {
          ns: "aboutKidneyDiseases",
        }
      ),
    },
    {
      title: i18n.t("majorKidneyDiseasesLists.list4.subLists.list2.title", {
        ns: "aboutKidneyDiseases",
      }),
      subTitle: i18n.t(
        "majorKidneyDiseasesLists.list4.subLists.list2.description",
        {
          ns: "aboutKidneyDiseases",
        }
      ),
    },
  ];

  const majorKidneyDiseasesSubListData5 = [
    {
      title: i18n.t("majorKidneyDiseasesLists.list5.subLists.list1.title", {
        ns: "aboutKidneyDiseases",
      }),
    },
    {
      title: i18n.t("majorKidneyDiseasesLists.list5.subLists.list2.title", {
        ns: "aboutKidneyDiseases",
      }),
    },
    {
      title: i18n.t("majorKidneyDiseasesLists.list5.subLists.list3.title", {
        ns: "aboutKidneyDiseases",
      }),
    },
  ];

  const rememberDiseasesSymptomsData = [
    {
      title: i18n.t("rememberDiseasesSymptoms.subLists.list1.title", {
        ns: "aboutKidneyDiseases",
      }),
    },
    {
      title: i18n.t("rememberDiseasesSymptoms.subLists.list2.title", {
        ns: "aboutKidneyDiseases",
      }),
    },
    {
      title: i18n.t("rememberDiseasesSymptoms.subLists.list3.title", {
        ns: "aboutKidneyDiseases",
      }),
    },
    {
      title: i18n.t("rememberDiseasesSymptoms.subLists.list4.title", {
        ns: "aboutKidneyDiseases",
      }),
    },
    {
      title: i18n.t("rememberDiseasesSymptoms.subLists.list5.title", {
        ns: "aboutKidneyDiseases",
      }),
    },
  ];

  return {
    whatIsKidneyDiseaseData,
    signsYourKidneysData,
    importantRememberHints,
    majorKidneyDiseasesSubListData1,
    majorKidneyDiseasesSubListData2,
    majorKidneyDiseasesSubListData3,
    majorKidneyDiseasesSubListData4,
    majorKidneyDiseasesSubListData5,
    rememberDiseasesSymptomsData,
  };
};
