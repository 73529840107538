import i18n from "../../../../i18n/i18n";

export const firstBlogContentDatas = () => {
  const firstBlogListData = [
    {
      title: i18n.t("blogs.101.innerHTML.hygiene.title", {
        ns: "cathetersBlogs",
      }),
      childList: [
        {
          title: i18n.t("blogs.101.innerHTML.hygiene.list1.title", {
            ns: "cathetersBlogs",
          }),
          description: i18n.t("blogs.101.innerHTML.hygiene.list1.description", {
            ns: "cathetersBlogs",
          }),
        },
        {
          title: i18n.t("blogs.101.innerHTML.hygiene.list2.title", {
            ns: "cathetersBlogs",
          }),
          description: i18n.t("blogs.101.innerHTML.hygiene.list2.description", {
            ns: "cathetersBlogs",
          }),
        },
        {
          title: i18n.t("blogs.101.innerHTML.hygiene.list3.title", {
            ns: "cathetersBlogs",
          }),
          description: i18n.t("blogs.101.innerHTML.hygiene.list3.description", {
            ns: "cathetersBlogs",
          }),
        },
      ],
    },
    {
      title: i18n.t("blogs.101.innerHTML.handlingWithCare.title", {
        ns: "cathetersBlogs",
      }),
      childList: [
        {
          title: i18n.t("blogs.101.innerHTML.handlingWithCare.list1.title", {
            ns: "cathetersBlogs",
          }),
          description: i18n.t(
            "blogs.101.innerHTML.handlingWithCare.list1.description",
            {
              ns: "cathetersBlogs",
            }
          ),
        },
        {
          title: i18n.t("blogs.101.innerHTML.handlingWithCare.list2.title", {
            ns: "cathetersBlogs",
          }),
          description: i18n.t(
            "blogs.101.innerHTML.handlingWithCare.list2.description",
            {
              ns: "cathetersBlogs",
            }
          ),
        },
        {
          title: i18n.t("blogs.101.innerHTML.handlingWithCare.list3.title", {
            ns: "cathetersBlogs",
          }),
          description: i18n.t(
            "blogs.101.innerHTML.handlingWithCare.list3.description",
            {
              ns: "cathetersBlogs",
            }
          ),
        },
      ],
    },
    {
      title: i18n.t("blogs.101.innerHTML.vigilanceAndEarlyIntervention.title", {
        ns: "cathetersBlogs",
      }),
      childList: [
        {
          title: i18n.t(
            "blogs.101.innerHTML.vigilanceAndEarlyIntervention.list1.title",
            {
              ns: "cathetersBlogs",
            }
          ),
          description: i18n.t(
            "blogs.101.innerHTML.vigilanceAndEarlyIntervention.list1.description",
            {
              ns: "cathetersBlogs",
            }
          ),
        },
        {
          title: i18n.t(
            "blogs.101.innerHTML.vigilanceAndEarlyIntervention.list2.title",
            {
              ns: "cathetersBlogs",
            }
          ),
          description: i18n.t(
            "blogs.101.innerHTML.vigilanceAndEarlyIntervention.list2.description",
            {
              ns: "cathetersBlogs",
            }
          ),
        },
        {
          title: i18n.t(
            "blogs.101.innerHTML.vigilanceAndEarlyIntervention.list3.title",
            {
              ns: "cathetersBlogs",
            }
          ),
          description: i18n.t(
            "blogs.101.innerHTML.vigilanceAndEarlyIntervention.list3.description",
            {
              ns: "cathetersBlogs",
            }
          ),
        },
      ],
    },
    {
      title: i18n.t("blogs.101.innerHTML.preventionOfBloodClots.title", {
        ns: "cathetersBlogs",
      }),
      childList: [
        {
          title: i18n.t(
            "blogs.101.innerHTML.preventionOfBloodClots.list1.title",
            {
              ns: "cathetersBlogs",
            }
          ),
          description: i18n.t(
            "blogs.101.innerHTML.preventionOfBloodClots.list1.description",
            {
              ns: "cathetersBlogs",
            }
          ),
        },
        {
          title: i18n.t(
            "blogs.101.innerHTML.preventionOfBloodClots.list2.title",
            {
              ns: "cathetersBlogs",
            }
          ),
          description: i18n.t(
            "blogs.101.innerHTML.preventionOfBloodClots.list2.description",
            {
              ns: "cathetersBlogs",
            }
          ),
        },
        {
          title: i18n.t(
            "blogs.101.innerHTML.preventionOfBloodClots.list3.title",
            {
              ns: "cathetersBlogs",
            }
          ),
          description: i18n.t(
            "blogs.101.innerHTML.preventionOfBloodClots.list3.description",
            {
              ns: "cathetersBlogs",
            }
          ),
        },
      ],
    },
    {
      title: i18n.t("blogs.101.innerHTML.empowerYourselfWithKnowledge.title", {
        ns: "cathetersBlogs",
      }),
      childList: [
        {
          title: i18n.t(
            "blogs.101.innerHTML.empowerYourselfWithKnowledge.list1.title",
            {
              ns: "cathetersBlogs",
            }
          ),
          description: i18n.t(
            "blogs.101.innerHTML.empowerYourselfWithKnowledge.list1.description",
            {
              ns: "cathetersBlogs",
            }
          ),
        },
        {
          title: i18n.t(
            "blogs.101.innerHTML.empowerYourselfWithKnowledge.list2.title",
            {
              ns: "cathetersBlogs",
            }
          ),
          description: i18n.t(
            "blogs.101.innerHTML.empowerYourselfWithKnowledge.list2.description",
            {
              ns: "cathetersBlogs",
            }
          ),
        },
      ],
    },
    {
      title: i18n.t(
        "blogs.101.innerHTML.collaborateWithYourHealthcareTeam.title",
        {
          ns: "cathetersBlogs",
        }
      ),
      childList: [
        {
          title: i18n.t(
            "blogs.101.innerHTML.collaborateWithYourHealthcareTeam.list1.title",
            {
              ns: "cathetersBlogs",
            }
          ),
          description: i18n.t(
            "blogs.101.innerHTML.collaborateWithYourHealthcareTeam.list1.description",
            {
              ns: "cathetersBlogs",
            }
          ),
        },
        {
          title: i18n.t(
            "blogs.101.innerHTML.collaborateWithYourHealthcareTeam.list2.title",
            {
              ns: "cathetersBlogs",
            }
          ),
          description: i18n.t(
            "blogs.101.innerHTML.collaborateWithYourHealthcareTeam.list2.description",
            {
              ns: "cathetersBlogs",
            }
          ),
        },
      ],
    },
  ];

  return {
    firstBlogListData,
  };
};
