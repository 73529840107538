import React from "react";
import { Trans, useTranslation } from "react-i18next";
import { firstBlogContentDatas } from "./firstBlogContents";
import Spacing from "../../../../components/Spacing";
import BlogList from "../../../Blogs/BlogComponents/BlogList";

const FirstBlog = () => {
  const { t } = useTranslation(["dialysisAccessoriesBlog"]);
  const {
    metallicNeedlesListData,
    plasticNeedlesListData,
    choosingTheRightNeedleListData,
  } = firstBlogContentDatas();

  return (
    <>
      <p>{t(`blogs.101.innerHTML.blogDescription`)}</p>

      <h2>{t(`blogs.101.innerHTML.needleGauge.title`)}</h2>
      <p>{t(`blogs.101.innerHTML.needleGauge.description`)}</p>

      <Spacing md="20" lg="20" />
      <h2>{t(`blogs.101.innerHTML.metallicNeedles.title`)}</h2>
      <p>{t(`blogs.101.innerHTML.metallicNeedles.description`)}</p>
      <div className="cs_blog_un_ord_list">
        <BlogList data={metallicNeedlesListData} />
      </div>

      <Spacing md="30" lg="30" />
      <h2>{t(`blogs.101.innerHTML.plasticNeedles.title`)}</h2>
      <p>{t(`blogs.101.innerHTML.plasticNeedles.description`)}</p>
      <div className="cs_blog_un_ord_list">
        <BlogList data={plasticNeedlesListData} />
      </div>

      <Spacing md="30" lg="30" />
      <h2>{t(`blogs.101.innerHTML.choosingTheRightNeedle.title`)}</h2>
      <p>{t(`blogs.101.innerHTML.choosingTheRightNeedle.description`)}</p>
      <div className="cs_blog_un_ord_list">
        <BlogList data={choosingTheRightNeedleListData} />
      </div>

      <Spacing md="30" lg="30" />
      <h2>{t(`blogs.101.innerHTML.importanceOfProperTechnique.title`)}</h2>
      <p>
        <Trans
          i18nKey={t(
            "blogs.101.innerHTML.importanceOfProperTechnique.description"
          )}
          components={{
            boldText: <b />,
          }}
        />
      </p>

      <Spacing md="30" lg="30" />
      <h2>{t(`blogs.101.innerHTML.remember.title`)}</h2>
      <p>{t(`blogs.101.innerHTML.remember.description`)}</p>

      <Spacing md="20" lg="20" />
      <p>{t(`blogs.101.innerHTML.contents.para1`)}</p>

      <Spacing md="30" lg="30" />
      <h2>{t(`blogs.101.innerHTML.disclaimer.title`)}</h2>
      <p>{t(`blogs.101.innerHTML.disclaimer.description`)}</p>
    </>
  );
};

export default FirstBlog;
