import React from "react";
import ContactForm from "../ContactForm";

const PopupModalComponent = ({ isOpen, onClose , heading }) => {
  return (
    <div
      className={`modal modal-dialog-centered modal-dialog-scrollable modal-lg ${
        isOpen ? "show" : ""
      }`}
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      tabIndex="-1"
      aria-labelledby="staticBackdropLabel"
      aria-hidden="true"
      style={{ display: isOpen ? "block" : "none" }}
    >
      <div className="modal-dialog">
        <div className="modal-content" style={{ marginBottom:60 }}>
          <div className="modal-header">
            <h5 className="modal-title">{ heading || 'Contact Us'}</h5> 
            <button type="button"  onClick={onClose} className="btn btn-link" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <ContactForm customStyle={{ formContainer: "pop-up-contact" }} />
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-secondary"
              onClick={onClose}
            >
              Close
            </button>
          </div>
        </div>
      </div>
      {/* {isOpen && <div className="modal-backdrop fade show"></div>} */}
    </div>
  );
};

export default PopupModalComponent;
