import React, { useRef } from "react";
import { pageTitle } from "../../../helpers/PageTitle";
import Section from "../../../components/Section";
import DepartmentSectionStyle2 from "../../../components/Section/DepartmentSection/DepartmentSectionStyle2";
import { useTranslation } from "react-i18next";
import FeaturesSectionStyle3 from "../../../components/Section/FeaturesSection/FeaturesSectionStyle3";
import Spacing from "../../../components/Spacing";
import TestimonialStyle2 from "../../../components/Testimonial/TestimonialStyle2";
import List from "../../../components/List";
import { kidneyDiseasesContentDatas } from "./aboutKidneyDiseasesContents";
import KidneyBreadcrumStyle from "../../../components/Breadcrumb/KidneyBreadcrumStyle";
import KidneyEducationBannerStyle from "../../../components/Section/BannerSection/KidneyEducationBannerStyle";
import ScrollToSection from "../../../components/ScrollToSection";
import SingleGridAccordion from "../../../components/SingleGridAccordion/SingleGridAccordion";

const AboutKidneyDiseases = () => {
  pageTitle("About Kidney Diseases");
  const { t } = useTranslation(["aboutKidneyDiseases"]);

  const kidneyDiseaseRef = useRef(null);
  const needAttentionRef = useRef(null);
  const importantNoteRef = useRef(null);
  const majorKidneyDiseaseRef = useRef(null);
  const rememberRef = useRef(null);

  const scrollToLists = [
    {
      btnName: "Kidney disease",
      btnRef: kidneyDiseaseRef,
      btnImage:
        "/images/kidney_educations/about-kidney-diesease/learn-about-kidney-icon-01.svg",
    },
    {
      btnName: "Symptoms and Signs of Kidney Diseases",
      btnRef: needAttentionRef,
      btnImage:
        "/images/kidney_educations/about-kidney-diesease/learn-about-kidney-icon-02.svg",
    },
    // {
    //   btnName: "Important Note",
    //   btnRef: importantNoteRef,
    // },
    {
      btnName: "Major kidney diseases",
      btnRef: majorKidneyDiseaseRef,
      btnImage:
        "/images/kidney_educations/about-kidney-diesease/learn-about-kidney-icon-03.svg",
    },
    // {
    //   btnName: "Remember",
    //   btnRef: rememberRef,
    // },
  ];

  const {
    whatIsKidneyDiseaseData,
    signsYourKidneysData,
    importantRememberHints,
    majorKidneyDiseasesSubListData1,
    majorKidneyDiseasesSubListData2,
    majorKidneyDiseasesSubListData3,
    majorKidneyDiseasesSubListData4,
    majorKidneyDiseasesSubListData5,
    rememberDiseasesSymptomsData,
  } = kidneyDiseasesContentDatas();

  const majorKidneyDieseasesList1 = [
    {
      title: `${t("majorKidneyDiseasesLists.list1.title")}`,
      iconUrl: "/images/kidney_educations/about-kidney-diesease/Icon 7-01.svg",
      content: (
        <List
          heading=""
          description={`${t("majorKidneyDiseasesLists.list1.description")}:`}
          data={majorKidneyDiseasesSubListData1}
        />
      ),
    },
  ];

  const majorKidneyDieseasesList2 = [
    {
      title: `${t("majorKidneyDiseasesLists.list2.title")}`,
      iconUrl: "/images/kidney_educations/about-kidney-diesease/Icon 8-01.svg",
      content: (
        <List
          heading=""
          description={`${t("majorKidneyDiseasesLists.list2.description")}:`}
          data={majorKidneyDiseasesSubListData2}
        />
      ),
    },
  ];

  const majorKidneyDieseasesList3 = [
    {
      title: `${t("majorKidneyDiseasesLists.list3.title")}`,
      iconUrl: "/images/kidney_educations/about-kidney-diesease/Icon 9-01.svg",
      content: (
        <List
          heading=""
          description={`${t("majorKidneyDiseasesLists.list3.description")}:`}
          data={majorKidneyDiseasesSubListData3}
        />
      ),
    },
  ];

  const majorKidneyDieseasesList4 = [
    {
      title: `${t("majorKidneyDiseasesLists.list4.title")}`,
      iconUrl: "/images/kidney_educations/about-kidney-diesease/Icon 10-01.svg",
      content: (
        <List
          heading=""
          description={`${t("majorKidneyDiseasesLists.list4.description")}:`}
          data={majorKidneyDiseasesSubListData4}
        />
      ),
    },
  ];

  const majorKidneyDieseasesList5 = [
    {
      title: `${t("majorKidneyDiseasesLists.list5.title")}`,
      iconUrl: "/images/kidney_educations/about-kidney-diesease/Icon 11-01.svg",
      content: (
        <List
          heading=""
          description={`${t("majorKidneyDiseasesLists.list5.description")}:`}
          data={majorKidneyDiseasesSubListData5}
        />
      ),
    },
  ];

  const rememberDiseasesSymptoms = [
    {
      title: `${t("rememberDiseasesSymptoms.title")}`,
      iconUrl: "/images/home/remember-icon.svg",
      content: (
        <List
          heading=""
          description={`${t("rememberDiseasesSymptoms.description")}:`}
          data={rememberDiseasesSymptomsData}
        />
      ),
    },
  ];

  return (
    <>
      {/* Breadcrumb Section Start */}
      <Section className="cs_kideny_education">
        <KidneyBreadcrumStyle />
      </Section>
      {/* Breadcrumb Section End */}

      {/* Banner Section Start */}
      <Section className="cs_banner_styles">
        <KidneyEducationBannerStyle
          bgUrl="/images/kidney_educations/about-kidney-diesease/about-kidney-diseases-web-banner-gradient-img.jpg"
          imgUrl=""
          title={t("bannerTitle")}
          subTitle={t("bannerDescription.line1")}
          subTitle2={t("bannerDescription.line2")}
          customeThemeClass=""
        />
      </Section>
      {/* Banner Section End */}

      {/* Section Navigation Start */}
      <Section
        topMd={50}
        topLg={50}
        topXl={50}
        bottomXl={80}
        bottomLg={80}
        bottomMd={80}
      >
        <ScrollToSection
          btnLists={scrollToLists}
          brandName="kidney-education"
        />
      </Section>
      {/* Section Navigation End */}

      {/* <BannerSectionStyle5
        bgUrl="/images/departments/banner_bg.svg"
        imgUrl="/images/departments/banner_img.png"
        title={t("bannerTitle")}
        subTitle={t("bannerDescription.line1")}
        subTitle2={t("bannerDescription.line2")}
        customClasses="cs_about_kidney"
      /> */}

      {/* Kidney Disease Section Start */}
      <Section
        bottomMd={130}
        bottomLg={120}
        bottomXl={110}
        sectionRef={kidneyDiseaseRef}
      >
        <DepartmentSectionStyle2
          sectionTitle={t("whatIsKidneyDisease.title")}
          subTitle={t("whatIsKidneyDisease.description1")}
          subTitle2={`${t("whatIsKidneyDisease.description2")}:`}
          data={whatIsKidneyDiseaseData}
        />
      </Section>
      {/* Kidney Disease Section End */}

      {/* Need Attention Section Start */}
      <Section
        bottomMd={130}
        bottomLg={120}
        bottomXl={110}
        sectionRef={needAttentionRef}
        className="cs_about_kidney"
      >
        <FeaturesSectionStyle3
          sectionTitle={t("signsYourKidneysMightNeedAttention.title")}
          subTitle={`${t("signsYourKidneysMightNeedAttention.description")}:`}
          data={signsYourKidneysData}
        />
      </Section>
      {/* Need Attention Section End */}

      {/* Important Note Section Start */}
      <Section
        topMd={130}
        topLg={120}
        topXl={110}
        bottomMd={130}
        bottomLg={120}
        bottomXl={110}
        className="cs_kideny_education cs_important_remember_bg"
        sectionRef={importantNoteRef}
      >
        <div className="container cs_about_kidney">
          <div className="row">
            {importantRememberHints?.map((item, index) => (
              <div className="col-xl-6 col-lg-6 col-md-6" key={index}>
                <TestimonialStyle2 title={item.title} text={item.description} />
              </div>
            ))}
          </div>
        </div>
      </Section>
      {/* Need Attention Section End */}

      {/* Major Kidney Section Start */}
      <Section
        topMd={130}
        topLg={120}
        topXl={110}
        bottomMd={130}
        bottomLg={120}
        bottomXl={110}
        className="cs_about_kidney"
        sectionRef={majorKidneyDiseaseRef}
      >
        <div className="cs_doctor_details">
          <div
            className="cs_doctor_details_bg cs_bg_filed"
            style={{
              backgroundImage: `url(/images/home/kidney-education-bg.png)`,
            }}
          />
          <Spacing md="85" />
          <div className="container">
            <div className="row">
              <div className="col-lg-5">
                <div className="cs_single_doctor overflow-hidden cs_radius_20">
                  <img
                    src="/images/kidney_educations/about-kidney-diesease/major-kidney-dieseases.jpg"
                    alt="Doctor"
                    className="w-100"
                  />
                </div>
              </div>
              <div className="col-lg-6 offset-lg-1 position-relative">
                <Spacing md="55" />
                <h2 className="cs_fs_48 mb-0 cs_semibold testimonial-title" style={{color: '#333333'}}>
                  {t("majorKidneyDiseases.title")}
                </h2>
                <Spacing md="32" />
                <p className="mb-0 cs_heading_color testimonial-sub-title" style={{color: '#333333'}}>{`${t(
                  "majorKidneyDiseases.description"
                )}:`}</p>
                <Spacing md="50" xl="150" lg="100" />
                <SingleGridAccordion
                  accordionData={majorKidneyDieseasesList1}
                  customClasses="cs_accordians_kidney_education cs_accordians_with_icon"
                />
                <Spacing md="30" lg="50" />
                <SingleGridAccordion
                  accordionData={majorKidneyDieseasesList2}
                  customClasses="cs_accordians_kidney_education cs_accordians_with_icon"
                />
                <Spacing md="30" lg="50" />
                <SingleGridAccordion
                  accordionData={majorKidneyDieseasesList3}
                  customClasses="cs_accordians_kidney_education cs_accordians_with_icon cs_accordians_only_title"
                />
                <Spacing md="30" lg="50" />
                <SingleGridAccordion
                  accordionData={majorKidneyDieseasesList4}
                  customClasses="cs_accordians_kidney_education cs_accordians_with_icon"
                />
                <Spacing md="30" lg="50" />
                <SingleGridAccordion
                  accordionData={majorKidneyDieseasesList5}
                  customClasses="cs_accordians_kidney_education cs_accordians_with_icon cs_accordians_only_title"
                />
              </div>
            </div>
          </div>
        </div>
      </Section>
      {/* Need Attention Section End */}

      {/* Remember Section Start */}
      <Section
        bottomMd={130}
        bottomLg={120}
        bottomXl={110}
        className="cs_about_kidney"
        sectionRef={rememberRef}
      >
        <div className="container">
          <div className="cs_banner cs_style_9 cs_white_bg cs_radius_30">
            <SingleGridAccordion
              accordionData={rememberDiseasesSymptoms}
              customClasses="cs_accordians_kidney_education cs_accordians_with_icon cs_accordians_only_title"
            />
            <Spacing md="50" xl="50" lg="50" />
            <p className="cs_banner_subtitle cs_fs_20 m-0 cs_medium">
              {t("lastSectionHints")}
            </p>
          </div>
        </div>
      </Section>
      {/* Remember Section End */}
    </>
  );
};

export default AboutKidneyDiseases;
