import { Trans } from "react-i18next";
import i18n from "../../../../i18n/i18n";

export const firstBlogContentDatas = () => {
  const realLifeConsequencesListData = [
    {
      description: i18n.t(
        "blogs.101.innerHTML.realLifeConsequences.list1.description",
        {
          ns: "medicineBlogs",
        }
      ),
    },
    {
      description: i18n.t(
        "blogs.101.innerHTML.realLifeConsequences.list2.description",
        {
          ns: "medicineBlogs",
        }
      ),
    },
    {
      description: i18n.t(
        "blogs.101.innerHTML.realLifeConsequences.list3.description",
        {
          ns: "medicineBlogs",
        }
      ),
    },
  ];

  const whyDoTheseMistakesHappenListData = [
    {
      title: i18n.t(
        "blogs.101.innerHTML.whyDoTheseMistakesHappen.list1.title",
        {
          ns: "medicineBlogs",
        }
      ),
      description: i18n.t(
        "blogs.101.innerHTML.whyDoTheseMistakesHappen.list1.description",
        {
          ns: "medicineBlogs",
        }
      ),
    },
    {
      title: i18n.t(
        "blogs.101.innerHTML.whyDoTheseMistakesHappen.list2.title",
        {
          ns: "medicineBlogs",
        }
      ),
      description: i18n.t(
        "blogs.101.innerHTML.whyDoTheseMistakesHappen.list2.description",
        {
          ns: "medicineBlogs",
        }
      ),
    },
    {
      title: i18n.t(
        "blogs.101.innerHTML.whyDoTheseMistakesHappen.list3.title",
        {
          ns: "medicineBlogs",
        }
      ),
      description: i18n.t(
        "blogs.101.innerHTML.whyDoTheseMistakesHappen.list3.description",
        {
          ns: "medicineBlogs",
        }
      ),
    },
  ];

  const colorCodedEnvelopesListData = [
    {
      title: i18n.t("blogs.101.innerHTML.colorCodedEnvelopes.list1.title", {
        ns: "medicineBlogs",
      }),
      description: (
        <Trans
          i18nKey={i18n.t(
            "blogs.101.innerHTML.colorCodedEnvelopes.list1.description",
            {
              ns: "medicineBlogs",
            }
          )}
          components={{
            dQuotes: <q />,
          }}
        />
      ),
    },
    {
      title: i18n.t("blogs.101.innerHTML.colorCodedEnvelopes.list2.title", {
        ns: "medicineBlogs",
      }),
      description: i18n.t(
        "blogs.101.innerHTML.colorCodedEnvelopes.list2.description",
        {
          ns: "medicineBlogs",
        }
      ),
    },
    {
      title: i18n.t("blogs.101.innerHTML.colorCodedEnvelopes.list3.title", {
        ns: "medicineBlogs",
      }),
      description: i18n.t(
        "blogs.101.innerHTML.colorCodedEnvelopes.list3.description",
        {
          ns: "medicineBlogs",
        }
      ),
    },
  ];

  const clearInstructionsListData = [
    {
      title: i18n.t("blogs.101.innerHTML.clearInstructions.list1.title", {
        ns: "medicineBlogs",
      }),
      description: i18n.t(
        "blogs.101.innerHTML.clearInstructions.list1.description",
        {
          ns: "medicineBlogs",
        }
      ),
    },
    {
      title: i18n.t("blogs.101.innerHTML.clearInstructions.list2.title", {
        ns: "medicineBlogs",
      }),
      description: i18n.t(
        "blogs.101.innerHTML.clearInstructions.list2.description",
        {
          ns: "medicineBlogs",
        }
      ),
    },
    {
      title: i18n.t("blogs.101.innerHTML.clearInstructions.list3.title", {
        ns: "medicineBlogs",
      }),
      description: i18n.t(
        "blogs.101.innerHTML.clearInstructions.list3.description",
        {
          ns: "medicineBlogs",
        }
      ),
    },
    {
      title: i18n.t("blogs.101.innerHTML.clearInstructions.list4.title", {
        ns: "medicineBlogs",
      }),
      description: i18n.t(
        "blogs.101.innerHTML.clearInstructions.list4.description",
        {
          ns: "medicineBlogs",
        }
      ),
    },
  ];

  const benefitsSpecializedPackagingListData = [
    {
      title: i18n.t(
        "blogs.101.innerHTML.benefitsSpecializedPackaging.list1.title",
        {
          ns: "medicineBlogs",
        }
      ),
    },
    {
      title: i18n.t(
        "blogs.101.innerHTML.benefitsSpecializedPackaging.list2.title",
        {
          ns: "medicineBlogs",
        }
      ),
    },
    {
      title: i18n.t(
        "blogs.101.innerHTML.benefitsSpecializedPackaging.list3.title",
        {
          ns: "medicineBlogs",
        }
      ),
    },
    {
      title: i18n.t(
        "blogs.101.innerHTML.benefitsSpecializedPackaging.list4.title",
        {
          ns: "medicineBlogs",
        }
      ),
    },
  ];

  const howThisHelpsYouListData = [
    {
      title: i18n.t("blogs.101.innerHTML.howThisHelpsYou.list1.title", {
        ns: "medicineBlogs",
      }),
      description: i18n.t(
        "blogs.101.innerHTML.howThisHelpsYou.list1.description",
        {
          ns: "medicineBlogs",
        }
      ),
    },
    {
      title: i18n.t("blogs.101.innerHTML.howThisHelpsYou.list2.title", {
        ns: "medicineBlogs",
      }),
      description: i18n.t(
        "blogs.101.innerHTML.howThisHelpsYou.list2.description",
        {
          ns: "medicineBlogs",
        }
      ),
    },
    {
      title: i18n.t("blogs.101.innerHTML.howThisHelpsYou.list3.title", {
        ns: "medicineBlogs",
      }),
      description: i18n.t(
        "blogs.101.innerHTML.howThisHelpsYou.list3.description",
        {
          ns: "medicineBlogs",
        }
      ),
    },
    {
      title: i18n.t("blogs.101.innerHTML.howThisHelpsYou.list4.title", {
        ns: "medicineBlogs",
      }),
      description: i18n.t(
        "blogs.101.innerHTML.howThisHelpsYou.list4.description",
        {
          ns: "medicineBlogs",
        }
      ),
    },
  ];

  const beyondThePackagingListData = [
    {
      title: i18n.t("blogs.101.innerHTML.beyondThePackaging.list1.title", {
        ns: "medicineBlogs",
      }),
      description: i18n.t(
        "blogs.101.innerHTML.beyondThePackaging.list1.description",
        {
          ns: "medicineBlogs",
        }
      ),
    },
    {
      title: i18n.t("blogs.101.innerHTML.beyondThePackaging.list2.title", {
        ns: "medicineBlogs",
      }),
      description: i18n.t(
        "blogs.101.innerHTML.beyondThePackaging.list2.description",
        {
          ns: "medicineBlogs",
        }
      ),
    },
    {
      title: i18n.t("blogs.101.innerHTML.beyondThePackaging.list3.title", {
        ns: "medicineBlogs",
      }),
      description: i18n.t(
        "blogs.101.innerHTML.beyondThePackaging.list3.description",
        {
          ns: "medicineBlogs",
        }
      ),
    },
  ];

  return {
    realLifeConsequencesListData,
    whyDoTheseMistakesHappenListData,
    colorCodedEnvelopesListData,
    clearInstructionsListData,
    benefitsSpecializedPackagingListData,
    howThisHelpsYouListData,
    beyondThePackagingListData,
  };
};
