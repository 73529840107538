import React from "react";

const BlogOrderedList = ({ data }) => {
  return (
    <ol>
      {data?.map((item, index) => (
        <li key={index}>
          <b>{item.title} </b>
          {item.description && <span> {item.description}</span>}
          {item.childList && item.childList.length > 0 && (
            <ul>
              {item.childList.map((childItem, childIndex) => (
                <li key={childIndex}>
                  <b>{childItem.title} </b>
                  {childItem.description && (
                    <span> {childItem.description}</span>
                  )}
                  {childItem.subChildList &&
                    childItem.subChildList.length > 0 && (
                      <ul>
                        {childItem.subChildList.map((subItem, subIndex) => (
                          <li key={subIndex}>
                            {subItem.title && <b>{subItem.title} </b>}
                            {subItem.description && (
                              <span> {subItem.description}</span>
                            )}
                            {subItem.leastChild &&
                              subItem.leastChild.length > 0 && (
                                <ul>
                                  {subItem.leastChild.map(
                                    (leastNode, leastIndex) => (
                                      <li key={leastIndex}>
                                        {leastNode.title && (
                                          <b>{leastNode.title} </b>
                                        )}
                                        {leastNode.description && (
                                          <span> {leastNode.description}</span>
                                        )}
                                      </li>
                                    )
                                  )}
                                </ul>
                              )}
                          </li>
                        ))}
                      </ul>
                    )}
                </li>
              ))}
            </ul>
          )}
        </li>
      ))}
    </ol>
  );
};

export default BlogOrderedList;
