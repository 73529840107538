import React from "react";
import { Outlet } from "react-router-dom";
// import Footer from '../Footer';
import Header from "../Header";
import Footer from "../Footer/CutomeFooter/Footer";

export default function Layout() {
  return (
    <>
      <Header logoSrc="/images/logo.png" variant="cs_heading_color" />
      <Outlet />
      <Footer />
    </>
  );
}
